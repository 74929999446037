const localeDefault = {
  "I2092_11072;623_21687": "An error has occurred, please try again or contact the administrator.",
  "2092_11060": "Edit",
  "2092_11078": "No data",
  "I2092_11065;611_93387": "The organization's function has been successfully established.",
  "10_3115": "AMS",
  "10_3166": "Back",
  "I10_2878;1_6306;1_6253;1_6154": "Identity Management",
  "I10_2878;1_6306;1_6256;1_6154": "Device Management",
  "I10_2878;1_6306;1_6246;1_6154": "TOP",
  "I10_2878;1_6306;1_6259;1_6219": "Approval",
  "10_2886": "Setting for Organization",
  "I10_2878;1_6306;1_6257;1_6154": "Vehicle Management",
  "10_3084": "Organization Code",
  "10_3082": "Netko Vietnam",
  "2007_6404": "/",
  "10_3087": "Enable Functions",
  "I10_2878;1_6306;1_6242": "5",
  "10_2882": "Activity Logs",
  "10_3159": "Common Service",
  "10_3096": "IDM",
  "10_3169": "Save",
  "I10_2878;1_6306;1_6254;1_6154": "Function Setting",
  "2007_6403": "Setting for Organization",
  "10_3148": "Notification",
  "10_3104": "Control Plane",
  "10_3081": "Organization Name",
  "10_3086": "netko-vietnam",
  "10_3080": "Functions Setting  ",
  "I10_2878;1_6306;1_6244": "Admin Portal",
  "I10_2878;1_6306;1_6255;1_6154": "Developer Portal",
  "I10_2878;1_6306;1_6247;1_6154": "Service Management",
  "I10_2878;1_6306;1_6265;1_6214": "Notification",
  "10_3137": "API Gateway",
  "2116_11214": "Enter Add To",
  "2007_6395": "Credential Value",
  "153_5042": "Enter Service Name!",
  "2007_6388": "Credential Key",
  "2007_6408": "/",
  "153_5044": "Enter API URI!",
  "I10_3375;1_6306;1_6247;1_6154": "Service Management",
  "I10_3375;1_6306;1_6242": "5",
  "153_5043": "Enter Protocol!",
  "I10_3375;1_6306;1_6254;1_6154": "Function Setting",
  "I10_3375;1_6306;1_6265;1_6214": "Notification",
  "10_3547": "Auth Method",
  "I10_3375;1_6306;1_6256;1_6154": "Device Management",
  "2007_6396": "Add To",
  "10_3543": "Select",
  "I10_3375;1_6306;1_6244": "Admin Portal",
  "2007_6407": "Service Management",
  "10_3559": "Status",
  "2009_3184": "Create Service",
  "10_3393": "Service Name",
  "2007_6392": "Value",
  "190_1780": "Enter Credential Key",
  "2007_6409": "Create Service",
  "I10_3375;1_6306;1_6257;1_6154": "Vehicle Management",
  "10_3541": "Protocol",
  "2115_11212": "No Special Characters Allowed 0-9 a-z space",
  "10_3544": "API URI",
  "2007_6390": "Key",
  "10_3546": "URL",
  "I10_3375;1_6306;1_6255;1_6154": "Developer Portal",
  "10_3561": "Active",
  "10_3459": "Next",
  "2115_11213": "Enter Auth Method!",
  "2007_6398": "Select",
  "10_3379": "Activity Logs",
  "I10_3375;1_6306;1_6253;1_6154": "Identity Management",
  "190_1781": "Enter Credential Value",
  "I10_3375;1_6306;1_6246;1_6154": "TOP",
  "I10_3375;1_6306;1_6259;1_6219": "Approval",
  "10_3539": "Service name",
  "10_3549": "None",
  "10_3550": "Description",
  "10_3552": "Description",
  "10_3457": "Cancel",
  "2009_3135": "Key",
  "2009_3131": "Next",
  "2009_3138": "Enter Service Name!",
  "2009_3154": "URI",
  "2009_3140": "Enter API URI!",
  "I12_3845;1_6306;1_6242": "5",
  "2009_3150": "Header",
  "2009_3139": "Enter Protocol!",
  "I12_3845;1_6306;1_6265;1_6214": "Notification",
  "2009_3127": "Description",
  "2009_3121": "Status",
  "I12_3845;1_6306;1_6244": "Admin Portal",
  "2009_3124": "Credential Key",
  "2009_3045": "Service Management",
  "I12_3845;1_6306;1_6257;1_6154": "Vehicle Management",
  "I12_3845;1_6306;1_6255;1_6154": "Developer Portal",
  "I12_3845;1_6306;1_6259;1_6219": "Approval",
  "2009_3122": "API URI",
  "12_3849": "Activity Logs",
  "2009_3137": "Value",
  "I12_3845;1_6306;1_6254;1_6154": "Function Setting",
  "2009_3142": "Protocol",
  "2009_3156": "None",
  "2009_3133": "Service name",
  "2009_3120": "Protocol",
  "2009_3160": "Enter your description",
  "2009_3126": "Add to",
  "2009_3047": "Update Service",
  "2009_3187": "Update Service",
  "2009_3161": "Enter Credential Key",
  "2009_3123": "Auth Method",
  "2009_3125": "Credential Value",
  "2009_3146": "Active",
  "2009_3119": "Service Name",
  "I12_3845;1_6306;1_6253;1_6154": "Identity Management",
  "I12_3845;1_6306;1_6256;1_6154": "Device Management",
  "I12_3845;1_6306;1_6246;1_6154": "TOP",
  "I12_3845;1_6306;1_6247;1_6154": "Service Management",
  "2009_3162": "Enter Credential Value",
  "2009_3046": "/",
  "2009_3129": "Cancel",
  "2007_6428": "Show",
  "13_4273": "api-om-api-gateway",
  "13_4287": "Notification",
  "I13_4548;1_6306;1_6242": "5",
  "13_4252": "EXTERNAL",
  "13_4189": "Active",
  "2007_6417": "1",
  "2007_6429": "1",
  "13_4319": "Service Name",
  "13_4275": "Service",
  "13_4261": "INTERNAL",
  "13_4186": "Active",
  "13_4219": "INTERNAL",
  "13_4305": "AMS",
  "I13_4548;1_6306;1_6259;1_6219": "Approval",
  "I13_4548;1_6306;1_6255;1_6154": "Developer Portal",
  "I13_4548;1_6306;1_6265;1_6214": "Notification",
  "I13_4548;1_6306;1_6254;1_6154": "Function Setting",
  "13_4527": "Type",
  "2007_6434": "results",
  "13_4313": "Toyota Serive",
  "2007_6433": "of",
  "I13_4548;1_6306;1_6247;1_6154": "Service Management",
  "13_4269": "om-admin-mazda",
  "I13_4548;1_6306;1_6246;1_6154": "TOP",
  "13_4303": "IDM",
  "13_4333": "Create",
  "13_4516": "Action",
  "13_4258": "INTERNAL",
  "13_4235": "Active",
  "2007_3546": "EXTERNAL",
  "13_4241": "INTERNAL",
  "13_4552": "Activity Logs",
  "2007_3547": "EXTERNAL",
  "I13_4548;1_6306;1_6244": "Admin Portal",
  "13_4289": "Common Service",
  "13_4267": "om-admin-hond",
  "13_4307": "Description",
  "I13_4548;1_6306;1_6253;1_6154": "Identity Management",
  "13_4295": "api-idm",
  "2007_6430": "20",
  "13_4283": "api-notification-inapp",
  "13_4265": "Service Code",
  "13_4279": "Control Plane",
  "13_4180": "Type",
  "13_4293": "om-admin-toyota",
  "I13_4548;1_6306;1_6256;1_6154": "Device Management",
  "13_4255": "EXTERNAL",
  "13_4263": "Action",
  "13_4271": "api-control-plane",
  "13_4531": "Service Name",
  "13_4297": "api-ams",
  "2007_6431": "40",
  "13_4229": "InActive",
  "13_4203": "Active",
  "13_4216": "INTERNAL",
  "13_4299": "Service",
  "13_4281": "API Gateway",
  "13_4226": "Active",
  "13_4178": "Status",
  "13_4335": "List Service",
  "2007_6423": "20  /",
  "13_4311": "Mazda Service",
  "13_4238": "INTERNAL",
  "13_4213": "EXTERNAL",
  "13_4183": "Active",
  "13_4309": "Honda Service",
  "13_4291": "om-admin-mazda-thangnd",
  "2007_3543": "EXTERNAL",
  "2007_6424": "page",
  "13_4232": "Active",
  "13_4206": "Active",
  "I13_4548;1_6306;1_6257;1_6154": "Vehicle Management",
  "13_4192": "Active",
  "2007_6432": "to",
  "13_4519": "Status",
  "13_4277": "Service 4",
  "13_4285": "api-common",
  "13_4301": "Service",
  "I2131_11211;623_21687": "Step numbers are duplicated",
  "I2029_2539;623_21698": "Info",
  "2092_10816": "No data",
  "I2029_2540;623_21687": "An error has occurred, please try again or contact the administrator.",
  "I2029_2537;611_93387": "The Approval Setting has been successfully created.",
  "I2029_2538;623_21593": "Warning",
  "I2125_11362;611_93387": "The Approval Setting has been successfully updated.",
  "I2007_6191;611_93387": "The Service has been successfully activated",
  "2007_6230": "Type the service code to the input below to confirm",
  "2007_3880": "Active",
  "I2029_2523;623_21687": "An error has occurred, please try again or contact the administrator.",
  "I2007_4055;645_18159;30_32137": "Cancel",
  "I2029_2426;611_93387": "The Service has been successfully deactivated",
  "I2029_2433;611_93387": "The Service has been successfully deleted",
  "2007_3887": "EXTERNAL",
  "I2116_11353;623_21687": "Incorrect service code!",
  "I2007_4055;645_18155": "Warning",
  "2007_6229": "Your project will also deleted service",
  "I2007_3933;611_95468;611_95349": "Confirm",
  "I2007_4055;645_18160;30_31931": "Confirm",
  "2007_6225": "Delete Service",
  "2117_11360": "Incorrect service code!",
  "2007_3902": "Delete",
  "I2029_2440;611_93387": "The Service has been successfully created",
  "2007_3886": "INTERNAL",
  "I2007_6194;623_21698": "Info",
  "2007_3893": "View",
  "2007_3874": "Make Inactive",
  "2007_6232": "Enter your service code",
  "I2029_2447;611_93387": "The Service has been successfully updated",
  "2007_3898": "Edit",
  "2007_3873": "Make Active",
  "2007_3881": "Inactive",
  "2007_6223": "Cancel",
  "I2007_4023;611_95467;30_32137": "Cancel",
  "I2007_3933;611_93748": "Warning",
  "2007_3875": "Delete",
  "I2007_4055;645_18156": "Are you sure you want to delete Service?",
  "I2007_4023;611_93748": "Warning",
  "2007_3879": "All",
  "I2007_4023;611_93749": "Are you sure to make Service InActive?",
  "2092_10774": "No data",
  "I2007_3933;611_93749": "Are you sure to make Service Active?",
  "2007_3885": "All",
  "I2007_4023;611_95468;611_95349": "Confirm",
  "I2007_6193;623_21687": "Error",
  "I2007_3933;611_95467;30_32137": "Cancel",
  "2007_6228": "Are you sure delete the service?",
  "I2007_6192;623_21593": "Warning",
  "I2007_6270;623_21593": "Warning",
  "2007_6386": "Basic Auth",
  "I2007_6269;611_93387": "The Service has been successfully created",
  "2009_3051": "gRPC",
  "2007_6412": "Header",
  "2007_6382": "Inactive",
  "2007_6385": "None",
  "I2007_6272;623_21698": "Info",
  "2092_10786": "No data",
  "2009_3052": "MQTT",
  "2007_6413": "Query Params",
  "2009_3050": "HTTPS",
  "2007_6381": "Active",
  "I2029_2502;623_21687": "An error has occurred, please try again or contact the administrator.",
  "2009_3053": "WebSocket",
  "2007_6387": "API Key",
  "2009_3075": "Inactive",
  "2009_3079": "Query Params",
  "I2009_3069;623_21593": "Warning",
  "I2009_3071;623_21698": "Info",
  "2009_3090": "WebSocket",
  "I2029_2509;623_21687": "An error has occurred, please try again or contact the administrator.",
  "2009_3087": "HTTPS",
  "2009_3078": "Header",
  "2009_3088": "gRPC",
  "2009_3084": "API Key",
  "2009_3074": "Active",
  "2009_3083": "Basic Auth",
  "2009_3082": "None",
  "2092_10792": "No data",
  "I2009_3068;611_93387": "The service has been successfully updated.",
  "2009_3089": "MQTT",
  "I2006_3574;643_12017": "Activity Logs",
  "I2282_13585;1_6154": "Service Management",
  "I2382_13045;250_58575": "Send Notification",
  "I2006_3574;366_12073": "UserName",
  "I2006_3574;636_27910": "ABC Corporation",
  "I2006_4176;405_11590;405_11754": "All",
  "2006_4219": "View All",
  "I2282_13593;1_6154": "Developer Portal",
  "I2006_3574;366_12081": "Logout",
  "I2282_13584;1_6154": "TOP",
  "2006_4213": "Scroll to load",
  "I2282_13603;1_6214": "Notification",
  "2006_4191": "[OM] - The system will be in maintain status at 12:00 today\n07:23 17/11/2023",
  "I2282_13753;1_6169": "Manage Vehicle",
  "2009_3201": "Activity Logs",
  "2006_4201": "Bien Hoang need you to Approval 5 Wrapper API Creation Request\n07:23 16/11/2023",
  "I2282_13739;1_6169": "Manage SIM",
  "I2282_13591;1_6154": "Identity Management",
  "2282_13582": "Admin Portal",
  "I2006_4176;405_11590;405_13233;144_59614": "99+",
  "2282_13580": "5",
  "2006_4186": "Bien Hoang need you to Approval the Translation Request\n10:23 17/11/2023",
  "I2282_13723;1_6178": "Send Device",
  "I2382_13046;250_58566": "Segment",
  "I2282_13724;1_6169": "Receive Device",
  "I2282_13755;1_6169": "Reminder",
  "I2282_13592;1_6154": "Function Setting",
  "I2282_13798;1_6178": "List Approval",
  "2006_4197": "Thurday, 16 Nov 2023",
  "2278_11230": "Reject",
  "2006_4181": "Friday, 17 Nov 2023",
  "2136_11235": "Identity Management",
  "I2006_4187;30_32021": "Approve",
  "2006_4206": "Bien Hoang need you to Approval the Translation Request\n10:23 16/11/2023",
  "2118_11361": "Dashboard",
  "I2006_4176;405_13268;405_11583": "Tasks",
  "I2282_13595;1_6154": "Vehicle Management",
  "2136_11225": "Admin Portal",
  "2136_11231": "Develop Console",
  "I2006_4176;405_13262;405_13239;144_59614": "9",
  "I2006_3574;974_98967": "Profile Setting",
  "I2282_13733;1_6169": "Telecom Company",
  "I2282_13799;1_6169": "Approval Setting",
  "I2006_3574;366_12079": "Organization Setting",
  "I2305_11283;1_6214": "Company Setting",
  "I2282_13754;1_6169": "Vehicle Setting",
  "I2006_4176;405_13251;405_11583": "Inbox",
  "I2282_13597;1_6219": "Approval",
  "2006_4211": "Bien Hoang update Translation Task from TODO to DONE\n10:23 16/11/2023",
  "I2282_13594;1_6154": "Device Management",
  "I2006_4207;30_31991": "Approve",
  "I2006_4176;405_13262;405_11583": "Approval",
  "I2009_3416;645_18155": "Warning",
  "2414_13832": "Approve",
  "I2009_3416;645_18156": "Are you sure you want to reject this request?",
  "2414_13814": "Created By:",
  "I2009_3414;611_93749": "Are you sure you want to approve this request?",
  "2414_13808": "Function Name:",
  "2414_13798": "Approval Confirmation",
  "2414_13810":
    "api-ams_translation_version_english_translation_9b8234d-ccd8-4e00-b407-2386596f7c09",
  "2092_10804": "No data",
  "2414_13816": "bienhoanggiang+10@gmail.com",
  "2414_13822": "01:15 08-03-2024",
  "I2009_3414;611_95468;611_95349": "Confirm",
  "I2029_2516;623_21687": "An error has occurred, please try again or contact the administrator.",
  "I2009_3420;623_21698": "Info",
  "I2009_3417;611_93387": "You have successfully approved the request",
  "153_5052": "Rejected",
  "153_5048": "All",
  "153_5050": "Approved",
  "2414_13828": "Reject",
  "I2009_3418;623_21593": "Warning",
  "153_5049": "In process",
  "I2009_3414;611_95467;30_32137": "Cancel",
  "I2009_3416;645_18160;30_31931": "Confirm",
  "I2009_3414;611_93748": "Warning",
  "I2009_3416;645_18159;30_32137": "Cancel",
  "2414_13820": "Created Date:",
  "I2029_2613;611_93387": "You have successfully rejected the request",
  "2027_2421": "Edit",
  "I2009_3553;645_18155": "Warning",
  "2027_2425": "Delete",
  "I2009_3557;623_21698": "Info",
  "2027_2416": "View",
  "I2029_2530;623_21687": "An error has occurred, please try again or contact the administrator.",
  "I2009_3555;623_21593": "Warning",
  "I2009_3553;645_18159;30_32137": "Cancel",
  "I2129_10794;611_93387": "The Approval Setting has been successfully updated.",
  "I2009_3553;645_18156": "Are you sure you want to delete this Approval Setting?",
  "I2009_3554;611_93387": "The Approval Setting has been successfully created.",
  "I2009_3553;645_18160;30_31931": "Confirm",
  "2092_10810": "No data",
  "I2029_2624;611_93387": "The Approval Setting has been successfully deleted.",
  "2009_4053": "View",
  "2009_4045": "Update",
  "I2092_11376;623_21687": "An error has occurred, please try again or contact the administrator.",
  "2009_4044": "Create",
  "2092_10822": "No data",
  "2009_4046": "Delete",
  "2024_4608": "Add field",
  "2024_4615": "Review",
  "I2024_4559;1_6306;1_6254;1_6154": "Function Setting",
  "2024_4568": "Name",
  "2024_4619": "Approval Setting",
  "I2024_4559;1_6306;1_6242": "5",
  "I2024_4559;1_6306;1_6247;1_6154": "Service Management",
  "2024_4597": "Admin@organicklink.cloud",
  "2024_4565": "Activity Logs",
  "2024_4715": "Approval Setting",
  "2024_4574": "IDM",
  "I2024_4559;1_6306;1_6257;1_6154": "Vehicle Management",
  "I2024_4559;1_6306;1_6256;1_6154": "Device Management",
  "2024_4572": "Service Name",
  "I2024_4559;1_6306;1_6259;1_6219": "Approval",
  "I2024_4559;1_6306;1_6255;1_6154": "Developer Portal",
  "I2024_4559;1_6306;1_6262;1_6169": "Approval Setting",
  "2024_4570": "Update Admin User",
  "2024_4713": "Approval",
  "2024_4594": "Approvers",
  "2024_4578": "Object",
  "2024_4590": "Steps",
  "2024_4711": "Update",
  "2024_4580": "User",
  "I2024_4559;1_6306;1_6246;1_6154": "TOP",
  "I2024_4559;1_6306;1_6244": "Admin Portal",
  "2024_4592": "1",
  "2024_4610": "Back",
  "I2024_4559;1_6306;1_6253;1_6154": "Identity Management",
  "2024_4586": "Update",
  "2024_4714": "/",
  "I2024_4559;1_6306;1_6265;1_6214": "Notification",
  "I2024_4559;1_6306;1_6261;1_6178": "List Approval",
  "2024_4584": "Method",
  "2027_2319": "Value",
  "2027_2290": "Description",
  "I2027_2273;1_6306;1_6257;1_6154": "Vehicle Management",
  "2027_2288": "API URI",
  "2027_2286": "Protocol",
  "2027_2285": "Service Name",
  "I2027_2273;1_6306;1_6253;1_6154": "Identity Management",
  "2027_2315": "Add to",
  "2027_2302": "Active",
  "2027_2321": "Header",
  "2027_2327": "View Service",
  "2027_2296": "Sevice 1",
  "2027_2282": "View Service",
  "I2027_2273;1_6306;1_6242": "5",
  "I2027_2273;1_6306;1_6247;1_6154": "Service Management",
  "2027_2289": "Auth Method",
  "2027_2298": "HTTPS",
  "I2027_2273;1_6306;1_6255;1_6154": "Developer Portal",
  "2027_2317": "Key",
  "2027_2308": "None",
  "2027_2314": "Credential Value",
  "2027_2313": "Credential Key",
  "2027_2326": "/",
  "I2027_2273;1_6306;1_6244": "Admin Portal",
  "2027_2292": "Back",
  "I2027_2273;1_6306;1_6254;1_6154": "Function Setting",
  "I2027_2273;1_6306;1_6256;1_6154": "Device Management",
  "2027_2287": "Status",
  "I2027_2273;1_6306;1_6246;1_6154": "TOP",
  "2027_2325": "Service Management",
  "2027_2312": "Enter your description",
  "2027_2277": "Activity Logs",
  "I2027_2273;1_6306;1_6265;1_6214": "Notification",
  "2027_2306": "https://om-mazda.vinasports.net",
  "I2027_2273;1_6306;1_6259;1_6219": "Approval",
  "2029_2466": "Query Params",
  "2029_2474": "HTTPS",
  "2029_2470": "Basic Auth",
  "2029_2461": "Active",
  "2029_2477": "WebSocket",
  "2092_10780": "No data",
  "I2029_2457;623_21687": "An error has occurred, please try again or contact the administrator.",
  "2029_2476": "MQTT",
  "2029_2469": "None",
  "I2029_2455;611_93387": "The organization's function has been successfully established.",
  "2029_2465": "Header",
  "2029_2462": "Inactive",
  "2029_2471": "API Key",
  "2029_2475": "gRPC",
  "2061_2484": "Owner OmAdmin",
  "2061_2457": "City",
  "2061_2477": "Personal data usage history",
  "2061_2451": "Address",
  "2061_2467": "Last Name",
  "2061_2448": "Email",
  "2061_2444": "English",
  "I2061_2410;1_6306;1_6256;1_6154": "Device Management",
  "2061_2486": "admin@organiclink.cloud",
  "I2061_2410;1_6306;1_6247;1_6154": "Service Management",
  "2061_2485": "Role:",
  "2061_2461": "State/Prefecture",
  "I2061_2410;1_6306;1_6246;1_6154": "TOP",
  "2061_2442": "Language",
  "2061_2470": "Update",
  "2061_2463": "Select Country...",
  "I2061_2410;1_6306;1_6253;1_6154": "Identity Management",
  "2061_2489": "Upload",
  "I2061_2410;1_6306;1_6257;1_6154": "Vehicle Management",
  "2061_2475": "Security",
  "I2061_2410;1_6306;1_6244": "Admin Portal",
  "2061_2459": "Postcode",
  "I2061_2410;1_6306;1_6265;1_6214": "Notification",
  "2061_2455": "Address 2",
  "I2061_2410;1_6306;1_6242": "5",
  "I2061_2410;1_6306;1_6259;1_6219": "Approval",
  "2061_2493": "Enter Last name",
  "2061_2434": "Gender",
  "2061_2417": "User Profile",
  "2061_2492": "Enter First name",
  "2061_2453": "Address 1",
  "2061_2431": "Nick name",
  "2061_2481": "Log out",
  "I2061_2410;1_6306;1_6254;1_6154": "Function Setting",
  "I2061_2410;1_6306;1_6255;1_6154": "Developer Portal",
  "2061_2424": "Phone",
  "2061_2421": "First Name",
  "2061_2414": "Activity Logs",
  "2061_2439": "Birthday",
  "2092_11446": "Service User",
  "2092_11455": "No data",
  "2092_11448": "Service Provider 2C",
  "2092_11447": "Service Provider 2B",
  "I2092_11449;623_21687": "An error has occurred, please try again or contact the administrator.",
  "I2092_11456;611_93387": "The Organization has been successfully updated.",
  "2092_11374": "Notification",
  "2092_11411": "Female",
  "2092_11375": "Your profile has been successfully updated.",
  "2092_10828": "No data",
  "I2092_11399;623_21687": "Provided token is invalid",
  "I2092_11383;623_21687": "An error has occurred, please try again or contact the administrator.",
  "2092_11409": "Male",
  "I2067_5738;835_98906": "SIM ID 2",
  "I2067_5724;18_21709": "789456134",
  "I2067_5761;18_21709": "KDDI Thailand",
  "I2067_5776;21_22137": "TOP",
  "I2067_5778;21_22137": "Identity Management",
  "I2067_5798;21_22137": "Company Settings",
  "I2067_5768;581_1954;7882_26275;433_34538": "11",
  "2067_5742": "Action",
  "I2067_5725;18_21709": "KDDI Thailand",
  "I2067_5787;21_22137": "Vehicle Management",
  "I2067_5783;250_58575": "Send Device",
  "I2067_5785;250_58566": "Manage Telecom Comp",
  "2067_5730": "Action",
  "2067_5754": "Action",
  "I2067_5726;835_98906": "SIM ID 1",
  "I2067_5714;171_66633": "Device Name",
  "I2067_5794;23_39072": "Notification",
  "I2067_5768;581_1954;7882_26160;433_34538": "...",
  "I2067_5777;21_22137": "Service Management",
  "I2067_5719;171_66633": "Action",
  "I2067_5768;368_110840": "Show 1 to 10 of 100 results",
  "I2067_5748;18_21709": "365412647",
  "I2067_5799;21_22137": "Activitiy log",
  "I2067_5736;18_21709": "789456132",
  "I2067_5735;835_98906": "T633",
  "I2067_5749;18_21709": "KDDI Japan",
  "I2067_5784;250_58566": "Receive Device",
  "I2067_5779;21_22137": "Function Setting",
  "I2067_5768;581_1954;7882_21473;497_44116": "1",
  "I2067_5702;388_23148;30_32140": "Add",
  "I2067_5780;21_22137": "Developer Portal",
  "I2067_5768;581_1954;7882_21853;433_34538": "2",
  "I2067_5739;33_33258;33_33254": "Sent",
  "I2067_5751;33_33258;33_33254": "Delivered",
  "I2067_5768;581_1954;7882_19131;497_44116": "10 / page",
  "2067_5766": "Action",
  "I2067_5768;581_1954;7882_26229;433_34538": "10",
  "I2067_5701;28_31248;28_31243": "Send Device",
  "I2067_5763;33_33258;33_33254": "Returning",
  "I2067_5709;121_59079": "Search...",
  "I2067_5713;171_66633": "Device ID",
  "I2067_5786;250_58566": "Manage SIM",
  "I2067_5707;30_32021": "Export",
  "I2067_5701;28_31246;28_31236": "Device Management",
  "I2067_5708;433_34538": "Device status",
  "I2067_5750;835_98906": "SIM ID 3",
  "I2067_5760;18_21709": "745621478",
  "I2067_5768;581_1954;7882_22097;433_34538": "4",
  "I2067_5758;18_21709": "Device ID 4",
  "I2067_5702;388_23101": "Send Device",
  "I2067_5746;18_21709": "Device ID 3",
  "I2067_5737;18_21709": "KDDI Thailand",
  "I2067_5706;30_32021": "Import",
  "I2067_5718;171_66633": "Device Status",
  "2067_5772": "5",
  "I2067_5701;28_31263;28_31261": ">",
  "I2067_5759;835_98906": "T711",
  "I2067_5727;33_33258;33_33254": "In-stock",
  "I2067_5762;835_98906": "SIM ID 4",
  "2067_5774": "Admin Portal",
  "I2067_5716;171_66633": "Assigned Organization",
  "I2067_5734;18_21709": "Device ID 2",
  "I2067_5747;835_98906": "T633",
  "I2067_5715;171_66633": "IMEI",
  "I2067_5768;581_1954;7882_21971;433_34538": "3",
  "I2067_5789;23_39072": "Approval",
  "I2067_5723;835_98906": "T633",
  "I2067_5717;171_66633": "SIM Status",
  "I2067_5722;18_21709": "Device ID 1",
  "I2067_5782;23_39072": "Device Management",
  "I2067_5842;835_98906": "SIM ID 2",
  "I2067_5819;171_66633": "IMEI",
  "I2067_5831;33_33258;33_33254": "In-stock",
  "I2067_5881;21_22137": "Service Management",
  "I2067_5821;171_66633": "SIM ID",
  "I2067_5830;835_98906": "SIM ID 1",
  "I2067_5850;18_21709": "Device ID 3",
  "I2067_5811;30_31991": "Export",
  "I2067_5805;28_31263;28_31261": ">",
  "2067_5846": "Action",
  "I2067_5805;28_31246;28_31236": "Device Management",
  "I2067_5838;18_21709": "Device ID 2",
  "I2067_5867;33_33258;33_33254": "Returning",
  "I2067_5891;21_22137": "Vehicle Management",
  "I2695_15314;7636_13403": "Seating Capacity",
  "2695_15316": "Enter Seating Capacity",
  "I2695_15314;7636_13405;75_53807": "Seating Capacity",
  "I2695_15710;7636_13403": "Nick Name",
  "I2695_15710;7636_13405;75_53807": "Nick Name",
  "I2067_5806;388_23148;30_32140": "Add",
  "I2067_5872;581_1954;7882_21853;433_34538": "2",
  "I2067_5887;250_58575": "Send Device",
  "I2067_5872;581_1954;7882_26229;433_34538": "10",
  "I2067_5872;581_1954;7882_21971;433_34538": "3",
  "I2067_5872;581_1954;7882_21473;497_44116": "1",
  "I2067_5840;18_21709": "789456132",
  "I2067_5829;18_21709": "KDDI Thailand",
  "I2067_5820;171_66633": "Assigned Organization",
  "2067_5878": "Admin Portal",
  "I2067_5841;18_21709": "KDDI Thailand",
  "I2067_5884;21_22137": "Developer Portal",
  "I2067_5817;171_66633": "Device ID",
  "I2067_5872;581_1954;7882_26160;433_34538": "...",
  "I2067_5851;835_98906": "T633",
  "2067_5876": "5",
  "I2067_5806;388_23101": "Send Device",
  "I2067_5872;368_110840": "Show 1 to 10 of 100 results",
  "I2067_5853;18_21709": "KDDI Japan",
  "I2067_5854;835_98906": "SIM ID 3",
  "2067_5834": "Action",
  "I2067_5823;171_66633": "Action",
  "I2067_5898;23_39072": "Notification",
  "I2067_5855;33_33258;33_33254": "Delivered",
  "I2067_5864;18_21709": "745621478",
  "I2067_5882;21_22137": "Identity Management",
  "I2067_5839;835_98906": "T633",
  "I2067_5843;33_33258;33_33254": "Sent",
  "I2067_5889;250_58566": "Telecom Company",
  "I2067_5863;835_98906": "T711",
  "I2067_5886;23_39072": "Device Management",
  "2067_5858": "Action",
  "I2067_5888;250_58566": "Receive Device",
  "2067_5870": "Action",
  "I2067_5810;30_32021": "Import",
  "I2067_5883;21_22137": "Function Setting",
  "I2067_5852;18_21709": "365412647",
  "I2067_5813;121_59079": "Search...",
  "I2067_5880;21_22137": "TOP",
  "I2067_5902;21_22137": "Company Settings",
  "I2067_5805;28_31248;28_31243": "Send Device",
  "I2067_5872;581_1954;7882_22097;433_34538": "4",
  "I2067_5865;18_21709": "KDDI Thailand",
  "I2067_5822;171_66633": "Device Status",
  "I2067_5812;433_34538": "Device status",
  "I2067_5818;171_66633": "Device Name",
  "I2067_5862;18_21709": "Device ID 4",
  "I2067_5872;581_1954;7882_26275;433_34538": "11",
  "I2067_5872;581_1954;7882_19131;497_44116": "10 / page",
  "I2067_5828;18_21709": "789456134",
  "I2067_5827;835_98906": "T633",
  "I2067_5903;21_22137": "Activitiy log",
  "I2067_5893;23_39072": "Approval",
  "I2067_5826;18_21709": "Device ID 1",
  "I2067_5866;835_98906": "SIM ID 4",
  "I2067_5890;250_58566": "Manage SIM",
  "I2067_5913;7636_13403": "Device Name",
  "I2067_5937;21_22137": "Function Setting",
  "I2067_5940;23_39072": "Device Management",
  "I2067_5909;35_49551;28_31261": ">",
  "I2067_5914;7636_13403": "IMEI",
  "I2067_5909;35_49549;28_31261": ">",
  "I2067_5934;21_22137": "TOP",
  "2082_10737": "No Special Characters Allowed 0-9",
  "I2067_5941;250_58575": "Send Device",
  "I2067_5925;30_32137": "Cancel",
  "I2067_5957;21_22137": "Activitiy log",
  "I2067_5921;75_53807": "SIM ID",
  "2082_10740": "No Special Characters Allowed 0-9 a-z space",
  "2082_10729": "No Special Characters Allowed 0-9 a-z space",
  "I2067_5935;21_22137": "Service Management",
  "I2067_5945;21_22137": "Vehicle Management",
  "2067_5932": "Admin Portal",
  "I2067_5923;154_64377;75_53865;250_37564": "Select Assigned Organization",
  "I2067_5952;23_39072": "Notification",
  "I2067_5922;30_32140": "Search",
  "2082_10739": "Enter your device bluetooth ID",
  "2067_5930": "5",
  "I2067_5942;250_58566": "Receive Device",
  "I2067_5944;250_58566": "Manage SIM",
  "I2067_5947;23_39072": "Approval",
  "I2067_5938;21_22137": "Developer Portal",
  "2082_10728": "Enter your device name",
  "I2067_5915;7636_13403": "Device Bluetooth ID",
  "2082_10736": "Enter your IMEI",
  "I2067_5926;30_32140": "Next",
  "I2067_5909;35_49552;28_31243": "Add Device",
  "I2067_5909;35_49548;28_31236": "Device Management",
  "I2067_5956;21_22137": "Company Settings",
  "I2067_5936;21_22137": "Identity Management",
  "2067_5918": "SIM ID",
  "I2067_5915;7636_13405;75_53807": "Device Bluetooth ID",
  "I2067_5914;7636_13405;75_53807": "IMEI",
  "I2067_5910;35_49503": "Add Device",
  "I2067_5943;250_58566": "Manage Telecom Comp",
  "I2067_5913;7636_13405;75_53807": "Device Name",
  "I2067_5923;154_64376": "Assigned Organization",
  "I2067_5909;35_49550;28_31236": "Send Device",
  "I2067_5963;35_49552;28_31243": "Add SIM",
  "I2067_5970;154_64377;75_53865;250_37564": "Select Telecom Company",
  "2083_10760": "Enter your telecom company",
  "2082_10754": "Enter your ICCID",
  "I2067_5983;21_22137": "Identity Management",
  "I2067_5991;250_58575": "Manage SIM",
  "I2067_5964;35_49503": "Add SIM",
  "I2067_5969;7636_13405;75_53807": "12345678",
  "I2067_5972;30_32137": "Cancel",
  "I2067_6003;21_22137": "Company Settings",
  "2082_10757": "Enter your PUK",
  "I2067_5989;250_58566": "Receive Device",
  "I2067_5987;23_39072": "Device Management",
  "I2067_5985;21_22137": "Developer Portal",
  "2083_10772": "Enter your action",
  "I2067_5988;250_58566": "Send Device",
  "2082_10751": "Enter your SIM name",
  "I2067_5963;35_49551;28_31261": ">",
  "I2067_5982;21_22137": "Service Management",
  "I2067_5963;35_49549;28_31261": ">",
  "2082_10752": "No Special Characters Allowed 0-9 a-z space",
  "I2067_5963;35_49548;28_31236": "Device Management",
  "I2083_10762;154_64376": "Select Action",
  "2082_10758": "No Special Characters Allowed 0-9",
  "I2067_5963;35_49550;28_31236": "Manage SIM",
  "I2067_5969;7636_13403": "PUK (8 DIGITS)",
  "I2067_5990;250_58566": "Manage Telecom Comp",
  "2067_5977": "5",
  "I2067_5967;7636_13405;75_53807": "SIM Name",
  "I2067_5968;7636_13405;75_53807": "1234560012345678901",
  "I2067_5970;154_64376": "Telecom Company",
  "I2937_13847;154_64376": "Coverage Type",
  "2082_10755": "No Special Characters Allowed 0-9",
  "I2067_5967;7636_13403": "SIM Name",
  "I2067_6004;21_22137": "Activitiy log",
  "I2067_5994;23_39072": "Approval",
  "I2067_5968;7636_13403": "ICCID (19 DIGITS)",
  "I2067_5973;30_32140": "Save",
  "2067_5979": "Admin Portal",
  "I2083_10762;154_64377;75_53865;250_37564": "Select Action",
  "I2067_5984;21_22137": "Function Setting",
  "I2067_5981;21_22137": "TOP",
  "I2067_5999;23_39072": "Notification",
  "I2067_5992;21_22137": "Vehicle Management",
  "I2067_6033;250_58566": "Send Device",
  "I2067_6035;250_58566": "Manage Telecom Comp",
  "I2067_6015;7636_13405;185_68545": "1234560012345678901",
  "I2067_6010;35_49552;28_31243": "View SIM",
  "I2067_6015;7636_13403": "ICCID (19 DIGITS)",
  "I2067_6027;21_22137": "Service Management",
  "I2067_6034;250_58566": "Receive Device",
  "I2067_6010;35_49549;28_31261": ">",
  "I2067_6037;21_22137": "Vehicle Management",
  "2067_6022": "5",
  "I2067_6011;35_49503": "View SIM",
  "I2067_6014;7636_13405;185_68545": "SIM Name",
  "I2067_6017;154_64376": "* Telecom Company",
  "I2067_6014;7636_13403": "* SIM Name",
  "I2067_6032;23_39072": "Device Management",
  "I2067_6049;21_22137": "Activitiy log",
  "I2067_6036;250_58575": "Manage SIM",
  "I2067_6017;154_64377;75_53865;250_37580": "Select Telecom Company",
  "I2067_6026;21_22137": "TOP",
  "I2067_6048;21_22137": "Company Settings",
  "I2067_6029;21_22137": "Function Setting",
  "I2067_6016;7636_13405;185_68545": "12345678",
  "I2067_6010;35_49551;28_31261": ">",
  "I2067_6016;7636_13403": "* PUK (8 DIGITS)",
  "I2067_6010;35_49548;28_31236": "Device Management",
  "I2067_6044;23_39072": "Notification",
  "I2067_6018;30_32137": "Cancel",
  "I2067_6028;21_22137": "Identity Management",
  "I2067_6039;23_39072": "Approval",
  "I2067_6030;21_22137": "Developer Portal",
  "I2067_6010;35_49550;28_31236": "Manage SIM",
  "2067_6024": "Admin Portal",
  "I2067_6061;7636_13403": "* PUK (8 DIGITS)",
  "I2084_10826;154_64376": "* Select Action",
  "I2067_6075;21_22137": "Developer Portal",
  "I2067_6062;154_64377;75_53865;250_37580": "Select Telecom Company",
  "I2067_6059;7636_13405;121_58638": "SIM Name",
  "I2067_6055;35_49549;28_31261": ">",
  "2067_6069": "Admin Portal",
  "2084_10843": "No Special Characters Allowed 0-9",
  "I2067_6061;7636_13405;185_68545": "12345678",
  "I2067_6055;35_49550;28_31236": "Manage SIM",
  "I2067_6060;7636_13403": "ICCID (19 DIGITS)",
  "I2067_6059;7636_13403": "* SIM Name",
  "I2067_6055;35_49548;28_31236": "Device Management",
  "I2067_6093;21_22137": "Company Settings",
  "I2067_6071;21_22137": "TOP",
  "2084_10848": "Enter your telecom company",
  "2084_10836": "Enter your SIM name",
  "I2067_6074;21_22137": "Function Setting",
  "I2067_6089;23_39072": "Notification",
  "2084_10837": "No Special Characters Allowed 0-9 a-z space",
  "I2067_6060;7636_13405;185_68545": "1234560012345678901",
  "I2167_11585;30_32140": "Update",
  "I2067_6056;35_49503": "Edit SIM",
  "2084_10840": "No Special Characters Allowed 0-9",
  "2084_10842": "Enter your PUK",
  "2067_6067": "5",
  "I2084_10826;154_64377;75_53865;250_37580": "Active",
  "I2067_6080;250_58566": "Manage Telecom Comp",
  "I2067_6063;30_32137": "Cancel",
  "I2067_6055;35_49552;28_31243": "Edit SIM",
  "I2067_6094;21_22137": "Activitiy log",
  "I2067_6062;154_64376": "* Telecom Company",
  "I2067_6081;250_58575": "Manage SIM",
  "I2067_6079;250_58566": "Receive Device",
  "2084_10839": "Enter your ICCID",
  "I2067_6084;23_39072": "Approval",
  "I2067_6077;23_39072": "Device Management",
  "I2067_6073;21_22137": "Identity Management",
  "2084_10864": "Enter your action",
  "I2067_6072;21_22137": "Service Management",
  "I2067_6055;35_49551;28_31261": ">",
  "I2067_6078;250_58566": "Send Device",
  "I2067_6082;21_22137": "Vehicle Management",
  "I2067_6148;21_22137": "Activitiy log",
  "2082_10748": "Enter your device bluetooth ID",
  "2067_6109": "SIM ID",
  "I2067_6114;154_64376": "Assigned Organization",
  "I2067_6116;30_32137": "Cancel",
  "2067_6123": "Admin Portal",
  "I2067_6101;35_49503": "Edit Device",
  "I2067_6100;35_49549;28_31261": ">",
  "I2067_6104;7636_13403": "* Device Name",
  "I2067_6106;7636_13403": "* Device Bluetooth ID",
  "2082_10745": "Enter your IMEI",
  "2067_6121": "5",
  "I2067_6132;250_58575": "Send Device",
  "I2067_6112;121_58638": "SIM ID",
  "I2067_6127;21_22137": "Identity Management",
  "I2067_6125;21_22137": "TOP",
  "2082_10743": "No Special Characters Allowed 0-9 a-z space",
  "I2067_6135;250_58566": "Manage SIM",
  "I2067_6100;35_49548;28_31236": "Device Management",
  "I2067_6131;23_39072": "Device Management",
  "I2067_6133;250_58566": "Receive Device",
  "I2067_6138;23_39072": "Approval",
  "I2067_6126;21_22137": "Service Management",
  "I2067_6106;7636_13405;121_58638": "Device Bluetooth ID ",
  "I2067_6105;7636_13403": "* IMEI",
  "I2067_6100;35_49551;28_31261": ">",
  "I2067_6134;250_58566": "Manage Telecom Comp",
  "2082_10742": "Enter your device name",
  "I2067_6136;21_22137": "Vehicle Management",
  "I2067_6129;21_22137": "Developer Portal",
  "I2067_6104;7636_13405;121_58638": "Device Name",
  "I2067_6117;30_32140": "Next",
  "I2067_6143;23_39072": "Notification",
  "2082_10749": "No Special Characters Allowed 0-9 a-z space",
  "2082_10746": "No Special Characters Allowed 0-9",
  "I2067_6147;21_22137": "Company Settings",
  "I2067_6100;35_49550;28_31236": "Send Device",
  "I2067_6128;21_22137": "Function Setting",
  "I2067_6114;154_64377;75_53865;250_37573": "Select Assigned Organization",
  "I2067_6105;7636_13405;121_58638": "IMEI",
  "I2067_6100;35_49552;28_31243": "Edit Device",
  "I2067_6113;30_32140": "Search",
  "I2067_6154;35_49605;28_31243": "Preview Device",
  "I2067_6185;23_39072": "Device Management",
  "I2067_6201;21_22137": "Company Settings",
  "I2067_6171;30_32140": "Update",
  "I2067_6155;35_49503": "Preview Device",
  "I2067_6186;250_58575": "Send Device",
  "I2067_6189;250_58566": "Manage SIM",
  "I2067_6180;21_22137": "Service Management",
  "2067_6177": "Admin Portal",
  "I2067_6154;35_49598;28_31261": ">",
  "I2067_6154;35_49595;28_31236": "Device Management",
  "I2067_6160;7636_13403": "* Device Bluetooth ID",
  "I2067_6154;35_49597;28_31236": "Send Device",
  "2067_6163": "SIM ID",
  "2067_6175": "5",
  "I2067_6159;7636_13405;185_68545": "IMEI",
  "I2067_6158;7636_13403": "* Device Name",
  "I2067_6170;30_32137": "Back",
  "I2067_6158;7636_13405;185_68545": "Device Name",
  "I2067_6154;35_49607;28_31261": ">",
  "I2067_6197;23_39072": "Notification",
  "I2067_6159;7636_13403": "* IMEI",
  "I2067_6188;250_58566": "Manage Telecom Comp",
  "I2067_6168;154_64377;75_53865;250_37580": "Select Assigned Organization",
  "I2067_6179;21_22137": "TOP",
  "I2067_6166;121_58638": "SIM ID",
  "I2067_6154;35_49599;28_31236": "Edit Device",
  "I2067_6181;21_22137": "Identity Management",
  "I2067_6183;21_22137": "Developer Portal",
  "I2067_6160;7636_13405;185_68545": "Device Bluetooth ID",
  "I2067_6192;23_39072": "Approval",
  "I2067_6167;30_32081": "Search",
  "I2067_6202;21_22137": "Activitiy log",
  "I2067_6182;21_22137": "Function Setting",
  "I2067_6190;21_22137": "Vehicle Management",
  "I2067_6168;154_64376": "Assigned Organization",
  "I2067_6187;250_58566": "Receive Device",
  "I2067_6154;35_49596;28_31261": ">",
  "I2067_6220;185_68545": "SIM ID",
  "I2067_6239;23_39072": "Device Management",
  "I2067_6243;250_58566": "Manage SIM",
  "I2067_6234;21_22137": "Service Management",
  "I2067_6251;23_39072": "Notification",
  "I2067_6242;250_58566": "Manage Telecom Comp",
  "I2067_6233;21_22137": "TOP",
  "I2067_6208;35_49552;28_31243": "Preview Device",
  "I2067_6222;154_64377;75_53865;250_37580": "Select Assigned Organization",
  "I2067_6224;30_32137": "Back",
  "2067_6231": "Admin Portal",
  "I2067_6208;35_49550;28_31236": "Send Device",
  "I2067_6225;30_32140": "Create",
  "I2067_6235;21_22137": "Identity Management",
  "I2067_6212;7636_13403": "* Device Name",
  "I2067_6222;154_64376": "Assigned Organization",
  "I2067_6241;250_58566": "Receive Device",
  "I2067_6209;35_49503": "Preview Device",
  "I2067_6236;21_22137": "Function Setting",
  "I2067_6214;7636_13403": "* Device Bluetooth ID",
  "I2067_6208;35_49549;28_31261": ">",
  "I2067_6237;21_22137": "Developer Portal",
  "I2067_6240;250_58575": "Send Device",
  "I2067_6244;21_22137": "Vehicle Management",
  "I2067_6214;7636_13405;185_68545": "Device Bluetooth ID",
  "I2067_6213;7636_13403": "* IMEI",
  "I2067_6221;30_32081": "Search",
  "I2067_6213;7636_13405;185_68545": "IMEI",
  "I2067_6208;35_49551;28_31261": ">",
  "I2067_6246;23_39072": "Approval",
  "2067_6217": "SIM ID",
  "I2067_6255;21_22137": "Company Settings",
  "I2067_6256;21_22137": "Activitiy log",
  "2067_6229": "5",
  "I2067_6208;35_49548;28_31236": "Device Management",
  "I2067_6212;7636_13405;185_68545": "Device Name",
  "I2067_6275;30_32081": "Search",
  "I2067_6308;21_22137": "Activitiy log",
  "I2067_6267;7636_13405;185_68545": "IMEI",
  "I2067_6293;250_58566": "Receive Device",
  "I2067_6267;7636_13403": "* IMEI",
  "I2067_6262;35_49551;28_31261": ">",
  "I2067_6266;7636_13405;185_68545": "Device Name",
  "I2067_6287;21_22137": "Identity Management",
  "2067_6271": "SIM ID",
  "I2067_6295;250_58566": "Manage SIM",
  "I2067_6276;154_64377;75_53865;250_37580": "Select Assigned Organization",
  "I2067_6274;185_68545": "SIM ID",
  "I2067_6262;35_49548;28_31236": "Device Management",
  "I2067_6296;21_22137": "Vehicle Management",
  "I2067_6268;7636_13403": "* Device Bluetooth ID",
  "I2067_6276;154_64376": "Assigned Organization",
  "I2067_6263;35_49503": "View Device",
  "I2067_6277;30_32137": "Cancel",
  "I2067_6286;21_22137": "Service Management",
  "I2067_6262;35_49552;28_31243": "View Device",
  "I2067_6285;21_22137": "TOP",
  "I2067_6262;35_49550;28_31236": "Send Device",
  "I2067_6289;21_22137": "Developer Portal",
  "2067_6283": "Admin Portal",
  "I2067_6262;35_49549;28_31261": ">",
  "I2067_6268;7636_13405;185_68545": "Device Bluetooth ID",
  "2067_6281": "5",
  "I2067_6298;23_39072": "Approval",
  "I2067_6294;250_58566": "Manage Telecom Comp",
  "I2067_6303;23_39072": "Notification",
  "I2067_6307;21_22137": "Company Settings",
  "I2067_6288;21_22137": "Function Setting",
  "I2067_6291;23_39072": "Device Management",
  "I2067_6292;250_58575": "Send Device",
  "I2067_6266;7636_13403": "* Device Name",
  "I2067_6381;581_1954;7882_19131;497_44116": "10 / page",
  "I2067_6338;18_21709": "Subscribed",
  "I2067_6374;18_21709": "Unsubscribed",
  "I2067_6332;171_66633": "Action",
  "I2136_11242;250_58566": "Manage Telecom Comp",
  "I2067_6360;835_98906": "T633",
  "I2067_6392;21_22137": "Function Setting",
  "I2067_6314;28_31263;28_31261": ">",
  "I2067_6321;121_59079": "Search...",
  "2067_6387": "Admin Portal",
  "I2067_6371;18_21709": "Device ID 4",
  "I2067_6391;21_22137": "Identity Management",
  "I2136_11239;23_39072": "Device Management",
  "I2067_6328;171_66633": "IMEI",
  "I2067_6411;21_22137": "Company Settings",
  "I2067_6330;171_66633": "SIM ID",
  "I2067_6361;18_21709": "365412647",
  "I2067_6335;18_21709": "Device ID 1",
  "I2067_6389;21_22137": "TOP",
  "I2067_6314;28_31248;28_31243": "Receive Device",
  "I2067_6318;30_31991": "Export",
  "I2067_6320;318_97322": "Device Status",
  "I2067_6381;581_1954;7882_21971;433_34538": "3",
  "2067_6343": "Action",
  "I2067_6381;581_1954;7882_26229;433_34538": "10",
  "2067_6379": "Action",
  "I2067_6364;33_33258;33_33254": "Received",
  "I2067_6381;368_110840": "Show 1 to 10 of 100 results",
  "I2067_6407;23_39072": "Notification",
  "I2067_6336;835_98906": "T633",
  "I2067_6314;28_31246;28_31236": "Device Management",
  "I2067_6381;581_1954;7882_21473;497_44116": "1",
  "I2067_6381;581_1954;7882_22097;433_34538": "4",
  "I2136_11241;250_58575": "Receive Device",
  "I2067_6340;33_33258;33_33254": "In-transit",
  "I2067_6348;835_98906": "T633",
  "I2067_6412;21_22137": "Activitiy log",
  "I2067_6376;33_33258;33_33254": "Returning",
  "I2067_6373;18_21709": "7894561478",
  "2067_6385": "5",
  "I2067_6326;171_66633": "Device ID",
  "I2067_6359;18_21709": "Device ID 3",
  "2067_6355": "Action",
  "I2067_6381;581_1954;7882_21853;433_34538": "2",
  "I2067_6400;21_22137": "Vehicle Management",
  "I2067_6329;171_66633": "SIM Status",
  "2067_6367": "Action",
  "I2067_6381;581_1954;7882_26160;433_34538": "...",
  "I2067_6402;23_39072": "Approval",
  "I2067_6315;35_49503": "Receive Device",
  "I2067_6331;171_66633": "Device Status",
  "I2136_11240;250_58566": "Send Device",
  "I2067_6381;581_1954;7882_26275;433_34538": "11",
  "I2067_6390;21_22137": "Service Management",
  "I2067_6347;18_21709": "Device ID 2",
  "I2067_6350;18_21709": "Active",
  "I2136_11243;250_58566": "Manage SIM",
  "I2067_6393;21_22137": "Developer Portal",
  "I2067_6327;171_66633": "Device Name",
  "I2067_6337;18_21709": "789456134",
  "I2067_6372;835_98906": "T711",
  "I2067_6349;18_21709": "789456132",
  "I2067_6362;18_21709": "Active",
  "I2067_6319;318_97322": "SIM Status",
  "I2067_6352;33_33258;33_33254": "Received",
  "I2067_6424;318_97322": "Device Status",
  "I2067_6485;581_1954;7882_26229;433_34538": "10",
  "I2067_6423;318_97322": "SIM Status",
  "2067_6459": "Action",
  "I2067_6442;18_21709": "Inactive",
  "I2067_6494;21_22137": "Service Management",
  "I2067_6485;581_1954;7882_26275;433_34538": "11",
  "I2067_6425;121_59079": "Search...",
  "I2067_6477;18_21709": "7894561478",
  "I2067_6454;18_21709": "Active",
  "I2067_6418;28_31248;28_31243": "Receive Device",
  "I2067_6501;250_58575": "Receive Device",
  "I2067_6444;33_33258;33_33254": "In-transit",
  "2067_6447": "Action",
  "I2067_6434;171_66633": "Connected Status",
  "I2067_6500;250_58566": "Send Device",
  "I2067_6464;835_98906": "T633",
  "I2067_6485;368_110840": "Show 1 to 10 of 100 results",
  "I2067_6485;581_1954;7882_21473;497_44116": "1",
  "I2067_6466;18_21709": "Active",
  "I2067_6451;18_21709": "Device ID 2",
  "I2067_6511;23_39072": "Notification",
  "I2067_6516;21_22137": "Activitiy log",
  "I2067_6441;18_21709": "789456134",
  "I2067_6504;21_22137": "Vehicle Management",
  "I2067_6506;23_39072": "Approval",
  "I2067_6475;18_21709": "Device ID 4",
  "2067_6491": "Admin Portal",
  "I2067_6432;171_66633": "IMEI",
  "I2067_6485;581_1954;7882_21853;433_34538": "2",
  "I2067_6495;21_22137": "Identity Management",
  "I2067_6502;250_58566": "Manage Telecom Comp",
  "2067_6483": "Action",
  "I2067_6480;33_33258;33_33254": "Returning",
  "I2067_6418;28_31246;28_31236": "Device Management",
  "I2067_6431;171_66633": "Device Name",
  "2067_6489": "5",
  "I2067_6452;835_98906": "T633",
  "I2067_6456;33_33258;33_33254": "Received",
  "I2067_6485;581_1954;7882_22097;433_34538": "4",
  "I2067_6476;835_98906": "T711",
  "I2067_6419;35_49503": "Receive Device",
  "I2067_6439;18_21709": "Device ID 1",
  "I2067_6485;581_1954;7882_19131;497_44116": "10 / page",
  "I2067_6493;21_22137": "TOP",
  "I2067_6503;250_58566": "Manage SIM",
  "I2067_6433;171_66633": "SIM Status",
  "I2067_6515;21_22137": "Company Settings",
  "I2067_6453;18_21709": "789456132",
  "I2067_6478;18_21709": "Active",
  "I2067_6422;30_32021": "Export",
  "I2067_6440;835_98906": "T633",
  "I2067_6436;171_66633": "Action",
  "I2067_6485;581_1954;7882_26160;433_34538": "...",
  "I2067_6499;23_39072": "Device Management",
  "I2067_6496;21_22137": "Function Setting",
  "I2067_6418;28_31263;28_31261": ">",
  "I2067_6435;171_66633": "Device Status",
  "I2067_6430;171_66633": "Device ID",
  "2067_6471": "Action",
  "I2067_6465;18_21709": "365412647",
  "I2067_6497;21_22137": "Developer Portal",
  "I2067_6485;581_1954;7882_21971;433_34538": "3",
  "I2067_6468;33_33258;33_33254": "Received",
  "I2067_6463;18_21709": "Device ID 3",
  "I2067_6546;21_22137": "Service Management",
  "I2067_6535;30_32081": "Search",
  "I2067_6526;7636_13405;185_68545": "Device Name",
  "I2067_6545;21_22137": "TOP",
  "I2067_6568;21_22137": "Activitiy log",
  "I2067_6527;7636_13403": "IMEI",
  "I2067_6537;30_32137": "Cancel",
  "I2067_6551;23_39072": "Device Management",
  "I2067_6526;7636_13403": "Device Name",
  "I2067_6522;35_49551;28_31261": ">",
  "2067_6531": "SIM ID",
  "I2067_6555;250_58566": "Manage SIM",
  "I2067_6553;250_58575": "Receive Device",
  "I2067_6522;35_49548;28_31236": "Device Management",
  "I2067_6528;7636_13403": "Device Bluetooth ID",
  "I2067_6523;35_49503": "View Device",
  "I2067_6534;185_68545": "SIM ID",
  "I2067_6549;21_22137": "Developer Portal",
  "2067_6543": "Admin Portal",
  "2067_6541": "5",
  "I2067_6548;21_22137": "Function Setting",
  "I2067_6552;250_58566": "Send Device",
  "I2067_6536;154_64376": "Assigned Organization",
  "I2067_6527;7636_13405;185_68545": "IMEI",
  "I2067_6554;250_58566": "Manage Telecom Comp",
  "I2067_6556;21_22137": "Vehicle Management",
  "I2067_6558;23_39072": "Approval",
  "I2067_6567;21_22137": "Company Settings",
  "I2067_6522;35_49552;28_31243": "View Device",
  "I2067_6563;23_39072": "Notification",
  "I2067_6547;21_22137": "Identity Management",
  "I2067_6522;35_49550;28_31236": "Receive Device",
  "I2067_6528;7636_13405;185_68545": "Device Bluetooth ID",
  "I2067_6536;154_64377;75_53865;250_37580": "Select Assigned Organization",
  "I2067_6522;35_49549;28_31261": ">",
  "I2067_6642;21_22137": "Function Setting",
  "2067_6609": "Action",
  "I2067_6587;171_66633": "Telecom Company",
  "I2067_6650;21_22137": "Vehicle Management",
  "I2067_6643;21_22137": "Developer Portal",
  "I2067_6575;28_31263;28_31261": ">",
  "I2067_6631;581_1954;7882_19131;497_44116": "10 / page",
  "I2067_6616;33_33258;33_33254": "Inactive",
  "I2067_6586;171_66633": "SIM Name",
  "I2067_6648;250_58566": "Manage Telecom Comp",
  "I2067_6657;23_39072": "Notification",
  "I2067_6602;835_98906": "SIM ID 2",
  "I2067_6649;250_58575": "Manage SIM",
  "I2067_6631;581_1954;7882_21971;433_34538": "3",
  "I2067_6606;33_33258;33_33254": "Active",
  "I2067_6646;250_58566": "Send Device",
  "I2067_6604;18_21709": "SORACOM",
  "I2067_6580;318_97322": "Telecom Company",
  "I2067_6596;33_33258;33_33254": "Inactive",
  "I2067_6614;18_21709": "SORACOM",
  "I2165_11541;30_32140": "Add",
  "I2067_6603;18_21709": "SIM Name 2",
  "I2067_6588;171_66633": "Use Data (MB)",
  "I2067_6652;23_39072": "Approval",
  "I2067_6626;33_33258;33_33254": "Active",
  "I2067_6590;171_66633": "Action",
  "I2067_6575;28_31246;28_31236": "Device Management",
  "2067_6629": "Action",
  "I2166_11553;121_59079": "Search...",
  "I2067_6631;581_1954;7882_22097;433_34538": "4",
  "I2067_6640;21_22137": "Service Management",
  "I2067_6631;581_1954;7882_21853;433_34538": "2",
  "I2067_6631;581_1954;7882_26229;433_34538": "10",
  "I2067_6585;171_66633": "SIM ID",
  "I2067_6615;18_21709": "100",
  "2067_6635": "5",
  "I2067_6662;21_22137": "Activitiy log",
  "I2067_6645;23_39072": "Device Management",
  "I2067_6612;835_98906": "SIM ID 3",
  "I2067_6639;21_22137": "TOP",
  "I2067_6576;35_49503": "Manage SIM",
  "I2067_6581;121_59079": "Status",
  "I2067_6631;581_1954;7882_26160;433_34538": "...",
  "I2067_6631;368_110840": "Show 1 to 10 of 100 results",
  "I2067_6575;28_31248;28_31243": "Manage SIM",
  "I2067_6592;835_98906": "SIM ID 1",
  "I2067_6631;581_1954;7882_26275;433_34538": "11",
  "2067_6637": "Admin Portal",
  "I2067_6595;18_21709": "250",
  "I2067_6594;18_21709": "SORACOM",
  "I2067_6622;835_98906": "SIM ID 4",
  "I2067_6641;21_22137": "Identity Management",
  "I2067_6624;18_21709": "SORACOM",
  "2067_6599": "Action",
  "I2067_6613;18_21709": "SIM Name 3",
  "I2067_6623;18_21709": "SIM Name 4",
  "I2067_6593;18_21709": "SIM Name 1",
  "I2067_6605;18_21709": "300",
  "I2067_6647;250_58566": "Receive Device",
  "I2067_6631;581_1954;7882_21473;497_44116": "1",
  "I2067_6589;171_66633": "Status",
  "I2067_6625;18_21709": "500",
  "I2067_6661;21_22137": "Company Settings",
  "2067_6619": "Action",
  "2067_6679": "Device Management / Select Box",
  "I2067_7185;75_53940;75_53855;75_53818": "Returned",
  "I2067_6694;30_31919": "Delete",
  "I2067_7183;645_18156": "Are you sure you want to Delete ?",
  "I2168_11598;30_32128": "Active",
  "I2067_6692;30_32128": "Send",
  "2067_6770": "10",
  "2067_6747": "30",
  "I2067_7139;433_34538": "Telecom Company",
  "2067_7199": "User ID sent",
  "I2149_11382;611_93749": "Are your sure to make Device Inactive ?",
  "2067_7135": "Select SIM ID",
  "2067_7021": "29",
  "2067_6910": "27",
  "2067_6835": "Tu",
  "I2067_7181;30_32137": "Cancel",
  "2067_7052": "Sa",
  "I2067_7148;171_66633": "Use Data (MB)",
  "2067_7050": "Fr",
  "I2149_11365;611_95467;30_32137": "Close",
  "I2067_6669;75_53939;318_97283": "Device status",
  "2067_7211": "User ID received",
  "2067_6677": "Manage SIM > Action > Unsubscribe",
  "2067_6792": "20",
  "I2067_6686;75_53940;75_53852;75_53818": "Active",
  "2067_7046": "We",
  "2067_7075": "7",
  "2067_7083": "11",
  "2067_6940": "Mo",
  "2067_6731": "Mo",
  "I2166_11578;611_93387": "The SIM has been successfully deleted.",
  "2067_6925": "Start Date - End Date",
  "I2067_7177;33_33258;33_33254": "Active",
  "2067_6962": "2",
  "2067_6671": "Receive Device > SIM Status",
  "I2067_7141;121_59079": "Search...",
  "2067_6990": "15",
  "2067_7062": "1",
  "I2067_7167;18_21709": "SIM Name 3",
  "2067_6816": "31",
  "I2067_7169;18_21709": "250",
  "2067_6680": "Device Management / Modal",
  "I2067_6669;75_53940;75_53856;75_53818": "Returning",
  "2067_7027": "1",
  "I2140_11312;623_21698": "Info",
  "2067_7107": "22",
  "2067_6689": "Action",
  "I2067_7178;368_110840": "Show 1 to 10 of 100 results",
  "2067_6948": "Fr",
  "I2067_6712;30_32128": "Deactive",
  "2067_6673": "Receive Device > Device Status",
  "2067_6794": "21",
  "I2067_6693;30_32128": "Edit",
  "2067_6907": "26",
  "I2067_6669;75_53940;75_53854;75_53818": "Sent",
  "2067_7036": "September 2023",
  "2067_6837": "We",
  "I2067_7173;835_98906": "SIM ID 4",
  "2168_11591": "Manage SIM > Status",
  "I2149_11382;611_93748": "Warning",
  "2067_6956": "30",
  "2067_6678": "Send Device > Telecom Company > Action > Delete",
  "2067_6739": "Fr",
  "I2067_6695;30_32128": "Operation Log",
  "I2067_7154;18_21709": "SORACOM",
  "2067_6733": "Tu",
  "2067_7195": "10:12 2024-02-24",
  "I2067_7162;18_21709": "250",
  "I2067_6702;30_32128": "Receive",
  "I2067_7149;171_66633": "Status",
  "2067_7005": "22",
  "I2067_6696;30_32128": "Receive Returns",
  "2067_7098": "18",
  "2067_6942": "Tu",
  "I2140_11309;611_93387": "Import successfully.",
  "2067_7088": "13",
  "2067_6787": "18",
  "2067_7064": "2",
  "2067_6789": "19",
  "2067_6964": "3",
  "2067_7194": "10:12 2024-02-23",
  "2067_7054": "Su",
  "I2067_7183;645_18160;30_31931": "Delete",
  "2067_6994": "17",
  "2067_7212": "User ID Returned",
  "I2067_7178;581_1954;7882_26229;433_34538": "10",
  "I2166_11565;611_93387": "The SIM has been successfully created.",
  "2067_6774": "12",
  "2067_6861": "5",
  "2067_6757": "4",
  "2067_6879": "13",
  "I2067_7178;581_1954;7882_21473;497_44116": "1",
  "2067_7048": "Th",
  "I2067_7184;75_53940;75_53854;75_53818": "Unsubscribed",
  "2067_6872": "10",
  "2067_6855": "2",
  "2067_7070": "5",
  "2067_6785": "17",
  "2067_6779": "14",
  "2067_7200": "User ID received return",
  "2067_6762": "6",
  "I2168_11597;30_32128": "All",
  "I2140_11313;623_21687": "An error has occurred, please try again or contact the administrator.",
  "I2149_11365;611_93748": "Warning",
  "2067_6885": "16",
  "2067_6960": "1",
  "2067_7131": "3",
  "2067_6916": "30",
  "2067_6812": "29",
  "2067_6833": "Mo",
  "2067_6849": "30",
  "2067_7023": "30",
  "2067_6749": "31",
  "I2067_6704;30_32128": "Operation Log",
  "2067_6803": "25",
  "2067_6751": "1",
  "2067_6753": "2",
  "I2067_7178;581_1954;7882_21853;433_34538": "2",
  "I2067_7170;33_33258;33_33254": "Inactive",
  "2067_7103": "20",
  "I2067_7186;611_93749": "Are you sure you want to Unsubscribe ?",
  "2067_6841": "Fr",
  "2067_6845": "Su",
  "2067_7094": "16",
  "2067_7116": "26",
  "2067_7100": "19",
  "2168_11594": "Status",
  "2067_7025": "31",
  "2067_7123": "29",
  "2067_6894": "20",
  "2067_7119": "27",
  "2067_6800": "24",
  "2067_7066": "3",
  "I2140_11314;611_93387": "The Device has been successfully updated.",
  "2067_7003": "21",
  "2067_7114": "25",
  "2067_7207": "10:12 2024-02-22",
  "2067_6946": "Th",
  "2067_7109": "23",
  "I2067_7145;171_66633": "SIM ID",
  "2067_6983": "12",
  "I2067_7166;835_98906": "SIM ID 3",
  "I2140_11310;611_93387": "The Device Setting has been successfully deleted.",
  "I2149_11382;611_95468;611_95349": "Confirm",
  "2067_7111": "24",
  "2067_6868": "8",
  "2067_6766": "8",
  "I2067_7155;18_21709": "250",
  "2067_6966": "4",
  "I2067_7159;835_98906": "SIM ID 2",
  "2067_7129": "2",
  "2067_6864": "6",
  "2067_6998": "19",
  "I2067_7152;835_98906": "SIM ID 1",
  "I2067_7186;611_95467;30_32137": "Close",
  "2067_6857": "3",
  "2067_7105": "21",
  "I2067_7186;611_95468;611_95349": "Unsubscribe",
  "2067_6684": "Send Device > View > Operation Log > Date",
  "2067_7073": "6",
  "2067_7029": "2",
  "2067_6759": "5",
  "I2067_7178;581_1954;7882_22097;433_34538": "4",
  "2067_6881": "14",
  "I2067_7183;645_18159;30_32137": "Close",
  "2067_6675": "Send Device > Add > Operation Log",
  "2067_6755": "3",
  "I2149_11365;611_93749": "Are your sure to make Device Active ?",
  "I2149_11365;611_93741": "Are you sure to send Device?",
  "I2149_11365;611_93742": "Are you sure to return Device?",
  "I2166_11557;611_93387": "Unsubscribe successfully.",
  "I2067_7184;75_53940;75_53853;75_53818": "Subscribed",
  "I2067_7161;18_21709": "SORACOM",
  "2067_6699": "Action",
  "2067_6975": "8",
  "2067_6808": "27",
  "I2067_6669;75_53940;75_53853;75_53818": "In-stock",
  "I2067_7140;433_34538": "Status",
  "I2067_7168;18_21709": "SORACOM",
  "2067_6958": "31",
  "I2149_11382;611_95467;30_32137": "Close",
  "2067_6796": "22",
  "2067_6934": "August 2023",
  "2067_6682": "Send Device  > Action (Send / Edit / Delete / Operation Log / Receive Returns)",
  "2067_7125": "30",
  "2067_6887": "17",
  "2067_6843": "Sa",
  "2067_6992": "16",
  "2067_7090": "14",
  "I2067_7174;18_21709": "SIM Name 4",
  "2067_6674": "Send Device > Add > Select SIM ID",
  "2067_6820": "2",
  "I2067_6711;30_32128": "Active",
  "2067_7042": "Mo",
  "2067_6676": "Receive Device > Operation Log",
  "2067_6743": "Su",
  "2067_7014": "26",
  "2067_7189": "Operation Log",
  "2067_6851": "31",
  "I2067_7156;33_33258;33_33254": "Active",
  "2067_6944": "We",
  "2067_7085": "12",
  "I2067_7153;18_21709": "SIM Name 1",
  "2067_6683": "Receive Device > Action ( Receive/ Returns / Operation Log)",
  "2067_6725": "August 2023",
  "I2067_7178;581_1954;7882_21971;433_34538": "3",
  "I2149_11365;611_95468;611_95349": "Confirm",
  "2067_6777": "13",
  "I2067_7185;75_53939;318_97328": "Device Status",
  "2067_6781": "15",
  "2067_7017": "27",
  "2067_6672": "Manage SIM > Action ( Edit / Active/ Inactive/ Unsubscribe )",
  "I2067_7176;18_21709": "250",
  "2067_6805": "26",
  "2067_6986": "13",
  "2067_7203": "Operation Log",
  "I2067_7163;33_33258;33_33254": "Subscribed",
  "2067_7044": "Tu",
  "I2067_7185;75_53940;75_53853;75_53818": "In-transit",
  "2067_7007": "23",
  "I2067_6710;30_32128": "Edit",
  "2067_6922": "3",
  "I2067_7160;18_21709": "SIM Name 2",
  "2067_6707": "Action",
  "2067_7009": "24",
  "I2168_11599;30_32128": "Deactive",
  "2067_7127": "1",
  "I2067_7185;75_53940;75_53854;75_53818": "Received",
  "2067_7096": "17",
  "2067_7079": "9",
  "2067_7001": "20",
  "2067_6685": "Receive Device > View > Operation Log > Date",
  "2067_6891": "19",
  "2067_7198": "User ID added",
  "2067_6914": "29",
  "I2067_6703;30_32128": "Return",
  "I2067_7178;581_1954;7882_19131;497_44116": "10 / page",
  "2067_6798": "23",
  "2067_6973": "7",
  "2067_6950": "Sa",
  "I2067_6669;75_53940;75_53855;75_53818": "Delivered",
  "2067_6839": "Th",
  "2067_6735": "We",
  "2067_6716": "Start Date - End Date",
  "2067_6810": "28",
  "2067_7077": "8",
  "I2142_11351;611_93387": "The Device has been successfully created.",
  "2067_6996": "18",
  "I2067_6686;75_53939;318_97283": "Status",
  "2067_6772": "11",
  "2067_6764": "7",
  "2067_6952": "Su",
  "I2067_7186;611_93748": "Unsubscribe",
  "2067_7019": "28",
  "2067_6876": "12",
  "2067_7012": "25",
  "2067_6905": "25",
  "2067_6741": "Sa",
  "2067_7060": "31",
  "2067_6912": "28",
  "I2067_7178;581_1954;7882_26160;433_34538": "...",
  "I2067_7178;581_1954;7882_26275;433_34538": "11",
  "2067_6681": "Send Device > Device Status > Selected SIM ID > Status",
  "2067_7193": "10:12 2024-02-22",
  "2067_6883": "15",
  "2067_7058": "30",
  "2067_7092": "15",
  "2067_6981": "11",
  "2067_6920": "2",
  "I2067_7146;171_66633": "SIM Name",
  "I2067_6713;30_32128": "Unsubcribe",
  "2067_6979": "10",
  "2067_6988": "14",
  "I2067_7182;30_32140": "Save",
  "2067_7121": "28",
  "I2067_7183;645_18155": "Delete",
  "2067_6783": "16",
  "2067_7208": "10:12 2024-02-23",
  "2067_7068": "4",
  "2067_6918": "1",
  "I2140_11311;623_21593": "Warning",
  "2067_7081": "10",
  "I2166_11564;611_93387": "The SIM has been successfully updated.",
  "2067_6818": "1",
  "2067_6874": "11",
  "2067_6737": "Th",
  "2067_6814": "30",
  "I2147_11358;611_93387": "The Device list has been successfully imported.",
  "2067_6866": "7",
  "2067_6977": "9",
  "2067_6896": "21",
  "2067_6971": "6",
  "2067_6853": "1",
  "2067_6898": "22",
  "I2067_7175;18_21709": "SORACOM",
  "2067_6902": "24",
  "2067_6968": "5",
  "2067_6859": "4",
  "I2067_7184;75_53939;318_97328": "SIM Status",
  "2067_6900": "23",
  "I2067_7147;171_66633": "Telecom Company",
  "2067_6827": "September 2023",
  "2067_6768": "9",
  "2067_6670": "Send Device > Device Status",
  "2067_6870": "9",
  "2067_6889": "18",
  "I2067_6686;75_53940;75_53853;75_53818": "Inactive",
  "I2067_7272;18_21709": "6512343",
  "I2067_7292;581_1954;7882_26275;433_34538": "11",
  "I2067_7256;18_21709": "Mazda",
  "I2067_7236;171_66633": "VIN",
  "I2067_7237;171_66633": "Assigned Organization",
  "I2067_7305;23_39072": "Device Management",
  "I2067_7228;121_59079": "Search...",
  "I2067_7239;171_66633": "Action",
  "I2067_7307;21_22137": "Vehicle Management",
  "I2067_7247;18_21709": "Sumitomo Shoji",
  "I2067_7238;171_66633": "Vehicle Status",
  "I2067_7292;581_1954;7882_19131;497_44116": "10 / page",
  "I2067_7255;835_98906": "Vehicle ID 2",
  "I2067_7248;33_33258;33_33254": "In-stock",
  "I2067_7218;28_31246;28_31236": "Vehicle Management",
  "I2067_7245;18_21709": "Sedan",
  "I2067_7317;23_39072": "Notification",
  "I2067_7301;21_22137": "Service Management",
  "I2067_7246;18_21709": "6512345",
  "I2067_7271;18_21709": "SUV",
  "I2067_7303;21_22137": "Function Setting",
  "I2067_7292;581_1954;7882_22097;433_34538": "4",
  "I2067_7218;28_31263;28_31261": ">",
  "2067_7296": "5",
  "I2067_7281;835_98906": "Vehicle ID 4",
  "I2067_7223;30_32021": "Import",
  "I2067_7284;18_21709": "SUV",
  "I2067_7309;250_58566": "Manage Vehicle Setting",
  "I2067_7273;18_21709": "Sumitomo Shoji",
  "I2067_7219;388_23148;30_32140": "Add",
  "I2067_7285;18_21709": "6512348",
  "I2067_7218;28_31248;28_31243": "Manage Vehicle",
  "I2067_7292;581_1954;7882_21853;433_34538": "2",
  "I2067_7292;368_110840": "Show 1 to 10 of 100 results",
  "I2067_7258;18_21709": "Sedan",
  "I2067_7269;18_21709": "Mazda",
  "2067_7251": "Action",
  "I2067_7260;18_21709": "Sumitomo Shoji",
  "I2067_7287;33_33258;33_33254": "Returning",
  "I2067_7225;30_31991": "Export QR Code",
  "I2067_7292;581_1954;7882_21473;497_44116": "1",
  "I2067_7244;18_21709": "Mazda 3",
  "I2067_7268;835_98906": "Vehicle ID 3",
  "I2067_7219;388_23101": "Manage Vehicle",
  "I2067_7310;250_58566": "Reminder",
  "I2067_7292;581_1954;7882_21971;433_34538": "3",
  "I2067_7302;21_22137": "Identity Management",
  "I2067_7233;171_66633": "Brand",
  "I2067_7304;21_22137": "Developer Portal",
  "I2067_7224;30_32021": "Export CSV",
  "I2067_7242;835_98906": "Vehicle ID 1",
  "I2067_7300;21_22137": "TOP",
  "2067_7290": "Action",
  "I2067_7274;33_33258;33_33254": "Delivered",
  "2067_7264": "Action",
  "I2067_7292;581_1954;7882_26160;433_34538": "...",
  "I2067_7243;18_21709": "Mazda",
  "I2067_7232;171_66633": "Vehicle ID",
  "I2067_7257;18_21709": "Mazda 5",
  "I2067_7234;171_66633": "Model",
  "I2067_7261;33_33258;33_33254": "Sent",
  "I2067_7227;318_97322": "Vehicle Status",
  "I2067_7235;171_66633": "Type",
  "I2067_7282;18_21709": "Mazda",
  "I2067_7292;581_1954;7882_26229;433_34538": "10",
  "I2067_7312;23_39072": "Approval",
  "2067_7298": "Admin Portal",
  "I2067_7226;318_97322": "Type",
  "2067_7277": "Action",
  "I2067_7308;250_58575": "Manage Vehicle",
  "I2067_7322;21_22137": "Activitiy log",
  "I2067_7259;18_21709": "6512342",
  "I2067_7283;18_21709": "CX-5",
  "I2067_7321;21_22137": "Company Settings",
  "I2067_7270;18_21709": "CX-5",
  "I2067_7286;18_21709": "Sumitomo Shoji",
  "I2067_7334;30_32021": "Export CSV",
  "I2067_7415;23_39072": "Device Management",
  "I2067_7417;21_22137": "Vehicle Management",
  "I2067_7392;18_21709": "Mazda",
  "I2067_7346;171_66633": "VIN",
  "I2067_7367;18_21709": "Mazda 5",
  "I2067_7335;30_32021": "Export QR Code",
  "2067_7400": "Action",
  "I2067_7384;33_33258;33_33254": "Delivered",
  "I2067_7411;21_22137": "Service Management",
  "I2067_7402;581_1954;7882_21473;497_44116": "1",
  "I2067_7394;18_21709": "SUV",
  "I2067_7368;18_21709": "Sedan",
  "I2067_7412;21_22137": "Identity Management",
  "2067_7406": "5",
  "I2067_7329;388_23148;30_32140": "Add",
  "I2067_7353;18_21709": "Mazda",
  "I2067_7354;18_21709": "Mazda 3",
  "I2067_7391;835_98906": "Vehicle ID 4",
  "I2067_7343;171_66633": "Brand",
  "I2067_7369;18_21709": "6512342",
  "I2067_7358;33_33258;33_33254": "In-stock",
  "I2067_7402;581_1954;7882_26229;433_34538": "10",
  "I2067_7402;581_1954;7882_26160;433_34538": "...",
  "I2067_7413;21_22137": "Function Setting",
  "I2067_7422;23_39072": "Approval",
  "I2067_7402;581_1954;7882_26275;433_34538": "11",
  "I2067_7379;18_21709": "Mazda",
  "I2067_7338;121_59079": "Search...",
  "2067_7408": "Admin Portal",
  "I2067_7410;21_22137": "TOP",
  "I2067_7402;581_1954;7882_19131;497_44116": "10 / page",
  "I2067_7328;28_31263;28_31261": ">",
  "I2067_7383;18_21709": "Sumitomo Shoji",
  "I2067_7418;250_58575": "Manage Vehicle",
  "I2067_7427;23_39072": "Notification",
  "I2067_7344;171_66633": "Model",
  "I2067_7342;171_66633": "Vehicle ID",
  "2067_7361": "Action",
  "I2067_7431;21_22137": "Company Settings",
  "2067_7374": "Action",
  "I2067_7382;18_21709": "6512343",
  "I2067_7402;581_1954;7882_22097;433_34538": "4",
  "I2067_7345;171_66633": "Type",
  "I2067_7357;18_21709": "Sumitomo Shoji",
  "I2067_7371;33_33258;33_33254": "Sent",
  "I2067_7378;835_98906": "Vehicle ID 3",
  "I2067_7333;30_32021": "Import",
  "I2067_7356;18_21709": "6512345",
  "I2067_7328;28_31248;28_31243": "Manage Vehicle",
  "I2067_7328;28_31246;28_31236": "Vehicle Management",
  "I2067_7381;18_21709": "SUV",
  "I2067_7380;18_21709": "CX-5",
  "I2067_7420;250_58566": "Reminder",
  "2067_7387": "Action",
  "I2067_7352;835_98906": "Vehicle ID 1",
  "I2067_7414;21_22137": "Developer Portal",
  "I2067_7329;388_23101": "Manage Vehicle",
  "I2067_7348;171_66633": "Vehicle Status",
  "I2067_7355;18_21709": "Sedan",
  "I2067_7419;250_58566": "Manage Vehicle Setting",
  "I2067_7402;581_1954;7882_21971;433_34538": "3",
  "I2067_7365;835_98906": "Vehicle ID 2",
  "I2067_7395;18_21709": "6512348",
  "I2067_7402;581_1954;7882_21853;433_34538": "2",
  "I2067_7366;18_21709": "Mazda",
  "I2067_7396;18_21709": "Sumitomo Shoji",
  "I2067_7393;18_21709": "CX-5",
  "I2067_7397;33_33258;33_33254": "Returning",
  "I2067_7347;171_66633": "Assigned Organization",
  "I2067_7337;318_97322": "Status",
  "I2067_7402;368_110840": "Show 1 to 10 of 100 results",
  "I2067_7336;318_97322": "Type",
  "I2067_7370;18_21709": "Sumitomo Shoji",
  "I2067_7349;171_66633": "Action",
  "I2067_7432;21_22137": "Activitiy log",
  "2067_7465": "Action",
  "I2067_7481;18_21709": "Hatchback",
  "I2067_7482;18_21709": "Mazda 2",
  "I2067_7518;21_22137": "Company Settings",
  "I2067_7502;23_39072": "Device Management",
  "I2067_7507;250_58566": "Reminder",
  "I2067_7439;388_23148;30_32140": "Add",
  "I2067_7446;121_59079": "Search...",
  "I2067_7489;581_1954;7882_22097;433_34538": "4",
  "I2067_7438;28_31246;28_31236": "Vehicle Management",
  "I2067_7489;581_1954;7882_21473;497_44116": "1",
  "I2067_7483;18_21709": "2023",
  "2067_7495": "Admin Portal",
  "I2067_7444;30_31991": "Export",
  "I2067_7445;318_97322": "Type",
  "I2067_7455;171_66633": "Action",
  "I2067_7497;21_22137": "TOP",
  "I2067_7458;18_21709": "Mazda",
  "I2067_7472;18_21709": "2021",
  "I2067_7489;581_1954;7882_21853;433_34538": "2",
  "I2067_7451;171_66633": "Type",
  "I2067_7459;18_21709": "Sedan",
  "I2067_7438;28_31263;28_31261": ">",
  "I2067_7501;21_22137": "Developer Portal",
  "I2067_7489;581_1954;7882_26160;433_34538": "...",
  "I2067_7519;21_22137": "Activitiy log",
  "I2067_7500;21_22137": "Function Setting",
  "I2067_7461;18_21709": "2022",
  "I2067_7504;21_22137": "Vehicle Management",
  "I2067_7443;30_32021": "Import",
  "I2067_7454;171_66633": "Color",
  "I2067_7499;21_22137": "Identity Management",
  "I2067_7452;171_66633": "Model",
  "I2067_7453;171_66633": "Model year",
  "I2067_7462;18_21709": "Black",
  "I2067_7460;18_21709": "Mazda 3",
  "I2067_7480;18_21709": "Mazda",
  "I2067_7471;18_21709": "Mazda CX-5",
  "I2067_7450;171_66633": "Brand",
  "I2067_7489;581_1954;7882_26275;433_34538": "11",
  "I2067_7470;18_21709": "SUV",
  "I2067_7489;581_1954;7882_19131;497_44116": "10 / page",
  "I2067_7489;581_1954;7882_26229;433_34538": "10",
  "I2067_7514;23_39072": "Notification",
  "I2067_7498;21_22137": "Service Management",
  "I2067_7439;388_23101": "Manage Vehicle Setting",
  "I2067_7438;28_31248;28_31243": "Manage Vehicle Setting",
  "I2067_7469;18_21709": "Mazda",
  "2067_7487": "Action",
  "I2067_7484;18_21709": "White",
  "2067_7493": "5",
  "I2067_7473;18_21709": "Gray",
  "I2067_7506;250_58575": "Manage Vehicle Setting",
  "2067_7476": "Action",
  "I2067_7509;23_39072": "Approval",
  "I2067_7489;581_1954;7882_21971;433_34538": "3",
  "I2067_7505;250_58566": "Manage Vehicle",
  "I2067_7489;368_110840": "Show 1 to 10 of 100 results",
  "I2067_7537;171_66633": "Brand",
  "I2067_7576;581_1954;7882_21853;433_34538": "2",
  "I2067_7576;581_1954;7882_26160;433_34538": "...",
  "2067_7582": "Admin Portal",
  "I2067_7556;18_21709": "Mazda",
  "I2067_7525;28_31263;28_31261": ">",
  "I2067_7531;30_32021": "Export",
  "I2067_7587;21_22137": "Function Setting",
  "2067_7574": "Action",
  "I2067_7576;368_110840": "Show 1 to 10 of 100 results",
  "I2067_7567;18_21709": "Mazda",
  "I2067_7591;21_22137": "Vehicle Management",
  "I2067_7533;121_59079": "Search...",
  "I2067_7541;171_66633": "Color",
  "I2067_7559;18_21709": "2021",
  "I2067_7526;388_23101": "Manage Vehicle Setting",
  "I2067_7540;171_66633": "Model year",
  "I2067_7585;21_22137": "Service Management",
  "I2067_7548;18_21709": "2022",
  "I2067_7525;28_31248;28_31243": "Manage Vehicle Setting",
  "I2067_7570;18_21709": "2023",
  "I2067_7538;171_66633": "Type",
  "I2067_7606;21_22137": "Activitiy log",
  "2067_7580": "5",
  "I2067_7530;30_32021": "Import",
  "I2067_7576;581_1954;7882_26275;433_34538": "11",
  "2067_7563": "Action",
  "I2067_7549;18_21709": "Black",
  "I2067_7558;18_21709": "Mazda CX-5",
  "I2067_7532;318_97322": "Type",
  "I2067_7593;250_58575": "Manage Vehicle Setting",
  "I2067_7584;21_22137": "TOP",
  "I2067_7592;250_58566": "Manage Vehicle",
  "I2067_7594;250_58566": "Reminder",
  "I2067_7589;23_39072": "Device Management",
  "I2067_7586;21_22137": "Identity Management",
  "I2067_7576;581_1954;7882_21473;497_44116": "1",
  "I2067_7588;21_22137": "Developer Portal",
  "I2067_7560;18_21709": "Gray",
  "I2067_7545;18_21709": "Mazda",
  "I2067_7569;18_21709": "Mazda 2",
  "I2067_7576;581_1954;7882_22097;433_34538": "4",
  "I2067_7568;18_21709": "Hatchback",
  "I2067_7547;18_21709": "Mazda 3",
  "I2067_7526;388_23148;30_32140": "Add",
  "I2067_7539;171_66633": "Model",
  "I2067_7576;581_1954;7882_21971;433_34538": "3",
  "I2067_7576;581_1954;7882_26229;433_34538": "10",
  "I2067_7546;18_21709": "Sedan",
  "I2067_7596;23_39072": "Approval",
  "I2067_7601;23_39072": "Notification",
  "I2067_7576;581_1954;7882_19131;497_44116": "10 / page",
  "I2067_7557;18_21709": "SUV",
  "I2067_7571;18_21709": "White",
  "I2067_7605;21_22137": "Company Settings",
  "I2067_7525;28_31246;28_31236": "Vehicle Management",
  "2067_7552": "Action",
  "I2067_7542;171_66633": "Action",
  "I2067_7719;35_49503": "View Vehicle",
  "2067_7739": "Length",
  "I2067_7812;21_22137": "Company Settings",
  "I2067_7791;21_22137": "TOP",
  "I2067_7803;23_39072": "Approval",
  "I2067_7795;21_22137": "Developer Portal",
  "I2067_7813;21_22137": "Activitiy log",
  "I2067_7732;154_64376": "* Vehicle Model",
  "2067_7768": "Kg",
  "I2067_7808;23_39072": "Notification",
  "2067_7752": "mm",
  "2067_7789": "Admin Portal",
  "2067_7774": "Vehicle Assignment",
  "2067_7763": "Max Weight",
  "2067_7747": "Width",
  "I2067_7729;154_64376": "* Vehicle Type",
  "I2067_7801;250_58566": "Reminder",
  "I2067_7735;7636_13403": "Number Plate",
  "I2067_7735;7636_13405;185_68545": "Number Plate",
  "I2067_7718;35_49549;28_31261": ">",
  "I2067_7718;35_49548;28_31236": "Vehicle Management",
  "I2067_7728;154_64376": "* Vehicle Brand",
  "I2067_7792;21_22137": "Service Management",
  "I2067_7718;35_49550;28_31236": "Manage Vehicle",
  "I2067_7750;185_68545": "Number Plate",
  "I2067_7734;7636_13405;185_68545": "VIN",
  "2067_7760": "mm",
  "I2067_7718;35_49552;28_31243": "View Vehicle",
  "I2067_7793;21_22137": "Identity Management",
  "2067_7787": "5",
  "I2067_7784;30_32137": "Cancel",
  "I2067_7733;154_64376": "Vehicle Model Year",
  "I2067_7799;250_58575": "Manage Vehicle",
  "I2067_7798;21_22137": "Vehicle Management",
  "I2067_7796;23_39072": "Device Management",
  "2067_7771": "Advance Setting",
  "2067_7783": "Towing Service Information",
  "2067_7744": "mm",
  "I2067_7734;7636_13403": "* VIN",
  "I2067_7729;154_64377;75_53865;250_37580": "Select Vehicle Type",
  "I2067_7800;250_58566": "Manage Vehicle Setting",
  "I2067_7718;35_49551;28_31261": ">",
  "I2067_7742;185_68545": "Number Plate",
  "I2067_7758;185_68545": "Number Plate",
  "2067_7780": "Insurance Service Information",
  "I2067_7732;154_64377;75_53865;250_37580": "Select Vehicle Model",
  "2067_7777": "Vehicle Inspection Information",
  "2067_7724": "General Setting",
  "I2067_7730;154_64377;75_53865;250_37580": "Select Vehicle Color",
  "I2067_7733;154_64377;75_53865;250_37580": "Select Vehicle Model Year",
  "I2067_7766;185_68545": "Number Plate",
  "2067_7755": "Height",
  "I2067_7728;154_64377;75_53865;250_37580": "Select Vehicle Brand",
  "I2067_7730;154_64376": "* Vehicle Color",
  "I2067_7794;21_22137": "Function Setting",
  "I2067_7922;35_49551;28_31261": ">",
  "I2067_7937;154_64377;75_53865;250_37564": "Select Vehicle Model Year",
  "I2067_7936;154_64376": "Vehicle Type",
  "2067_8000": "Device ID",
  "2067_7981": "Odometer",
  "2067_7995": "Device Linkage",
  "2067_8043": "Admin Portal",
  "2067_7928": "General Setting",
  "I2067_7936;154_64377;75_53865;250_37564": "Select Vehicle Type",
  "2067_8009": "Vehicle Assignment",
  "2087_10900": "Select Vehicle Brand",
  "I2067_7962;75_53807": "Height Number",
  "I2067_8034;154_64377;75_53865;250_37564": "Enter Contract Start Date",
  "I2067_8011;154_64377;75_53865;250_37564": "Select Assigned Organization",
  "I2067_8035;154_64376": "Towing Service Contract Expiry Date",
  "I2067_8057;23_39072": "Approval",
  "I2067_7938;7636_13405;75_53807": "VIN",
  "I2067_7934;154_64377;75_53865;250_37564": "Select Vehicle Color",
  "I2067_7932;154_64376": "Vehicle Brand",
  "2087_10909": "Select Vehicle Color",
  "2087_10911": "Enter VIN",
  "2067_8023": "Insurance Service Information",
  "I2067_7937;154_64376": "Vehicle Model Year",
  "I2067_8046;21_22137": "Service Management",
  "I2067_8050;23_39072": "Device Management",
  "I2067_8004;30_32140": "Search",
  "I2067_8005;7636_13403": "Device Bluetooth ID",
  "I2067_7922;35_49552;28_31243": "Add Vehicle",
  "2067_7986": "km",
  "I2067_7932;154_64377;75_53865;250_37564": "Select Vehicle Brand",
  "2087_10906": "Select Vehicle Model Year",
  "2067_7951": "Width",
  "I2067_8005;7636_13405;185_68545": "Device Bluetooth ID",
  "I2067_8026;154_64377;75_53865;250_37564": "Enter Contract Start Date",
  "2087_10904": "Select Vehicle Model",
  "I2067_8035;154_64377;75_53865;250_37564": "Enter Contract Expiry Date",
  "I2067_8066;21_22137": "Company Settings",
  "2067_8031": "Towing Service Information",
  "I2067_8003;75_53807": "Name",
  "I2067_7991;743_17642": "Choose files to upload",
  "I2067_7938;7636_13403": "VIN",
  "I2067_7946;75_53807": "Length Number",
  "I2067_8047;21_22137": "Identity Management",
  "2067_7943": "Length",
  "2067_8015": "Vehicle Inspection Information",
  "I2067_8045;21_22137": "TOP",
  "2067_7989": "Vehicle Images",
  "I2067_7991;743_17640": "or",
  "I2067_8062;23_39072": "Notification",
  "2067_7976": "Advance Setting",
  "I2067_7933;154_64376": "Vehicle Model",
  "2067_7964": "mm",
  "I2067_8052;21_22137": "Vehicle Management",
  "I2067_8037;30_32137": "Cancel",
  "I2067_7922;35_49549;28_31261": ">",
  "I2067_7939;7636_13405;75_53807": "Number Plate",
  "2087_10902": "Select Vehicle Type",
  "2067_7967": "Max Weight",
  "I2067_8011;154_64376": "Assigned Organization",
  "I2067_8018;154_64376": "Inspection Date",
  "I2067_7991;743_17639": "Drop your files here",
  "I2067_8027;154_64376": "Insurance Service Contract Expiry Date",
  "I2067_8055;250_58566": "Reminder",
  "I2067_8049;21_22137": "Developer Portal",
  "I2067_8027;154_64377;75_53865;250_37564": "Enter Contract Expiry Date",
  "I2067_7984;75_53807": "Name",
  "I2067_7939;7636_13403": "Number Plate",
  "I2067_8018;154_64377;75_53865;250_37564": "Enter Inspection Date",
  "2067_7948": "mm",
  "I2067_8054;250_58566": "Manage Vehicle Setting",
  "I2067_7922;35_49548;28_31236": "Vehicle Management",
  "I2067_7933;154_64377;75_53865;250_37564": "Select Vehicle Model",
  "I2067_8019;154_64377;75_53865;250_37564": "Enter Inspection Due Date",
  "I2067_8034;154_64376": "Towing Service Contract Start Date",
  "2067_7959": "Height",
  "I2067_8053;250_58575": "Manage Vehicle",
  "2067_7956": "mm",
  "I2067_8038;30_32140": "Next",
  "I2067_7970;75_53807": "Max Number",
  "I2067_7922;35_49550;28_31236": "Manage Vehicle",
  "I2067_7923;35_49503": "Add Vehicle",
  "I2067_8019;154_64376": "Inspection Due Date",
  "I2067_8048;21_22137": "Function Setting",
  "2067_8041": "5",
  "I2067_8067;21_22137": "Activitiy log",
  "I2067_7934;154_64376": "Vehicle Color",
  "I2067_8026;154_64376": "Insurance Service Contract Start Date",
  "2067_7972": "Kg",
  "I2067_7954;75_53807": "Width Number",
  "I2067_8178;154_64376": "Insurance Service Contract Expiry Date",
  "I2067_8213;23_39072": "Notification",
  "I2067_8169;154_64376": "Inspection Date",
  "I2067_8196;21_22137": "TOP",
  "I2067_8162;154_64377;75_53865;250_37580": "Select Assigned Organization",
  "I2067_8201;23_39072": "Device Management",
  "I2067_8206;250_58566": "Reminder",
  "I2067_8170;154_64377;75_53865;250_37580": "Enter Inspection Due Date",
  "I2067_8084;154_64377;75_53865;250_37580": "Select Vehicle Type",
  "I2067_8097;185_68545": "Number Plate",
  "I2067_8154;185_68545": "Device ID",
  "I2067_8090;7636_13405;185_68545": "Number Plate",
  "I2067_8218;21_22137": "Activitiy log",
  "2067_8118": "Max Weight",
  "2067_8110": "Height",
  "I2067_8089;7636_13403": "* VIN",
  "2067_8123": "Kg",
  "I2067_8185;154_64376": "Towing Service Contract Start Date",
  "2067_8194": "Admin Portal",
  "2067_8182": "Towing Service Information",
  "2067_8115": "mm",
  "2067_8099": "mm",
  "I2067_8085;154_64377;75_53865;250_37580": "Select Vehicle Color",
  "I2067_8135;121_58638": "Odometer",
  "I2067_8121;185_68545": "Number Plate",
  "2067_8137": "km",
  "I2067_8185;154_64377;75_53865;250_37580": "Enter Contract Start Date",
  "I2067_8084;154_64376": "* Vehicle Model",
  "2067_8132": "Odometer",
  "I2067_8085;154_64376": "* Vehicle Color",
  "2067_8192": "5",
  "I2067_8217;21_22137": "Company Settings",
  "I2067_8073;35_49596;28_31261": ">",
  "2067_8174": "Insurance Service Information",
  "2067_8102": "Width",
  "2067_8094": "Length",
  "I2067_8087;154_64376": "* Vehicle Type",
  "I2067_8177;154_64377;75_53865;250_37580": "Enter Contract Start Date",
  "I2067_8088;154_64377;75_53865;250_37580": "Select Vehicle Model Year",
  "I2067_8189;30_32140": "Save",
  "I2067_8197;21_22137": "Service Management",
  "I2067_8199;21_22137": "Function Setting",
  "I2067_8105;185_68545": "Number Plate",
  "I2067_8073;35_49598;28_31261": ">",
  "I2067_8113;185_68545": "Number Plate",
  "I2067_8073;35_49595;28_31236": "Vehicle Management",
  "I2067_8073;35_49607;28_31261": ">",
  "I2067_8203;21_22137": "Vehicle Management",
  "I2067_8205;250_58566": "Manage Vehicle Setting",
  "I2067_8083;154_64377;75_53865;250_37580": "Select Vehicle Brand",
  "2067_8146": "Device Linkage",
  "I2067_8074;35_49503": "Preview Vehicle",
  "I2067_8186;154_64376": "Towing Service Contract Expiry Date",
  "I2067_8088;154_64376": "Vehicle Model Year",
  "2067_8160": "Vehicle Assignment",
  "I2067_8198;21_22137": "Identity Management",
  "I2067_8073;35_49605;28_31243": "Preview Vehicle",
  "I2067_8188;30_32137": "Back",
  "2067_8079": "General Setting",
  "I2067_8208;23_39072": "Approval",
  "I2067_8204;250_58575": "Manage Vehicle",
  "I2067_8170;154_64376": "Inspection Due Date",
  "I2067_8186;154_64377;75_53865;250_37580": "Enter Contract Expiry Date",
  "2067_8166": "Vehicle Inspection Information",
  "I2067_8073;35_49599;28_31236": "Add Vehicle",
  "I2067_8200;21_22137": "Developer Portal",
  "I2067_8073;35_49597;28_31236": "Manage Vehicle",
  "I2067_8156;7636_13405;185_68545": "Device Bluetooth ID",
  "2067_8140": "Vehicle Images",
  "2067_8107": "mm",
  "I2067_8162;154_64376": "Assigned Organization",
  "I2067_8169;154_64377;75_53865;250_37580": "Enter Inspection Date",
  "2067_8151": "Device ID",
  "I2067_8083;154_64376": "* Vehicle Brand",
  "I2067_8089;7636_13405;185_68545": "VIN",
  "2067_8142": "uploadFile",
  "I2067_8155;30_32140": "Search",
  "I2067_8156;7636_13403": "Device Bluetooth ID",
  "I2067_8087;154_64377;75_53865;250_37580": "Select Vehicle Model",
  "2067_8127": "Advance Setting",
  "I2067_8177;154_64376": "Insurance Service Contract Start Date",
  "I2067_8090;7636_13403": "Number Plate",
  "I2067_8178;154_64377;75_53865;250_37580": "Enter Contract Expiry Date",
  "I2067_8357;23_39072": "Approval",
  "I2067_8328;154_64376": "Insurance Service Contract Start Date",
  "I2067_8313;154_64377;75_53865;250_37580": "Select Assigned Organization",
  "2067_8302": "Device ID",
  "2067_8283": "Odometer",
  "I2067_8224;35_49595;28_31236": "Vehicle Management",
  "I2067_8347;21_22137": "Identity Management",
  "I2067_8366;21_22137": "Company Settings",
  "I2067_8329;154_64377;75_53865;250_37580": "Enter Contract Expiry Date",
  "I2067_8329;154_64376": "Insurance Service Contract Expiry Date",
  "2067_8288": "km",
  "2067_8291": "Vehicle Images",
  "2067_8343": "Admin Portal",
  "I2067_8234;154_64376": "* Vehicle Brand",
  "2067_8333": "Towing Service Information",
  "I2067_8307;7636_13405;185_68545": "Device Bluetooth ID",
  "2067_8274": "Kg",
  "I2067_8234;154_64377;75_53865;250_37580": "Select Vehicle Brand",
  "I2067_8241;7636_13405;185_68545": "Number Plate",
  "I2067_8235;154_64376": "* Vehicle Model",
  "I2067_8238;154_64376": "* Vehicle Type",
  "I2067_8307;7636_13403": "Device Bluetooth ID",
  "2067_8341": "5",
  "I2067_8256;185_68545": "Number Plate",
  "I2067_8350;23_39072": "Device Management",
  "2067_8317": "Vehicle Inspection Information",
  "I2067_8349;21_22137": "Developer Portal",
  "I2067_8305;185_68545": "Name",
  "2067_8325": "Insurance Service Information",
  "I2067_8264;185_68545": "Number Plate",
  "2067_8245": "Length",
  "2067_8269": "Max Weight",
  "I2067_8306;30_32140": "Search",
  "I2067_8241;7636_13403": "Number Plate",
  "I2067_8320;154_64377;75_53865;250_37580": "Enter Inspection Date",
  "I2067_8286;185_68545": "Odometer",
  "I2067_8345;21_22137": "TOP",
  "2067_8297": "Device Linkage",
  "I2067_8367;21_22137": "Activitiy log",
  "I2067_8337;154_64376": "Towing Service Contract Expiry Date",
  "I2067_8348;21_22137": "Function Setting",
  "I2067_8328;154_64377;75_53865;250_37580": "Enter Contract Start Date",
  "I2067_8353;250_58575": "Manage Vehicle",
  "I2067_8272;185_68545": "Number Plate",
  "I2067_8337;154_64377;75_53865;250_37580": "Enter Contract Expiry Date",
  "2067_8278": "Advance Setting",
  "I2067_8355;250_58566": "Reminder",
  "I2067_8239;154_64377;75_53865;250_37580": "Select Vehicle Model Year",
  "I2067_8236;154_64376": "* Vehicle Color",
  "I2067_8224;35_49598;28_31261": ">",
  "I2067_8224;35_49596;28_31261": ">",
  "I2067_8352;21_22137": "Vehicle Management",
  "I2067_8224;35_49605;28_31243": "Preview Vehicle",
  "I2067_8240;7636_13405;185_68545": "VIN",
  "I2067_8224;35_49597;28_31236": "Manage Vehicle",
  "I2067_8224;35_49607;28_31261": ">",
  "I2067_8225;35_49503": "Preview Vehicle",
  "2067_8253": "Width",
  "2067_8311": "Vehicle Assignment",
  "I2067_8320;154_64376": "Inspection Date",
  "I2067_8239;154_64376": "Vehicle Model Year",
  "I2067_8248;185_68545": "Number Plate",
  "2067_8261": "Height",
  "I2067_8338;30_32137": "Cancel",
  "I2067_8354;250_58566": "Manage Vehicle Setting",
  "I2067_8224;35_49599;28_31236": "Add Vehicle",
  "I2067_8336;154_64377;75_53865;250_37580": "Enter Contract Start Date",
  "I2067_8346;21_22137": "Service Management",
  "2067_8258": "mm",
  "2067_8293": "uploadFile",
  "2067_8266": "mm",
  "2067_8250": "mm",
  "I2067_8238;154_64377;75_53865;250_37580": "Select Vehicle Model",
  "I2067_8236;154_64377;75_53865;250_37580": "Select Vehicle Color",
  "I2067_8235;154_64377;75_53865;250_37580": "Select Vehicle Type",
  "2067_8230": "General Setting",
  "I2067_8240;7636_13403": "* VIN",
  "I2067_8362;23_39072": "Notification",
  "I2067_8321;154_64377;75_53865;250_37580": "Enter Inspection Due Date",
  "I2067_8321;154_64376": "Inspection Due Date",
  "I2067_8313;154_64376": "Assigned Organization",
  "I2067_8336;154_64376": "Towing Service Contract Start Date",
  "I2067_8390;7636_13405;185_68545": "OM Staff - Bien Hoang",
  "I2067_8599;7636_13405;75_53807": "Vehicle Indentification Number",
  "I2067_8391;7636_13405;185_68545": "Pending",
  "I2067_8451;18_21709": "Content",
  "I2067_8430;171_66633": "Device Bluetooth ID",
  "2067_8418": "Select Device ID",
  "I2067_8523;7636_13405;75_53807": "Device ID",
  "I2067_8616;154_64377;75_53940;75_53855;1914_88893": "Assigned Passenger",
  "I2067_8469;645_18156": "Are you sure you want to Delete ?",
  "I2067_8601;7636_13405;75_53807": "Device ID",
  "I2067_8613;377_14795": "Assigned Primary Driver",
  "2067_8467": "Action",
  "I2067_8406;30_32069": "Receive returns",
  "I2067_8414;30_32128": "Delete",
  "I2067_8445;18_21709": "Active",
  "I2067_8480;30_32140": "OK",
  "I2067_8616;154_64377;75_53940;75_53854;1914_88893": "Assigned Secondary Driver",
  "I2067_8404;30_32128": "Edit",
  "I2067_8460;368_110840": "Show 1 to 10 of 100 results",
  "2067_8373": "Vehicle Management > Manage Vehicle  > Approval Confirmation",
  "I2067_8427;171_66633": "Device ID",
  "I2067_8443;18_21709": "789456132",
  "I2067_8393;7636_13403": "Request date",
  "I2067_8437;18_21709": "Content",
  "2067_8383": "Approval Confirmation",
  "I2067_8599;7636_13403": "Vehicle Indentification Number",
  "I2067_8423;121_59079": "Search...",
  "I2067_8567;7636_13405;75_53807": "Device ID",
  "I2067_8460;581_1954;7882_22097;433_34538": "4",
  "I2067_8460;581_1954;7882_21971;433_34538": "3",
  "I2067_8431;171_66633": "SIM Status",
  "2067_8400": "Action",
  "I2067_8612;377_14795": "Admin",
  "I2067_8468;819_92231;30_32128": "Delete",
  "I2067_8526;30_32137": "Cancel",
  "I2067_8388;7636_13403": "Approval Flow",
  "I2067_8521;7636_13405;75_53807": "Vehicle Indentification Number",
  "I2067_8460;581_1954;7882_26275;433_34538": "11",
  "I2067_8565;7636_13405;75_53807": "Vehicle Indentification Number",
  "I2067_8390;7636_13403": "Requestor",
  "I2067_8469;645_18160;30_31931": "Delete",
  "I2067_8403;30_32128": "Send",
  "I2067_8460;581_1954;7882_19131;497_44116": "10 / page",
  "I2067_8392;7636_13403": "After status",
  "I2067_8413;30_32128": "Edit",
  "I2067_8608;154_64377;75_53939;250_37573": "Never",
  "I2067_8464;30_32140": "Save",
  "I2067_8468;819_92230;30_32128": "Edit",
  "I2067_8397;30_32140": "Request for Approval",
  "I2067_8429;171_66633": "IMEI",
  "I2067_8460;581_1954;7882_26160;433_34538": "...",
  "I2067_8616;154_64376": "* Receipent",
  "I2067_8428;171_66633": "Device Name",
  "2067_8378": "Manage Vehicle > Action",
  "I2067_8615;1914_95545;75_53826": "Assigned Primary Driver",
  "I2067_8405;30_32128": "Delete",
  "I2067_8435;18_21709": "T399",
  "I2067_8460;581_1954;7882_21853;433_34538": "2",
  "I2067_8617;611_95468;611_95349": "Confirm",
  "I2067_8616;154_64377;75_53940;75_53853;1914_88893": "Assigned Primary Driver",
  "2067_8371":
    "Manage Vehicle > Action (Received / Edit / Delete / Receive Returns / Operation Log)",
  "I2067_8483;75_53854;75_53818": "Sent",
  "I2067_8463;30_32137": "Cancel",
  "I2067_8422;318_97322": "Status",
  "I2067_8393;7636_13405;185_68545": "2023-12-23 14:24:30",
  "2067_8379": "Vehicle Management > Manage Vehicle Settings > Action",
  "I2067_8455;18_21709": "Device ID 4",
  "2067_8606":
    "Work Management  > Reminder > Vehicle Inspection /  Vehicle Insurance / Vehicle Towing Service",
  "I2067_8441;18_21709": "Device ID 2 ",
  "I2067_8617;611_93748": "Congratulations",
  "2067_8375": "Manage Vehicle > Action > Delete",
  "I2067_8523;7636_13403": "Device ID",
  "I2067_8460;581_1954;7882_21473;497_44116": "1",
  "I2067_8459;18_21709": "Active",
  "I2067_8608;154_64377;75_53940;75_53856;75_53818": "Three months ago",
  "I2067_8389;7636_13403": "Approval ID",
  "I2067_8483;75_53852;75_53818": "All",
  "I2067_8605;30_32140": "Export",
  "I2067_8469;645_18155": "Delete",
  "I2067_8565;7636_13403": "Vehicle Indentification Number",
  "I2236_11665;75_53818": "One week ago",
  "2067_8476": "Vehicle ID2 has not been linked to any device. Kindly review it once more.",
  "I2067_8604;30_32137": "Cancel",
  "I2067_8527;30_32140": "Export",
  "I2067_8452;18_21709": "Active",
  "I2067_8483;75_53853;75_53818": "In-Stock",
  "I2067_8567;7636_13403": "Device ID",
  "I2067_8450;18_21709": "365412647",
  "I2067_8436;18_21709": "123456789",
  "I2067_8444;18_21709": "Content",
  "2067_8372": "Manage Vehicle > Status",
  "I2067_8442;18_21709": "T633",
  "I2067_8396;30_32137": "Cancel",
  "I2067_8438;18_21709": "Inactive",
  "2067_8473": "Export QR Code",
  "I2067_8391;7636_13403": "Current status",
  "I2067_8407;30_32069": "Operation Log",
  "I2067_8608;154_64376": "* When To Send",
  "I2067_8458;18_21709": "Content",
  "I2067_8601;7636_13403": "Device ID",
  "2067_8370": "Vehicle Mangement / Modal",
  "2067_8369": "Vehicle Mangement / Select Box",
  "I2067_8616;154_64377;75_53940;75_53852;1914_88893": "Admin",
  "2067_8374": "Vehicle Management > Manage Vehicle > Add > Select Device ID",
  "I2067_8449;18_21709": "T633",
  "I2067_8608;154_64377;75_53940;75_53853;75_53818": "One day ago",
  "I2067_8616;154_64377;75_53939;250_37570": "Select Receipent",
  "I2067_8483;75_53856;75_53818": "Returning",
  "I2067_8617;611_93749": "Your changes have been successfully  saveds",
  "I2067_8448;18_21709": "Device ID 3",
  "I2067_8608;154_64377;75_53940;75_53852;75_53826": "Never",
  "I2067_8483;75_53855;75_53818": "Delivered",
  "I2067_8389;7636_13405;185_68545": "235",
  "I2067_8615;1914_95544;75_53826": "Admin",
  "2067_8376": "Manage Vehicle > Bulk Action > Export QR Code Error",
  "I2067_8479;30_32137": "Close",
  "2067_8410": "Action",
  "I2067_8457;18_21709": "745621478",
  "I2067_8482;318_97295": "Status",
  "2067_8531": "Export QR Code",
  "I2067_8469;645_18159;30_32137": "Close",
  "I2067_8456;18_21709": "T711",
  "I2067_8615;1914_95546;1914_88893": "Assigned Secondary Driver",
  "I2067_8521;7636_13403": "Vehicle Indentification Number",
  "2067_8487": "Export QR Code",
  "I2067_8434;18_21709": "1234ABC56",
  "2067_8377": "Manage Vehicle > Bulk Action > Export 1QR Code",
  "2067_8607":
    "Work Management  > Reminder > Vehicle Inspection /  Vehicle Insurance / Vehicle Towing Service",
  "I2067_8608;154_64377;75_53940;75_53855;75_53818": "Two months ago",
  "I2067_8460;581_1954;7882_26229;433_34538": "10",
  "I2067_8392;7636_13405;185_68545": "Updated",
  "I2067_8615;1914_95547;1914_88893": "Assigned Passenger",
  "I2067_8388;7636_13405;185_68545": "Flow Name (Click to view)",
  "I2067_8608;154_64377;75_53940;75_53854;75_53818": "One month ago",
  "2067_8640": "Admin Portal",
  "I2067_8643;21_22137": "Service Management",
  "I2067_8664;21_22137": "Activitiy log",
  "I2067_8630;154_64376": "Vehicle Type",
  "I2067_8624;35_49503": "Add Vehicle Setting",
  "I2067_8623;35_49552;28_31243": "Add Vehicle Setting",
  "I2067_8652;250_58566": "Reminder",
  "I2067_8627;154_64377;75_53865;250_37564": "Select Vehicle Brand",
  "I2067_8623;35_49550;28_31236": "Manage Vehicle Setting",
  "I2067_8649;21_22137": "Vehicle Management",
  "I2067_8631;154_64377;75_53865;250_37564": "Select Vehicle Model Year",
  "I2067_8651;250_58575": "Manage Vehicle Setting",
  "I2067_8650;250_58566": "Manage Vehicle",
  "I2067_8629;154_64377;75_53865;250_37564": "Select Vehicle Color",
  "I2067_8623;35_49548;28_31236": "Device Management",
  "I2067_8646;21_22137": "Developer Portal",
  "I2067_8629;154_64376": "Vehicle Color",
  "I2067_8628;154_64376": "Vehicle Model",
  "I2067_8633;30_32137": "Cancel",
  "I2067_8647;23_39072": "Device Management",
  "I2067_8659;23_39072": "Notification",
  "I2067_8645;21_22137": "Function Setting",
  "I2067_8663;21_22137": "Company Settings",
  "I2067_8631;154_64376": "Vehicle Model Year",
  "I2067_8654;23_39072": "Approval",
  "I2067_8634;30_32140": "Save",
  "I2067_8642;21_22137": "TOP",
  "I2067_8623;35_49551;28_31261": ">",
  "I2067_8623;35_49549;28_31261": ">",
  "2067_8638": "5",
  "I2067_8630;154_64377;75_53865;250_37564": "Select Vehicle Type",
  "I2067_8627;154_64376": "Vehicle Brand",
  "I2067_8628;154_64377;75_53865;250_37564": "Select Vehicle Model",
  "I2067_8644;21_22137": "Identity Management",
  "I2067_8699;250_58566": "Reminder",
  "I2067_8670;35_49552;28_31243": "Edit Vehicle Setting",
  "I2067_8675;154_64377;75_53865;250_37573": "Select Vehicle Model",
  "I2067_8678;154_64377;75_53865;250_37573": "Select Vehicle Model Year",
  "I2067_8693;21_22137": "Developer Portal",
  "I2067_8689;21_22137": "TOP",
  "I2067_8671;35_49503": "Edit Vehicle Setting",
  "I2067_8675;154_64376": "* Vehicle Model",
  "I2067_8674;154_64376": "* Vehicle Brand",
  "I2067_8698;250_58575": "Manage Vehicle Setting",
  "I2067_8670;35_49550;28_31236": "Manage Vehicle Setting",
  "I2067_8670;35_49551;28_31261": ">",
  "2067_8687": "Admin Portal",
  "I2067_8677;154_64376": "* Vehicle Type",
  "I2067_8696;21_22137": "Vehicle Management",
  "I2067_8691;21_22137": "Identity Management",
  "I2067_8690;21_22137": "Service Management",
  "I2067_8697;250_58566": "Manage Vehicle",
  "I2067_8710;21_22137": "Company Settings",
  "I2067_8711;21_22137": "Activitiy log",
  "2067_8685": "5",
  "I2067_8694;23_39072": "Device Management",
  "I2067_8676;154_64376": "* Vehicle Color",
  "I2067_8677;154_64377;75_53865;250_37573": "Select Vehicle Type",
  "I2067_8681;30_32140": "Update",
  "I2067_8680;30_32137": "Cancel",
  "I2067_8706;23_39072": "Notification",
  "I2067_8678;154_64376": "Vehicle Model Year",
  "I2067_8670;35_49549;28_31261": ">",
  "I2067_8701;23_39072": "Approval",
  "I2067_8692;21_22137": "Function Setting",
  "I2067_8674;154_64377;75_53865;250_37573": "Select Vehicle Brand",
  "I2067_8676;154_64377;75_53865;250_37573": "Select Vehicle Color",
  "I2067_8670;35_49548;28_31236": "Device Management",
  "2087_10894": "Select Vehicle Model Year",
  "2067_8767": "Kg",
  "I2067_8832;30_32137": "Cancel",
  "2067_8776": "Odometer",
  "I2067_8813;154_64376": "Inspection Date",
  "I2067_8779;75_53807": "Name",
  "I2067_8731;154_64376": "* Vehicle Type",
  "I2067_8728;154_64377;75_53865;250_37573": "Select Vehicle Type",
  "2067_8795": "Device ID",
  "2067_8743": "mm",
  "I2067_8727;154_64376": "* Vehicle Brand",
  "2067_8751": "mm",
  "I2067_8800;7636_13403": "Device Bluetooth ID",
  "I2067_8717;35_49598;28_31261": ">",
  "2087_10892": "Select Vehicle Model",
  "2067_8754": "Height",
  "I2067_8798;121_58638": "Device ID",
  "I2067_8822;154_64376": "Insurance Service Contract Expiry Date",
  "I2067_8806;154_64377;75_53865;250_37573": "Select Assigned Organization",
  "2067_8771": "Advance Setting",
  "I2067_8718;35_49503": "Edit Vehicle",
  "I2067_8765;121_58638": "100",
  "I2067_8757;121_58638": "100",
  "I2067_8829;154_64376": "Towing Service Contract Start Date",
  "I2067_8829;154_64377;75_53865;250_37573": "Enter Contract Start Date",
  "I2067_8833;30_32140": "Next",
  "I2067_8852;23_39072": "Approval",
  "I2067_8800;7636_13405;185_68545": "Device Bluetooth ID",
  "I2067_8717;35_49599;28_31236": "Edit Vehicle",
  "2067_8784": "Vehicle Images",
  "I2067_8821;154_64376": "Insurance Service Contract Start Date",
  "I2067_8731;154_64377;75_53865;250_37573": "Select Vehicle Model",
  "I2067_8717;35_49605;28_31243": "Edit Vehicle",
  "I2067_8830;154_64377;75_53865;250_37573": "Enter Contract Expiry Date",
  "I2067_8842;21_22137": "Identity Management",
  "2067_8786": "uploadFile",
  "I2067_8717;35_49597;28_31236": "Manage Vehicle",
  "2067_8738": "Length",
  "I2067_8857;23_39072": "Notification",
  "I2067_8830;154_64376": "Towing Service Contract Expiry Date",
  "I2067_8717;35_49596;28_31261": ">",
  "I2067_8849;250_58566": "Manage Vehicle Setting",
  "I2067_8814;154_64376": "Inspection Due Date",
  "2087_10896": "Enter VIN",
  "I2067_8732;154_64377;75_53865;250_37573": "Select Vehicle Model Year",
  "2067_8804": "Vehicle Assignment",
  "I2067_8749;121_58638": "100",
  "I2067_8733;7636_13403": "* VIN",
  "I2067_8734;7636_13405;121_58638": "Name",
  "2067_8746": "Width",
  "2067_8723": "General Setting",
  "2087_10898": "Select Vehicle Color",
  "I2067_8717;35_49607;28_31261": ">",
  "I2067_8840;21_22137": "TOP",
  "I2067_8844;21_22137": "Developer Portal",
  "I2067_8843;21_22137": "Function Setting",
  "I2067_8845;23_39072": "Device Management",
  "I2067_8734;7636_13403": "Number Plate",
  "I2067_8814;154_64377;75_53865;250_37573": "Enter Inspection Due Date",
  "I2067_8717;35_49595;28_31236": "Vehicle Management",
  "2067_8762": "Max Weight",
  "2067_8836": "5",
  "I2067_8850;250_58566": "Reminder",
  "2087_10889": "Select Vehicle Type",
  "2067_8838": "Admin Portal",
  "I2067_8848;250_58575": "Manage Vehicle",
  "I2067_8727;154_64377;75_53865;250_37573": "Select Vehicle Brand",
  "I2067_8821;154_64377;75_53865;250_37573": "Enter Contract Start Date",
  "I2067_8822;154_64377;75_53865;250_37573": "Enter Contract Expiry Date",
  "2067_8818": "Insurance Service Information",
  "I2067_8813;154_64377;75_53865;250_37573": "Enter Inspection Date",
  "2067_8759": "mm",
  "I2067_8841;21_22137": "Service Management",
  "2067_8781": "km",
  "I2067_8862;21_22137": "Activitiy log",
  "I2067_8861;21_22137": "Company Settings",
  "I2067_8733;7636_13405;121_58638": "Name",
  "2067_8810": "Vehicle Inspection Information",
  "I2067_8732;154_64376": "Vehicle Model Year",
  "I2067_8728;154_64376": "* Vehicle Model",
  "I2067_8847;21_22137": "Vehicle Management",
  "I2067_8729;154_64376": "* Vehicle Color",
  "I2067_8729;154_64377;75_53865;250_37573": "Select Vehicle Color",
  "2067_8826": "Towing Service Information",
  "I2067_8741;121_58638": "100",
  "I2067_8799;30_32140": "Search",
  "2067_8790": "Device Linkage",
  "I2067_8806;154_64376": "Assigned Organization",
  "2087_10886": "Select Vehicle Brand",
  "I2067_8868;35_49597;28_31236": "Manage Vehicle",
  "I2067_8868;35_49596;28_31261": ">",
  "I2067_8882;154_64377;75_53865;250_37580": "Select Vehicle Model",
  "I2067_8885;7636_13405;121_58638": "Name",
  "I2067_8908;185_68545": "Number Plate",
  "I2067_8973;154_64377;75_53865;250_37580": "Enter Contract Expiry Date",
  "I2067_8880;154_64376": "* Vehicle Color",
  "I2067_9013;21_22137": "Activitiy log",
  "I2067_8972;154_64377;75_53865;250_37580": "Enter Contract Start Date",
  "I2067_8984;30_32140": "Update",
  "2067_8894": "mm",
  "I2067_8900;185_68545": "Number Plate",
  "I2067_8880;154_64377;75_53865;250_37580": "Select Vehicle Color",
  "2067_8987": "5",
  "I2067_8878;154_64376": "* Vehicle Brand",
  "2067_8937": "uploadFile",
  "I2067_8994;21_22137": "Function Setting",
  "I2067_8965;154_64376": "Inspection Due Date",
  "I2067_8992;21_22137": "Service Management",
  "I2067_8868;35_49607;28_31261": ">",
  "I2067_8868;35_49605;28_31243": "Preview Vehicle",
  "I2067_8878;154_64377;75_53865;250_37580": "Select Vehicle Brand",
  "2067_8874": "General Setting",
  "I2067_8981;154_64376": "Towing Service Contract Expiry Date",
  "2067_8935": "Vehicle Images",
  "I2067_8995;21_22137": "Developer Portal",
  "I2067_8868;35_49595;28_31236": "Vehicle Management",
  "I2067_8965;154_64377;75_53865;250_37580": "Enter Inspection Due Date",
  "I2067_8980;154_64377;75_53865;250_37580": "Enter Contract Start Date",
  "I2067_8972;154_64376": "Insurance Service Contract Start Date",
  "I2067_8883;154_64377;75_53865;250_37580": "Select Vehicle Model Year",
  "I2067_8964;154_64377;75_53865;250_37580": "Enter Inspection Date",
  "I2067_8879;154_64377;75_53865;250_37580": "Select Vehicle Type",
  "2067_8955": "Vehicle Assignment",
  "I2067_8996;23_39072": "Device Management",
  "2067_8989": "Admin Portal",
  "2067_8946": "Device ID",
  "I2067_8980;154_64376": "Towing Service Contract Start Date",
  "I2067_8951;7636_13403": "Device Bluetooth ID",
  "I2067_8991;21_22137": "TOP",
  "I2067_8983;30_32137": "Back",
  "I2067_8951;7636_13405;185_68545": "Device Bluetooth ID",
  "2067_8977": "Towing Service Information",
  "I2067_9008;23_39072": "Notification",
  "I2067_9000;250_58566": "Manage Vehicle Setting",
  "I2067_8884;7636_13403": "* VIN",
  "I2067_8957;154_64377;75_53865;250_37580": "Select Assigned Organization",
  "I2067_8993;21_22137": "Identity Management",
  "I2067_8957;154_64376": "Assigned Organization",
  "2067_8922": "Advance Setting",
  "I2067_8868;35_49598;28_31261": ">",
  "I2067_8949;185_68545": "Name",
  "I2067_9003;23_39072": "Approval",
  "I2067_8973;154_64376": "Insurance Service Contract Expiry Date",
  "I2067_8950;30_32140": "Search",
  "I2067_8916;185_68545": "Number Plate",
  "2067_8902": "mm",
  "2067_8918": "Kg",
  "I2067_8998;21_22137": "Vehicle Management",
  "2067_8969": "Insurance Service Information",
  "I2067_9001;250_58566": "Reminder",
  "I2067_8869;35_49503": "Preview Vehicle",
  "I2067_8883;154_64376": "Vehicle Model Year",
  "2067_8910": "mm",
  "I2067_8981;154_64377;75_53865;250_37580": "Enter Contract Expiry Date",
  "I2067_8868;35_49599;28_31236": "Edit Vehicle",
  "2067_8897": "Width",
  "I2067_9012;21_22137": "Company Settings",
  "I2067_8964;154_64376": "Inspection Date",
  "I2067_8999;250_58575": "Manage Vehicle",
  "I2067_8930;121_58638": "Odometer",
  "I2067_8892;185_68545": "Number Plate",
  "2067_8932": "km",
  "2067_8913": "Max Weight",
  "I2067_8882;154_64376": "* Vehicle Type",
  "I2067_8879;154_64376": "* Vehicle Model",
  "2067_8961": "Vehicle Inspection Information",
  "2067_8941": "Device Linkage",
  "I2067_8884;7636_13405;185_68545": "VIN",
  "2067_8927": "Odometer",
  "2067_8905": "Height",
  "2067_8889": "Length",
  "I2067_8885;7636_13403": "Number Plate",
  "I2067_9029;21_22137": "Identity Management",
  "I2067_9056;171_66633": "Type",
  "I2067_9082;368_110840": "Show 1 to 10 of 100 results",
  "I2067_9059;18_21709": "Organization ID 1",
  "I2067_9080;18_21709": "Service User",
  "2067_9025": "Admin Portal",
  "I2067_9082;581_1954;7882_21971;433_34538": "3",
  "I2067_9055;171_66633": "Organization Name",
  "I2067_9050;121_59079": "Search...",
  "2067_9023": "5",
  "I2067_9030;21_22137": "Function Setting",
  "I2067_9034;21_22137": "Vehicle Management",
  "I2067_9082;581_1954;7882_21473;497_44116": "1",
  "I2067_9064;18_21709": "Service B2B",
  "I2067_9082;581_1954;7882_26229;433_34538": "10",
  "I2067_9067;18_21709": "Organization ID 2",
  "I2067_9082;581_1954;7882_22097;433_34538": "4",
  "I2067_9019;35_49503": "Company Settings",
  "I2067_9031;21_22137": "Developer Portal",
  "I2067_9027;21_22137": "TOP",
  "I2067_9028;21_22137": "Service Management",
  "I2067_9072;18_21709": "Service B2C",
  "I2067_9041;23_39072": "Notification",
  "I2067_9065;528_68898;30_32021": "Localization",
  "I2067_9049;433_34538": "Type",
  "I2067_9046;21_22137": "Activitiy log",
  "2067_9071": "User Name",
  "I2067_9033;23_39072": "Device Management",
  "I2067_9054;171_66633": "ID",
  "I2067_9082;581_1954;7882_26275;433_34538": "11",
  "I2067_9081;528_68898;30_32021": "Localization",
  "I2067_9082;581_1954;7882_19131;497_44116": "10 / page",
  "2067_9063": "User Name",
  "I2067_9082;581_1954;7882_21853;433_34538": "2",
  "I2067_9036;23_39072": "Approval",
  "I2067_9057;171_66633": "Action",
  "I2067_9082;581_1954;7882_26160;433_34538": "...",
  "I2067_9073;528_68898;30_32021": "Localization",
  "2067_9079": "User Name",
  "I2067_9045;21_27541": "Company Settings",
  "I2067_9075;18_21709": "Organization ID 3",
  "I2067_9116;21_22137": "Developer Portal",
  "I2067_9126;23_39072": "Notification",
  "I2067_9119;21_22137": "Vehicle Management",
  "I2067_9094;154_64376": "End Repeat",
  "I2067_9095;154_64377;75_53865;250_37573": "English",
  "I2067_9093;154_64377;75_53865;250_37573": "(GMT-11:00) Niue Time",
  "I2067_9115;21_22137": "Function Setting",
  "I2067_9100;154_64376": "Distance Unit",
  "I2067_9118;23_39072": "Device Management",
  "I2067_9093;154_64376": "Time Zone",
  "2067_9108": "5",
  "I2067_9094;154_64377;75_53865;250_37573": "Japan",
  "I2067_9098;154_64376": "Temperature",
  "I2067_9101;154_64377;75_53865;250_37573": "Metric (m, mm, kg)",
  "I2067_9095;154_64376": "Language",
  "I2067_9113;21_22137": "Service Management",
  "I2067_9104;154_64376": "Number Format",
  "2067_9110": "Admin Portal",
  "I2067_9103;154_64377;75_53865;250_37573": "DD/MM/YYYY",
  "I2354_11303;30_32140": "Save",
  "I2067_9088;28_31248;28_31243": "Localization",
  "I2067_9114;21_22137": "Identity Management",
  "I2067_9103;154_64376": "Date Format",
  "I2067_9104;154_64377;75_53865;250_37573": "1.234.567,89",
  "I2067_9098;154_64377;75_53865;250_37573": "°C",
  "I2067_9088;28_31263;28_31261": ">",
  "I2067_9112;21_22137": "TOP",
  "I2067_9130;21_27541": "Company Settings",
  "I2067_9088;28_31246;28_31236": "Company Settings",
  "I2067_9089;35_49503": "Localization",
  "I2067_9100;154_64377;75_53865;250_37573": "km",
  "I2354_11302;30_32137": "Cancel",
  "I2067_9101;154_64376": "Measurement System",
  "I2067_9097;154_64377;75_53865;250_37573": "USD - U.S.Dollar",
  "I2067_9121;23_39072": "Approval",
  "I2067_9131;21_22137": "Activitiy log",
  "I2067_9097;154_64376": "Currency",
  "I2067_9141;75_53939;250_37570": "Metric (m, mm, kg)",
  "I2067_9157;250_37570": "1.234.567,89",
  "I2067_9140;75_53939;250_37570": "km",
  "2067_9138": "Localization > Number Format",
  "I2067_9143;250_37570": "DD/MM/YYYY",
  "I2067_9147;75_53818": "DD/M/YYYY",
  "I2067_9151;75_53818": "YYYY/M/DD",
  "I2067_9150;75_53818": "DD-MM-YYYY",
  "I2067_9141;75_53940;75_53853;75_53818": "Metric (m, mm, kg)",
  "I2067_9139;75_53939;250_37570": "°C",
  "I2067_9146;75_53818": "DD/MM/YYYY",
  "I2067_9139;75_53940;75_53853;75_53818": "Celsius (°C)",
  "I2067_9163;75_53818": "1 234 567,89",
  "I2067_9140;75_53940;75_53853;75_53818": "km",
  "I2067_9160;75_53818": "1.234.567,89",
  "2067_9136": "Localization > Measurement System",
  "I2067_9139;75_53940;75_53854;75_53818": "Fahrenheit (°F)",
  "I2067_9148;75_53818": "M/DD/YY",
  "2067_9134": "Localization > Temperature",
  "I2067_9141;75_53940;75_53854;75_53818": "Imperial (ft, in, lb)",
  "I2067_9149;75_53818": "DD.MM.YYYY",
  "I2067_9153;75_53818": "YYYY-MM-DD",
  "2067_9133": "Company Settings / Select Box",
  "I2067_9162;75_53818": "1 234 567.89",
  "I2067_9140;75_53940;75_53854;75_53818": "miles",
  "I2067_9161;75_53818": "1,234,567.89",
  "2067_9135": "Localization > Distance Unit",
  "2067_9137": "Localization > Date Format",
  "I2067_9152;75_53818": "YYYY.MM.DD",
  "I2067_9180;30_32140": "Save",
  "I2067_9197;250_58566": "Manage Vehicle",
  "2067_9187": "Admin Portal",
  "I2067_9199;250_58575": "Reminder",
  "I2067_9206;23_39072": "Notification",
  "I2067_9170;28_31263;28_31261": ">",
  "I2067_9211;21_22137": "Activitiy log",
  "I2067_9194;23_39072": "Device Management",
  "I2067_9170;28_31246;28_31236": "Vehicle Management",
  "I2067_9178;154_64377;75_53865;250_37573": "Never",
  "I2067_9191;21_22137": "Identity Management",
  "I2067_9176;7636_13403": "Reminder Title",
  "I2067_9201;23_39072": "Approval",
  "I2067_9210;21_22137": "Company Settings",
  "I2067_9189;21_22137": "TOP",
  "I2067_9173;758_37471;758_37454": "Vehicle Towing Service",
  "I2067_9193;21_22137": "Developer Portal",
  "I2067_9190;21_22137": "Service Management",
  "I2067_9176;7636_13405;75_53807": "Reminder Title",
  "2067_9185": "5",
  "I2067_9177;7700_22671": "Message",
  "I2067_9198;250_58566": "Manage Vehicle Setting",
  "I2067_9170;28_31248;28_31243": "Reminder",
  "I2067_9173;758_37469;758_37460": "Vehicle Inspection",
  "I2067_9177;7700_22673;154_61744": "Message",
  "I2067_9178;154_64376": "When To Send",
  "I2067_9196;21_22137": "Vehicle Management",
  "I2067_9179;154_64377;75_53865;250_37564": "Select Receipent",
  "I2067_9173;758_37470;758_37454": "Vehicle Insurance",
  "I2067_9179;154_64376": "Receipent",
  "I2067_9192;21_22137": "Function Setting",
  "I2067_9171;35_49503": "Reminder",
  "I2067_9238;21_22137": "Function Setting",
  "2067_9231": "5",
  "I2067_9216;28_31246;28_31236": "Vehicle Management",
  "I2067_9216;28_31248;28_31243": "Reminder",
  "I2067_9224;154_64377;75_53865;250_37573": "Never",
  "I2067_9244;250_58566": "Manage Vehicle Setting",
  "I2067_9252;23_39072": "Notification",
  "I2067_9226;30_32140": "Save",
  "I2067_9219;758_37670;758_37454": "Vehicle Inspection",
  "2067_9233": "Admin Portal",
  "I2067_9235;21_22137": "TOP",
  "I2067_9223;7700_22673;154_61744": "Message",
  "I2067_9223;7700_22671": "* Message",
  "I2067_9222;7636_13403": "* Reminder Title",
  "I2067_9256;21_22137": "Company Settings",
  "I2067_9219;758_37672;758_37454": "Vehicle Towing Service",
  "I2067_9243;250_58566": "Manage Vehicle",
  "I2067_9242;21_22137": "Vehicle Management",
  "I2067_9237;21_22137": "Identity Management",
  "I2067_9225;154_64376": "* Receipent",
  "I2067_9222;7636_13405;75_53807": "Reminder Title",
  "I2067_9236;21_22137": "Service Management",
  "I2067_9247;23_39072": "Approval",
  "I2067_9245;250_58575": "Reminder",
  "I2067_9240;23_39072": "Device Management",
  "I2067_9224;154_64376": "* When To Send",
  "I2067_9219;758_37671;758_37460": "Vehicle Insurance",
  "I2067_9239;21_22137": "Developer Portal",
  "I2067_9257;21_22137": "Activitiy log",
  "I2067_9216;28_31263;28_31261": ">",
  "I2067_9225;154_64377;75_53865;250_37564": "Select Receipent",
  "I2067_9217;35_49503": "Reminder",
  "I2067_9288;21_22137": "Vehicle Management",
  "I2067_9289;250_58566": "Manage Vehicle",
  "I2067_9290;250_58566": "Manage Vehicle Setting",
  "I2067_9270;154_64377;75_53865;250_37573": "Never",
  "I2067_9281;21_22137": "TOP",
  "I2067_9262;28_31246;28_31236": "Vehicle Management",
  "I2067_9293;23_39072": "Approval",
  "I2067_9284;21_22137": "Function Setting",
  "I2067_9270;154_64376": "* When To Send",
  "2067_9279": "Admin Portal",
  "I2067_9298;23_39072": "Notification",
  "I2067_9269;7700_22671": "* Message",
  "I2067_9302;21_22137": "Company Settings",
  "I2067_9263;35_49503": "Reminder",
  "I2067_9262;28_31248;28_31243": "Reminder",
  "I2067_9268;7636_13405;75_53807": "Reminder Title",
  "I2067_9291;250_58575": "Reminder",
  "I2067_9262;28_31263;28_31261": ">",
  "I2067_9269;7700_22673;154_61744": "Message",
  "I2067_9265;996_50816;758_37454": "Vehicle Insurance",
  "I2067_9272;30_32140": "Save",
  "I2067_9271;154_64376": "* Receipent",
  "I2067_9303;21_22137": "Activitiy log",
  "I2067_9268;7636_13403": "* Reminder Title",
  "I2067_9265;996_50815;758_37460": "Vehicle Towing Service",
  "I2067_9283;21_22137": "Identity Management",
  "I2067_9286;23_39072": "Device Management",
  "I2067_9282;21_22137": "Service Management",
  "2067_9277": "5",
  "I2067_9265;996_50814;758_37454": "Vehicle Inspection",
  "I2067_9285;21_22137": "Developer Portal",
  "I2067_9271;154_64377;75_53865;250_37564": "Select Receipent",
  "I2067_9322;18_21709": "ID 1",
  "I2067_9384;21_22137": "Activitiy log",
  "I2067_9324;18_21709": "SORACOM",
  "2067_9327": "Action",
  "I2067_9347;835_98906": "Telecom Company Name 4",
  "I2067_9353;581_1954;7882_21971;433_34538": "3",
  "2067_9357": "5",
  "I2067_9364;21_22137": "Function Setting",
  "I2067_9318;171_66633": "Telecom Company Name",
  "I2067_9323;835_98906": "Telecom Company Name 1",
  "I2067_9331;835_98906": "Telecom Company Name 2",
  "I2067_9310;388_23101": "Telecom Company",
  "I2067_9353;581_1954;7882_26229;433_34538": "10",
  "2067_9351": "Action",
  "I2067_9361;21_22137": "TOP",
  "I2067_9332;18_21709": "SORACOM",
  "I2067_9374;23_39072": "Approval",
  "I2067_9372;21_22137": "Vehicle Management",
  "I2067_9310;388_23148;30_32140": "Add",
  "I2067_9340;18_21709": "SORACOM",
  "I2067_9330;18_21709": "ID 2",
  "I2067_9353;581_1954;7882_21853;433_34538": "2",
  "I2067_9339;835_98906": "Telecom Company Name 3",
  "I2067_9320;171_66633": "Action",
  "2067_9343": "Action",
  "I2067_9317;171_66633": "ID",
  "I2067_9353;581_1954;7882_26160;433_34538": "...",
  "I2067_9362;21_22137": "Service Management",
  "I2067_9353;368_110840": "Show 1 to 10 of 100 results",
  "I2067_9309;28_31248;28_31243": "Telecom Company",
  "I2067_9368;250_58566": "Send Device",
  "I2067_9353;581_1954;7882_19131;497_44116": "10 / page",
  "I2067_9348;18_21709": "SORACOM",
  "I2067_9371;250_58566": "Manage SIM",
  "I2067_9338;18_21709": "ID 3",
  "I2067_9367;23_39072": "Device Management",
  "I2067_9309;28_31263;28_31261": ">",
  "2067_9359": "Admin Portal",
  "I2067_9353;581_1954;7882_22097;433_34538": "4",
  "I2067_9346;18_21709": "ID 4",
  "I2067_9353;581_1954;7882_21473;497_44116": "1",
  "I2067_9370;250_58575": "Telecom Company",
  "I2067_9379;23_39072": "Notification",
  "I2067_9319;171_66633": "Description",
  "I2067_9363;21_22137": "Identity Management",
  "2067_9335": "Action",
  "I2067_9353;581_1954;7882_26275;433_34538": "11",
  "I2067_9309;28_31246;28_31236": "Device Management",
  "I2067_9369;250_58566": "Receive Device",
  "I2067_9313;121_59079": "Search...",
  "I2067_9365;21_22137": "Developer Portal",
  "I2067_9383;21_22137": "Company Settings",
  "I2067_9392;35_49503": "Add Telecom Company",
  "I2067_9412;21_22137": "Developer Portal",
  "I2067_9430;21_22137": "Company Settings",
  "I2067_9399;30_32137": "Cancel",
  "I2067_9411;21_22137": "Function Setting",
  "I2067_9415;250_58566": "Send Device",
  "2067_9404": "5",
  "I2067_9426;23_39072": "Notification",
  "2084_10869": "Enter your description",
  "I2067_9410;21_22137": "Identity Management",
  "I2067_9397;7700_22671": "Description",
  "I2067_9397;7700_22673;154_61744": "Description",
  "I2067_9391;35_49550;28_31236": "Telecom Company",
  "2084_10866": "Enter your telecom company name",
  "I2067_9396;7636_13403": "Telecom Company Name",
  "I2067_9391;35_49552;28_31243": "Add Telecom Company",
  "I2067_9391;35_49551;28_31261": ">",
  "I2067_9400;30_32140": "Save",
  "I2067_9416;250_58566": "Receive Device",
  "I2067_9391;35_49548;28_31236": "Device Management",
  "I2067_9419;21_22137": "Vehicle Management",
  "I2067_9409;21_22137": "Service Management",
  "I2067_9418;250_58566": "Manage SIM",
  "I2067_9408;21_22137": "TOP",
  "I2067_9391;35_49549;28_31261": ">",
  "I2067_9396;7636_13405;75_53807": "Telecom Company Name",
  "2084_10867": "No Special Characters Allowed 0-9 a-z space",
  "I2067_9417;250_58575": "Telecom Company",
  "I2067_9421;23_39072": "Approval",
  "I2067_9414;23_39072": "Device Management",
  "I2067_9431;21_22137": "Activitiy log",
  "2067_9406": "Admin Portal",
  "I2067_9438;35_49549;28_31261": ">",
  "I2067_9477;21_22137": "Activitiy log",
  "2067_9452": "Admin Portal",
  "I2067_9458;21_22137": "Developer Portal",
  "I2067_9438;35_49551;28_31261": ">",
  "I2067_9439;35_49503": "View Telecom Company",
  "I2067_9461;250_58566": "Send Device",
  "I2067_9443;7636_13403": "Telecom Company Name",
  "I2067_9476;21_22137": "Company Settings",
  "I2067_9472;23_39072": "Notification",
  "2067_9450": "5",
  "I2067_9454;21_22137": "TOP",
  "I2067_9444;7700_22671": "Description",
  "I2067_9438;35_49552;28_31243": "View Telecom Company",
  "I2067_9465;21_22137": "Vehicle Management",
  "I2067_9438;35_49548;28_31236": "Device Management",
  "I2067_9457;21_22137": "Function Setting",
  "I2067_9456;21_22137": "Identity Management",
  "I2067_9444;7700_22673;185_68601": "Description",
  "I2067_9463;250_58575": "Telecom Company",
  "I2067_9455;21_22137": "Service Management",
  "I2067_9460;23_39072": "Device Management",
  "I2067_9464;250_58566": "Manage SIM",
  "I2067_9467;23_39072": "Approval",
  "I2067_9446;30_32137": "Cancel",
  "I2067_9443;7636_13405;185_68545": "Telecom Company Name",
  "I2067_9462;250_58566": "Receive Device",
  "I2067_9438;35_49550;28_31236": "Telecom Company",
  "I2067_9505;21_22137": "Developer Portal",
  "I2067_9514;23_39072": "Approval",
  "I2067_9483;35_49551;28_31261": ">",
  "I2067_9507;23_39072": "Device Management",
  "I2067_9524;21_22137": "Activitiy log",
  "I2067_9493;30_32140": "Update",
  "I2067_9489;7636_13405;121_58638": "Telecom Company Name",
  "I2067_9509;250_58566": "Receive Device",
  "2084_10872": "Enter your telecom company name",
  "I2067_9490;7700_22673;250_58177": "Description",
  "I2067_9519;23_39072": "Notification",
  "I2067_9501;21_22137": "TOP",
  "I2067_9483;35_49549;28_31261": ">",
  "I2067_9502;21_22137": "Service Management",
  "I2067_9508;250_58566": "Send Device",
  "I2067_9489;7636_13403": "* Telecom Company Name",
  "I2067_9510;250_58575": "Telecom Company",
  "2067_9499": "Admin Portal",
  "I2067_9511;250_58566": "Manage SIM",
  "I2067_9483;35_49550;28_31236": "Telecom Company",
  "2084_10873": "No Special Characters Allowed 0-9 a-z space",
  "I2067_9490;7700_22671": "* Description",
  "I2067_9512;21_22137": "Vehicle Management",
  "I2067_9492;30_32137": "Cancel",
  "I2067_9523;21_22137": "Company Settings",
  "I2067_9503;21_22137": "Identity Management",
  "2084_10875": "Enter your description",
  "I2067_9483;35_49548;28_31236": "Device Management",
  "I2067_9483;35_49552;28_31243": "Edit Telecom Company",
  "I2067_9504;21_22137": "Function Setting",
  "2067_9497": "5",
  "I2067_9484;35_49503": "Edit Telecom Company",
  "I2084_10791;154_64377;75_53940;75_53853;1914_88893": "Deactive",
  "I2084_10791;154_64377;75_53939;250_37570": "Select Action",
  "I2084_10791;154_64376": "* Select Action",
  "I2084_10791;154_64377;75_53940;75_53852;1914_88893": "Active",
  "I2159_11398;75_53853;1914_88893": "Delete",
  "I2159_11398;75_53852;1914_88893": "Edit",
  "2092_10852": "Organization Code",
  "2092_10848": "Organization",
  "2092_10858": "Created Date",
  "2092_10965": "of",
  "I2092_10837;1_6306;1_6259;1_6219": "Approval",
  "2092_10956": "page",
  "I2092_10837;1_6306;1_6246;1_6154": "TOP",
  "2092_10961": "1",
  "I2092_10837;1_6306;1_6247;1_6154": "Service Management",
  "2092_10964": "to",
  "2092_10962": "20",
  "I2092_10837;1_6306;1_6254;1_6154": "Function Setting",
  "2092_10966": "results",
  "2092_10917": "netko-solution",
  "2092_10920": "10:30 25-01-2024",
  "2092_10894": "OM Japan",
  "2092_10949": "1",
  "I2092_10837;1_6306;1_6253;1_6154": "Identity Management",
  "2092_10937": "om-admin",
  "2092_10914": "NETKO Solution",
  "2092_10841": "Activity Logs",
  "2092_10844": "Functions Setting",
  "2092_10877": "netko-vietnam",
  "I2092_10837;1_6306;1_6255;1_6154": "Developer Portal",
  "2092_10874": "Netko Vietnam",
  "2092_10856": "Icon",
  "2092_10897": "om-japan",
  "2092_10934": "Organic Mobility",
  "2092_10862": "Action",
  "2092_10900": "10:35 25-01-2024",
  "2092_10955": "20  /",
  "I2092_10837;1_6306;1_6256;1_6154": "Device Management",
  "2092_10880": "11:34 25-01-2024",
  "I2092_10837;1_6306;1_6257;1_6154": "Vehicle Management",
  "I2092_10837;1_6306;1_6244": "Admin Portal",
  "2092_10963": "40",
  "2092_10960": "Show",
  "I2092_10837;1_6306;1_6242": "5",
  "2092_10854": "Name",
  "I2092_10837;1_6306;1_6265;1_6214": "Notification",
  "2092_10940": "15:59 25-01-2024",
  "2092_11260": "Active",
  "2092_11238": "Service",
  "2092_11244": "Protocol",
  "2092_11247": "Auth Method",
  "2092_11264": "https://om-mazda.vinasports.net",
  "I2092_11231;1_6306;1_6242": "5",
  "I2092_11231;1_6306;1_6246;1_6154": "TOP",
  "I2092_11231;1_6306;1_6244": "Admin Portal",
  "I2092_11231;1_6306;1_6254;1_6154": "Function Setting",
  "2092_11270": "Enter your description",
  "I2092_11231;1_6306;1_6255;1_6154": "Developer Portal",
  "2092_11256": "HTTPS",
  "I2092_11231;1_6306;1_6259;1_6219": "Approval",
  "2092_11235": "Activity Logs",
  "2092_11271": "Credential Key",
  "2092_11273": "Add to",
  "2092_11252": "Update",
  "2092_11254": "Sevice 1",
  "I2092_11231;1_6306;1_6257;1_6154": "Vehicle Management",
  "2092_11250": "Cancel",
  "2092_11275": "Key",
  "2092_11248": "Description",
  "2092_11240": "Preview",
  "I2092_11231;1_6306;1_6256;1_6154": "Device Management",
  "I2092_11231;1_6306;1_6247;1_6154": "Service Management",
  "I2092_11231;1_6306;1_6253;1_6154": "Identity Management",
  "2092_11245": "Status",
  "2092_11284": "/",
  "2092_11243": "Service Name",
  "2092_11239": "Update ",
  "2092_11272": "Credential Value",
  "2092_11246": "API URI",
  "2092_11277": "Value",
  "2092_11279": "Header",
  "2092_11283": "Service Management",
  "2092_11266": "None",
  "I2092_11231;1_6306;1_6265;1_6214": "Notification",
  "2092_11285": "Update Service",
  "I2129_10803;1_6306;1_6261;1_6178": "List Approval",
  "2129_11049": "Back",
  "2129_10817": "Approval Setting",
  "2129_10868": "Approval",
  "2129_11011": "Service Name",
  "I2129_10803;1_6306;1_6259;1_6219": "Approval",
  "I2129_10803;1_6306;1_6242": "5",
  "2129_11007": "Name",
  "I2129_10803;1_6306;1_6255;1_6154": "Developer Portal",
  "2129_10809": "Activity Logs",
  "I2129_10803;1_6306;1_6244": "Admin Portal",
  "2129_11009": "Update Admin User",
  "2129_10870": "Approval Setting",
  "2129_11051": "Create",
  "2129_11033": "Approvers",
  "I2129_10803;1_6306;1_6246;1_6154": "TOP",
  "I2129_10803;1_6306;1_6254;1_6154": "Function Setting",
  "I2129_10803;1_6306;1_6256;1_6154": "Device Management",
  "I2129_10803;1_6306;1_6247;1_6154": "Service Management",
  "2129_11019": "User",
  "2129_11210": "Preview Create Approval Setting",
  "I2129_10803;1_6306;1_6257;1_6154": "Vehicle Management",
  "2129_11013": "IDM",
  "I2129_10803;1_6306;1_6265;1_6214": "Notification",
  "I2129_10803;1_6306;1_6262;1_6169": "Approval Setting",
  "2129_11023": "Method",
  "2129_11047": "Add field",
  "2129_10869": "/",
  "2129_10813": "Create",
  "2129_11036": "Admin@organicklink.cloud",
  "2129_11025": "Update",
  "2129_11017": "Object",
  "I2129_10803;1_6306;1_6253;1_6154": "Identity Management",
  "2129_11031": "1",
  "2129_11029": "Steps",
  "2129_11082": "IDM",
  "2129_11071": "/",
  "I2129_11054;1_6306;1_6246;1_6154": "TOP",
  "2129_11060": "Activity Logs",
  "2129_11118": "Back",
  "2129_11086": "Object",
  "2129_11068": "Preview Update Approval Setting",
  "I2129_11054;1_6306;1_6255;1_6154": "Developer Portal",
  "I2129_11054;1_6306;1_6242": "5",
  "2129_11072": "Approval Setting",
  "I2129_11054;1_6306;1_6262;1_6169": "Approval Setting",
  "2129_11064": "Update",
  "I2129_11054;1_6306;1_6253;1_6154": "Identity Management",
  "2129_11076": "Name",
  "2129_11092": "Method",
  "I2129_11054;1_6306;1_6259;1_6219": "Approval",
  "2129_11116": "Add field",
  "I2129_11054;1_6306;1_6244": "Admin Portal",
  "2129_11070": "Approval",
  "2129_11120": "Update",
  "2129_11100": "1",
  "2129_11209": "Preview",
  "2129_11080": "Service Name",
  "I2129_11054;1_6306;1_6256;1_6154": "Device Management",
  "I2129_11054;1_6306;1_6265;1_6214": "Notification",
  "2129_11105": "Admin@organicklink.cloud",
  "I2129_11054;1_6306;1_6257;1_6154": "Vehicle Management",
  "2129_11088": "User",
  "2129_11098": "Steps",
  "I2129_11054;1_6306;1_6254;1_6154": "Function Setting",
  "I2129_11054;1_6306;1_6247;1_6154": "Service Management",
  "2129_11102": "Approvers",
  "2129_11078": "Update Admin User",
  "2129_11094": "Update",
  "I2129_11054;1_6306;1_6261;1_6178": "List Approval",
  "I2160_11446;623_21593": "Warning",
  "I2163_11507;611_93749": "Are your sure to delete this Telecom Company?",
  "I2160_11410;154_64377;75_53939;250_37570": "Select Action",
  "I2160_11411;75_53853;1914_88893": "Delete",
  "I2163_11507;611_93748": "Warning",
  "I2160_11410;154_64377;75_53940;75_53852;1914_88893": "Active",
  "I2160_11410;154_64376": "* Select Action",
  "I2160_11448;623_21687": "An error has occurred, please try again or contact the administrator.",
  "I2163_11507;611_95467;30_32137": "Close",
  "I2163_11507;611_95468;611_95349": "Confirm",
  "I2160_11500;611_93387": "The Telecom Company has been successfully created.",
  "I2160_11447;623_21698": "Info",
  "I2160_11449;611_93387": "The Telecom Company has been successfully updated.",
  "I2160_11445;611_93387": "The Telecom Company Setting has been successfully deleted.",
  "I2160_11411;75_53852;1914_88893": "Edit",
  "I2160_11410;154_64377;75_53940;75_53853;1914_88893": "Deactive",
  "217_4578": "Address",
  "217_4541": "Last Name",
  "217_4610": "Security",
  "I217_4441;1_6306;1_6253;1_6154": "Identity Management",
  "217_4566": "Gender",
  "217_4554": "Email",
  "I217_4441;1_6306;1_6244": "Admin Portal",
  "I217_4441;1_6306;1_6259;1_6219": "Approval",
  "217_4580": "Address 1",
  "2313_11299": "Middle Name",
  "I217_4441;1_6306;1_6254;1_6154": "Function Setting",
  "I217_4441;1_6306;1_6242": "5",
  "217_4550": "Nick name",
  "217_4576": "English",
  "217_4459": "Update",
  "I217_4441;1_6306;1_6246;1_6154": "TOP",
  "217_4606": "Role:",
  "217_4602": "Upload",
  "2135_11219": "Email is invalid",
  "217_4558": "Phone Number",
  "2870_14098": "Country Code",
  "2135_11221": "Postcode is wrong",
  "I217_4441;1_6306;1_6256;1_6154": "Device Management",
  "217_4588": "Postcode",
  "217_4574": "Language",
  "217_4445": "Activity Logs",
  "217_4586": "State/Prefecture",
  "I217_4441;1_6306;1_6247;1_6154": "Service Management",
  "I217_4441;1_6306;1_6255;1_6154": "Developer Portal",
  "217_4613": "Personal data usage history",
  "217_4582": "Address 2",
  "217_4584": "City",
  "2087_10884": "Enter phone",
  "217_4590": "Select Country...",
  "217_4452": "First Name",
  "217_4570": "Birthday",
  "2313_11301": "Enter Middle name",
  "2135_11220": "Phone is invalid",
  "I217_4441;1_6306;1_6257;1_6154": "Vehicle Management",
  "2009_4208": "Enter Last name",
  "217_4605": "admin@organiclink.cloud",
  "I217_4441;1_6306;1_6265;1_6214": "Notification",
  "217_4607": "Owner OmAdmin",
  "217_4449": "User Profile",
  "217_4615": "Log out",
  "2087_10883": "Enter code",
  "2009_4207": "Enter First name",
  "2433_13775": "Notification",
  "2193_11186": "Success",
  "2404_13782": "Message should not be empty.",
  "2402_13757": "Please input message",
  "2402_13759": "Please input title",
  "2404_13780": "The start time must be less than the end time",
  "2365_11302": "Changed Successful!",
  "2236_11659": "Enter Reminder Title",
  "2236_11660": "Enter Message",
  "2236_11668": "Updated failure",
  "2236_11667": "Updated successfully",
  "2404_13783": "You are not allowed to select dates before the current time!",
  "2236_11662": "Select Recipient",
  "2239_11669": "Max length is 128",
  "2402_13760": "Please select segment",
  "2387_15874": "Are your sure to delete Notification?",
  "2402_13758": "Please select date",
  "2185_11324": "The start time must be less than the end time",
  "2236_11661": "Select When To Send",
  "2404_13781": "Title should not be empty.",
  "I2204_11286;819_92230;30_32128": "Edit",
  "I2204_11224;30_32069": "Receive returns",
  "I2204_11223;30_32128": "Delete",
  "2204_11228": "Action",
  "I2204_11287;645_18156": "Are you sure you want to Delete ?",
  "I2204_11287;645_18155": "Delete",
  "I2204_11287;645_18159;30_32137": "Close",
  "2204_11218": "Action",
  "I2204_11221;30_32128": "Send",
  "I2204_11231;30_32128": "Edit",
  "I2204_11286;819_92231;30_32128": "Delete",
  "2204_11193": "Manage Vehicle Setting > Action > Delete",
  "I2204_11287;645_18160;30_31931": "Delete",
  "I2204_11222;30_32128": "Edit",
  "I2204_11232;30_32128": "Delete",
  "2204_11285": "Action",
  "I2204_11225;30_32069": "Operation Log",
  "I2382_11940;581_1954;7882_19131;497_44116": "10 / page",
  "I2382_11918;18_21709": "All Organization",
  "2382_11946": "Client Portal",
  "2886_13849": "Client Portal",
  "2886_13847": "Export Management",
  "I2382_11948;21_22137": "Top",
  "I2382_11909;121_59079": "Search...",
  "I2382_11955;23_39072": "Vehicle Management",
  "I2382_11940;581_1954;7882_26275;433_34538": "11",
  "I2382_11940;581_1954;7882_21473;497_44116": "1",
  "I2382_11896;28_31246;28_31236": "Notification",
  "I2382_11915;171_66633": "Action",
  "I2382_11927;18_21709": "01/02/2024 10:30:00",
  "I2382_11940;581_1954;7882_21971;433_34538": "3",
  "2382_11902": "Segment",
  "I2382_11968;23_39072": "Approval",
  "I2382_11979;250_58566": "Send Notification",
  "I2382_11933;835_98906": "Segment 3",
  "I2382_11896;28_31263;28_31261": ">",
  "I2382_11919;18_21709": "01/03/2024 10:30:00",
  "I2382_11940;581_1954;7882_26160;433_34538": "...",
  "I2382_11896;28_31248;28_31243": "Segment",
  "2382_11922": "Action",
  "I2382_11914;171_66633": "Created Date",
  "2382_11944": "5",
  "I2382_11960;21_22137": "Driver Management",
  "I2382_11935;18_21709": "01/01/2024 10:30:00",
  "I2382_11912;171_66633": "Title",
  "I2382_11940;581_1954;7882_26229;433_34538": "10",
  "2382_11938": "Action",
  "I2382_11926;18_21709": "All Organization",
  "I2382_11966;23_39072": "Work Management",
  "I2382_11940;581_1954;7882_21853;433_34538": "2",
  "I2382_11950;23_39072": "Company setting",
  "I2382_11905;30_32140": "Add",
  "I2382_11934;18_21709": "Specific Organization",
  "I2382_11913;171_66633": "Segment Type",
  "2382_11964": "Chauffeur Connect",
  "I2382_11940;581_1954;7882_22097;433_34538": "4",
  "I2382_11961;21_22137": "Passenger Management",
  "2382_11976": "Notification",
  "I2382_11925;835_98906": "Segment 2",
  "I2382_11940;368_110840": "Show 1 to 10 of 100 results",
  "I2382_11980;250_58575": "Segment",
  "2382_11930": "Action",
  "I2382_11917;835_98906": "Segment 1",
  "I2382_12003;154_61744": "Message",
  "2382_12052": "Chauffeur Connect",
  "I2382_11985;35_49548;28_31236": "Notification",
  "I2382_12043;23_39072": "Vehicle Management",
  "I2382_11985;35_49552;28_31243": "Add Notification",
  "I2382_11986;35_49503": "Add Notification",
  "I2382_12004;863_68472;743_17640": "or",
  "2382_12025": "Schedule",
  "2382_12001": "*",
  "I2382_12004;863_68472;743_17639": "Drop your files here",
  "2382_11991": "*",
  "I2382_11985;35_49550;28_31236": "Send Notification",
  "I2382_12049;21_22137": "Passenger Management",
  "I2404_13776;121_59006": "Select Date",
  "I2382_12028;30_32140": "Next",
  "I2382_12067;250_58575": "Send Notification",
  "2382_12002": "Message",
  "2382_11996": "*",
  "I2382_12004;863_68471": "Upload Imagine",
  "2382_12007": "Destination URL",
  "I2382_12056;23_39072": "Approval",
  "I2382_12027;30_32137": "Cancel",
  "2382_11992": "Segment",
  "I2382_12054;23_39072": "Work Management",
  "2382_12032": "5",
  "I2382_12004;863_68472;743_17642": "Choose files to upload",
  "I2382_12036;21_22137": "Top",
  "I2382_12038;23_39072": "Company setting",
  "2382_11997": "Title",
  "I2382_12008;75_53807": "Destination URL",
  "I2382_11985;35_49551;28_31261": ">",
  "I2382_11993;75_53865;250_37564": "Select Segment",
  "2382_12019": "Immediately",
  "2382_12064": "Notification",
  "I2382_12048;21_22137": "Driver Management",
  "I2382_11985;35_49549;28_31261": ">",
  "I2382_12068;250_58566": "Segment",
  "I2382_11998;75_53807": "Title",
  "2382_12011": "When to send",
  "2382_12034": "Client Portal",
  "2382_12099": "When to send",
  "I2382_12155;250_58575": "Send Notification",
  "I2382_12115;30_32137": "Cancel",
  "I2382_12116;30_32140": "Next",
  "2382_12122": "Client Portal",
  "I2382_12091;250_58177": "Message",
  "I2382_12126;23_39072": "Company setting",
  "I2382_12092;863_68471": "Upload Imagine",
  "I2382_12092;863_68472;743_17640": "or",
  "I2382_12073;35_49549;28_31261": ">",
  "2382_12089": "*",
  "2382_12107": "Immediately",
  "I2382_12156;250_58566": "Segment",
  "I2382_12073;35_49548;28_31236": "Notification",
  "2382_12085": "Title",
  "I2382_12086;121_58638": "Title",
  "I2382_12096;121_58638": "Destination URL",
  "I2382_12074;35_49503": "Edit Notification",
  "I2382_12092;863_68472;743_17639": "Drop your files here",
  "I2382_12144;23_39072": "Approval",
  "I2382_12136;21_22137": "Driver Management",
  "I2382_12092;863_68472;743_17642": "Choose files to upload",
  "2382_12113": "Schedule",
  "I2382_12142;23_39072": "Work Management",
  "2382_12090": "Message",
  "2382_12079": "*",
  "I2382_12131;23_39072": "Vehicle Management",
  "I2382_12073;35_49551;28_31261": ">",
  "2382_12084": "*",
  "I2382_12073;35_49550;28_31236": "Send Notification",
  "I2382_12073;35_49552;28_31243": "Edit Notification",
  "I2382_12124;21_22137": "Top",
  "2382_12095": "Destination URL",
  "I2382_12137;21_22137": "Passenger Management",
  "2382_12140": "Chauffeur Connect",
  "I2382_12081;75_53865;250_37573": "Select Segment",
  "2382_12152": "Notification",
  "2382_12080": "Segment",
  "2382_12120": "5",
  "2382_12186": "Specific Organization",
  "I2382_12207;30_32140": "Search",
  "2382_12237": "Chauffeur Connect",
  "I2382_12162;35_49503": "Edit Segment",
  "I2382_12202;121_59015": "Select Role",
  "I2382_12161;35_49551;28_31261": ">",
  "I2382_12252;250_58566": "Send Notification",
  "I2382_12206;185_68545": "Select User",
  "2382_12201": "Role",
  "2382_12172": "Segment Type",
  "2382_12219": "Client Portal",
  "I2382_12213;30_32140": "Next",
  "I2382_12223;23_39072": "Company setting",
  "I2382_12239;23_39072": "Work Management",
  "I2382_12161;35_49549;28_31261": ">",
  "I2382_12221;21_22137": "Top",
  "I2382_12161;35_49552;28_31243": "Edit Segment",
  "2382_12179": "All Organization",
  "I2382_12233;21_22137": "Driver Management",
  "I2382_12161;35_49550;28_31236": "Segment",
  "I2382_12253;250_58575": "Segment",
  "2382_12217": "5",
  "2382_12166": "*",
  "I2382_12234;21_22137": "Passenger Management",
  "I2382_12161;35_49548;28_31236": "Notification",
  "2382_12190": "*",
  "I2382_12197;121_59015": "Select Organization",
  "2382_12196": "Organization",
  "2382_12195": "*",
  "2382_12204": "User",
  "2382_12200": "*",
  "2382_12249": "Notification",
  "2382_12171": "*",
  "I2382_12241;23_39072": "Approval",
  "I2382_12212;30_32137": "Cancel",
  "I2382_12168;121_58638": "Title",
  "2382_12191": "Target",
  "I2382_12228;23_39072": "Vehicle Management",
  "I2382_12210;30_32140": "Add Target",
  "2382_12167": "Title",
  "2382_12287": "*",
  "I2382_12300;30_32140": "Next",
  "I2382_12258;35_49550;28_31236": "Segment",
  "I2382_12315;23_39072": "Vehicle Management",
  "2382_12336": "Notification",
  "I2382_12320;21_22137": "Driver Management",
  "I2382_12265;75_53807": "Title",
  "2382_12268": "*",
  "I2382_12294;121_59006": "Select Role",
  "I2382_12299;30_32137": "Cancel",
  "I2382_12310;23_39072": "Company setting",
  "I2382_12258;35_49552;28_31243": "Add Segment",
  "I2382_12258;35_49551;28_31261": ">",
  "2382_12263": "*",
  "2382_12324": "Chauffeur Connect",
  "I2382_12328;23_39072": "Approval",
  "I2382_12326;23_39072": "Work Management",
  "I2382_12258;35_49549;28_31261": ">",
  "2382_12283": "Specific Organization",
  "I2382_12308;21_22137": "Top",
  "2382_12288": "Target",
  "I2382_12340;250_58575": "Segment",
  "I2382_12258;35_49548;28_31236": "Notification",
  "2382_12264": "Title",
  "2382_12269": "Segment Type",
  "I2382_12297;30_32140": "Add Role",
  "2382_12293": "Role",
  "I2382_12339;250_58566": "Send Notification",
  "2382_12277": "All Organization",
  "I2382_12259;35_49503": "Add Segment",
  "2382_12304": "5",
  "I2382_12321;21_22137": "Passenger Management",
  "2382_12292": "*",
  "2382_12306": "Client Portal",
  "I2382_12345;35_49550;28_31236": "Segment",
  "2382_12374": "*",
  "2382_12385": "Role",
  "I2382_12405;21_22137": "Top",
  "2382_12370": "Specific Organization",
  "I2382_12345;35_49548;28_31236": "Notification",
  "I2382_12396;30_32137": "Cancel",
  "2382_12388": "User",
  "I2382_12407;23_39072": "Company setting",
  "2382_12350": "*",
  "I2382_12423;23_39072": "Work Management",
  "2382_12380": "Organization",
  "2382_12355": "*",
  "I2382_12381;121_59006": "Select Organization",
  "2382_12356": "Segment Type",
  "I2382_12386;121_59006": "Select Role",
  "I2382_12352;75_53807": "Title",
  "I2382_12391;30_32140": "Search",
  "I2382_12346;35_49503": "Add Segment",
  "I2382_12345;35_49552;28_31243": "Add Segment",
  "2382_12403": "Client Portal",
  "2382_12421": "Chauffeur Connect",
  "I2382_12397;30_32140": "Next",
  "2382_12375": "Target",
  "2382_12379": "*",
  "I2382_12418;21_22137": "Passenger Management",
  "2382_12384": "*",
  "2382_12433": "Notification",
  "I2382_12437;250_58575": "Segment",
  "I2382_12345;35_49551;28_31261": ">",
  "2382_12401": "5",
  "2382_12363": "All Organization",
  "I2382_12412;23_39072": "Vehicle Management",
  "I2382_12417;21_22137": "Driver Management",
  "I2382_12345;35_49549;28_31261": ">",
  "I2382_12425;23_39072": "Approval",
  "2382_12351": "Title",
  "I2382_12390;185_68545": "Select User",
  "I2382_12394;30_32140": "Add Target",
  "I2382_12436;250_58566": "Send Notification",
  "I2382_12442;35_49607;28_31261": ">",
  "I2382_12522;23_39072": "Approval",
  "2382_12518": "Chauffeur Connect",
  "I2382_12442;35_49599;28_31236": "Create Segment",
  "I2382_12442;35_49595;28_31236": "Notification",
  "2382_12467": "Specific Organization",
  "I2382_12478;121_59018": "Select Organization",
  "I2382_12491;30_32081": "Add Target",
  "2382_12500": "Client Portal",
  "I2382_12534;250_58575": "Segment",
  "2382_12482": "Role",
  "I2382_12509;23_39072": "Vehicle Management",
  "I2382_12520;23_39072": "Work Management",
  "2382_12453": "Segment Type",
  "I2382_12502;21_22137": "Top",
  "2382_12448": "Title",
  "2382_12460": "All Organization",
  "2382_12485": "User",
  "I2382_12442;35_49597;28_31236": "Segment",
  "I2382_12442;35_49598;28_31261": ">",
  "I2382_12488;30_32081": "Search",
  "2382_12452": "*",
  "I2382_12483;121_59018": "Select Role",
  "I2382_12442;35_49596;28_31261": ">",
  "I2382_12443;35_49503": "Preview Segment",
  "2382_12530": "Notification",
  "2382_12447": "*",
  "2382_12472": "Target",
  "I2382_12442;35_49605;28_31243": "Preview Segment",
  "I2382_12449;185_68545": "Title",
  "I2382_12493;30_32137": "Back",
  "2382_12477": "Organization",
  "I2382_12487;185_68545": "Select User",
  "I2382_12504;23_39072": "Company setting",
  "2382_12471": "*",
  "I2382_12514;21_22137": "Driver Management",
  "I2382_12515;21_22137": "Passenger Management",
  "2382_12476": "*",
  "2382_12481": "*",
  "2382_12498": "5",
  "I2382_12533;250_58566": "Send Notification",
  "I2382_12494;30_32140": "Save",
  "I2382_12584;185_68545": "Select User",
  "I2382_12590;30_32137": "Back",
  "I2382_12619;23_39072": "Approval",
  "I2382_12546;185_68545": "Title",
  "2382_12595": "5",
  "2382_12568": "*",
  "2382_12545": "Title",
  "2382_12597": "Client Portal",
  "2382_12550": "Segment Type",
  "I2382_12617;23_39072": "Work Management",
  "I2382_12611;21_22137": "Driver Management",
  "2382_12564": "Specific Organization",
  "2382_12544": "*",
  "2382_12557": "All Organization",
  "I2382_12539;35_49595;28_31236": "Notification",
  "I2382_12601;23_39072": "Company setting",
  "I2382_12539;35_49605;28_31243": "Preview Segment",
  "I2382_12539;35_49598;28_31261": ">",
  "2382_12582": "User",
  "2382_12569": "Target",
  "I2382_12585;30_32081": "Search",
  "2382_12579": "Role",
  "I2382_12539;35_49607;28_31261": ">",
  "I2382_12612;21_22137": "Passenger Management",
  "I2382_12631;250_58575": "Segment",
  "2382_12573": "*",
  "2382_12578": "*",
  "2382_12574": "Organization",
  "I2382_12539;35_49596;28_31261": ">",
  "I2382_12591;30_32140": "Save",
  "I2382_12606;23_39072": "Vehicle Management",
  "I2382_12540;35_49503": "Preview Segment",
  "I2382_12539;35_49597;28_31236": "Segment",
  "I2382_12588;30_32081": "Add Target",
  "2382_12615": "Chauffeur Connect",
  "I2382_12580;121_59018": "Select Role",
  "I2382_12539;35_49599;28_31236": "Edit Segment",
  "2382_12549": "*",
  "I2382_12599;21_22137": "Top",
  "2382_12627": "Notification",
  "I2382_12630;250_58566": "Send Notification",
  "I2382_12575;121_59018": "Select Organization",
  "2382_12684": "5",
  "I2382_12659;185_68545": "Destination URL",
  "2382_12658": "Destination URL",
  "2382_12662": "When to send",
  "I2382_12636;35_49596;28_31261": ">",
  "2382_12676": "Specific Organization",
  "2382_12648": "Title",
  "2382_12652": "*",
  "I2382_12719;250_58575": "Send Notification",
  "2382_12704": "Chauffeur Connect",
  "I2382_12637;35_49503": "Preview Notification",
  "I2382_12690;23_39072": "Company setting",
  "I2382_12706;23_39072": "Work Management",
  "I2382_12720;250_58566": "Segment",
  "I2382_12701;21_22137": "Passenger Management",
  "I2382_12695;23_39072": "Vehicle Management",
  "I2382_12679;30_32137": "Save as draft",
  "I2382_12654;185_68601": "Message",
  "I2382_12636;35_49598;28_31261": ">",
  "2382_12643": "Segment",
  "I2382_12655;863_68472;743_17639": "Drop your files here",
  "I2382_12678;30_32137": "Back",
  "2382_12642": "*",
  "I2382_12636;35_49595;28_31236": "Notification",
  "I2382_12655;863_68471": "Upload Imagine",
  "I2382_12655;863_68472;743_17642": "Choose files to upload",
  "I2382_12636;35_49597;28_31236": "Send Notification",
  "I2382_12655;863_68472;743_17640": "or",
  "I2382_12700;21_22137": "Driver Management",
  "2382_12647": "*",
  "2382_12686": "Client Portal",
  "2382_12653": "Message",
  "I2382_12636;35_49599;28_31236": "Add Notification",
  "I2382_12636;35_49605;28_31243": "Preview Notification",
  "2382_12670": "Immediately",
  "I2382_12644;75_53865;250_37580": "Select Segment",
  "2382_12716": "Notification",
  "I2382_12680;30_32140": "Publish",
  "I2382_12708;23_39072": "Approval",
  "I2382_12636;35_49607;28_31261": ">",
  "I2382_12688;21_22137": "Top",
  "I2382_12649;185_68545": "Title",
  "2382_12773": "Client Portal",
  "I2382_12744;863_68472;743_17642": "Choose files to upload",
  "I2382_12806;250_58575": "Send Notification",
  "2382_12759": "Immediately",
  "I2382_12743;185_68601": "Message",
  "I2382_12795;23_39072": "Approval",
  "I2382_12793;23_39072": "Work Management",
  "2382_12771": "5",
  "I2382_12744;863_68471": "Upload Imagine",
  "2382_12765": "Specific Organization",
  "2382_12732": "Segment",
  "I2382_12744;863_68472;743_17640": "or",
  "I2382_12787;21_22137": "Driver Management",
  "I2382_12782;23_39072": "Vehicle Management",
  "I2382_12725;35_49550;28_31236": "Send Notification",
  "2382_12737": "Title",
  "2382_12747": "Destination URL",
  "I2382_12725;35_49548;28_31236": "Notification",
  "I2382_12788;21_22137": "Passenger Management",
  "I2382_12807;250_58566": "Segment",
  "2382_12803": "Notification",
  "I2382_12744;863_68472;743_17639": "Drop your files here",
  "2382_12751": "When to send",
  "2382_12741": "*",
  "I2382_12733;75_53865;250_37580": "Select Segment",
  "2382_12736": "*",
  "I2382_12777;23_39072": "Company setting",
  "2382_12742": "Message",
  "I2382_12748;185_68545": "Destination URL",
  "2382_12791": "Chauffeur Connect",
  "I2382_12725;35_49551;28_31261": ">",
  "2382_12731": "*",
  "I2382_12767;30_32137": "Cancel",
  "I2382_12725;35_49549;28_31261": ">",
  "I2382_12725;35_49552;28_31243": "View Notification",
  "I2382_12726;35_49503": "View Notification",
  "I2382_12775;21_22137": "Top",
  "I2382_12738;185_68545": "Title",
  "2382_12867": "Sa",
  "I2382_12823;7636_13405;121_58638": "235",
  "I2382_12905;8068_76053": "27",
  "I2382_12811;30_32128": "Edit",
  "I2382_12929;8068_76055": "",
  "I2382_12916;8068_76055": "7",
  "I2382_12910;8068_76055": "1",
  "I2382_12904;8068_76053": "26",
  "I2382_12899;8068_76053": "22",
  "I2382_12914;8068_76055": "5",
  "I2382_12921;8068_76055": "20",
  "I2382_12874;8068_76053": "29",
  "I2382_12890;8068_76053": "14",
  "2382_12857": "Mo",
  "I2382_12933;8068_76055": "07",
  "I2382_12825;7636_13405;121_58638": "Pending",
  "I2382_12812;30_31919": "Delete",
  "I2382_12883;8068_76053": "8",
  "I2382_12834;75_53940;75_53852;75_53818": "Publish",
  "I2382_12891;8068_76053": "15",
  "I2382_12927;8068_76055": "",
  "I2382_12833;75_53939;497_42334": "Type",
  "I2382_12922;8068_76055": "21",
  "I2382_12934;8068_76055": "08",
  "I2382_12944;30_32021": "Ok",
  "I2382_12894;8068_76053": "17",
  "I2382_12830;30_32137": "Cancel",
  "I2382_12928;8068_76055": "",
  "I2382_12876;8068_76053": "2",
  "I2382_12888;8068_76053": "12",
  "I2382_12895;8068_76053": "18",
  "2382_12849": "Mar 2024",
  "I2382_12939;8068_76055": "13",
  "I2382_12833;75_53940;75_53853;75_53818": "Schedule",
  "I2382_12884;8068_76053": "9",
  "I2382_12823;7636_13403": "Approval ID",
  "I2382_12908;8068_76055": "30",
  "I2382_12826;7636_13405;121_58638": "Updated",
  "I2382_12841;30_32128": "Portal User",
  "I2382_12880;8068_76053": "5",
  "I2382_12832;611_93749": "Are you sure to delete Notification ?",
  "2382_12855": "Su",
  "I2382_12900;8068_76053": "23",
  "I2382_12833;75_53940;75_53852;75_53818": "Immediatly",
  "I2382_12870;8068_76055": "25",
  "2382_12865": "Fr",
  "I2382_12926;8068_76055": "",
  "I2382_12872;8068_76053": "27",
  "I2382_12838;30_32140": "Add",
  "2382_12863": "Th",
  "I2382_12912;8068_76055": "3",
  "I2382_12935;8068_76055": "09",
  "I2382_12889;8068_76053": "13",
  "I2382_12932;8068_76055": "06",
  "I2382_12822;7636_13405;121_58638": "Flow Name (Click to view)",
  "I2382_12832;611_95467;30_32137": "Cancel",
  "I2382_12826;7636_13403": "After status",
  "I2382_12827;7636_13403": "Request date",
  "I2382_12887;8068_76053": "11",
  "I2382_12936;8068_76055": "10",
  "I2382_12907;8068_76055": "29",
  "I2382_12831;30_32140": "Request for Approval",
  "I2382_12875;8068_76053": "1",
  "I2382_12840;30_32128": "End User",
  "I2382_12834;75_53940;75_53853;75_53818": "Draft",
  "I2382_12906;8068_76053": "28",
  "I2382_12896;8068_76053": "19",
  "I2382_12825;7636_13403": "Current status",
  "I2382_12878;8068_76053": "3",
  "I2382_12881;8068_76051": "6",
  "I2382_12824;7636_13403": "Requestor",
  "I2382_12882;8068_76053": "7",
  "I2382_12943;30_32018": "Now",
  "I2382_12832;611_93748": "Warning",
  "2382_12859": "Tu",
  "I2382_12834;75_53939;497_42334": "Status",
  "I2382_12827;7636_13405;121_58638": "2023-12-23 14:24:30",
  "I2382_12911;8068_76055": "2",
  "I2382_12938;8068_76055": "12",
  "I2382_12940;8068_76055": "14",
  "I2382_12897;8068_76053": "20",
  "I2382_12886;8068_76053": "10",
  "I2382_12871;8068_76055": "26",
  "I2382_12937;8068_76055": "11",
  "I2382_12915;8068_76055": "6",
  "I2382_12879;8068_76053": "4",
  "2382_12817": "Approval Confirmation",
  "I2382_12903;8068_76053": "25",
  "I2382_12824;7636_13405;121_58638": "OM Staff - Bien Hoang",
  "I2382_12873;8068_76053": "28",
  "I2382_12898;8068_76053": "21",
  "I2382_12813;30_32128": "View Statistics",
  "I2382_12892;8068_76053": "16",
  "I2382_12913;8068_76055": "4",
  "I2382_12923;8068_76055": "22",
  "I2382_12832;611_95468;611_95349": "Confirm",
  "I2382_12822;7636_13403": "Approval Flow",
  "I2382_12925;8068_76055": "",
  "I2382_12902;8068_76053": "24",
  "I2382_12924;8068_76055": "23",
  "2382_12861": "We",
  "I2382_12957;30_32140": "Add",
  "I2382_12979;18_21709": "Publish",
  "I2382_13016;23_39072": "Company setting",
  "I2382_12967;171_66633": "Title",
  "I2382_12987;18_21709": "Segment 2",
  "I2382_13006;581_1954;7882_21971;433_34538": "3",
  "I2382_12948;28_31248;28_31243": "Send Notification",
  "I2382_12966;171_66633": "ID",
  "I2382_12971;171_66633": "Status",
  "I2382_12963;121_59079": "Search...",
  "2382_13030": "Chauffeur Connect",
  "I2382_13006;581_1954;7882_26275;433_34538": "11",
  "I2382_12998;18_21709": "Segment 3",
  "I2382_13027;21_22137": "Passenger Management",
  "2382_13012": "Client Portal",
  "I2382_12948;28_31246;28_31236": "Notification",
  "I2382_13006;581_1954;7882_21853;433_34538": "2",
  "I2382_12996;18_21709": "49",
  "2382_13004": "Action",
  "I2382_13006;368_110840": "Show 1 to 10 of 100 results",
  "I2382_13021;23_39072": "Vehicle Management",
  "2382_13010": "5",
  "I2382_12972;171_66633": "Action",
  "I2382_13032;23_39072": "Work Management",
  "I2382_12990;18_21709": "Draft",
  "I2382_12989;33_33258;33_33254": "Schedule",
  "I2382_12986;835_98906": "Notification 2",
  "2382_12993": "Action",
  "I2382_13006;581_1954;7882_26160;433_34538": "...",
  "I2382_12978;33_33258;33_33254": "Immediately",
  "I2382_12970;171_66633": "Type",
  "I2389_15877;250_58566": "Segment",
  "I2382_13006;581_1954;7882_21473;497_44116": "1",
  "I2382_13014;21_22137": "Top",
  "I2382_12974;18_21709": "30",
  "I2382_13006;581_1954;7882_19131;497_44116": "10 / page",
  "I2382_12976;18_21709": "Segment 1",
  "I2382_13026;21_22137": "Driver Management",
  "I2382_12969;171_66633": "Send Date",
  "I2382_12961;433_34538": "Type",
  "I2382_12999;18_21709": "01/01/2024",
  "I2382_12997;835_98906": "Notification 3",
  "I2382_12948;28_31263;28_31261": ">",
  "2382_12982": "Action",
  "2382_12954": "Send Notification",
  "I2382_12975;835_98906": "Notification 1",
  "I2382_12985;18_21709": "17",
  "I2382_13034;23_39072": "Approval",
  "I2382_13006;581_1954;7882_22097;433_34538": "4",
  "I2382_12962;433_34538": "Status",
  "I2382_13000;33_33258;33_33254": "Immediately",
  "2382_13042": "Notification",
  "I2382_12968;171_66633": "Segment",
  "I2389_15876;250_58575": "Send Notification",
  "I2382_13006;581_1954;7882_26229;433_34538": "10",
  "I2382_12977;18_21709": "01/03/2024",
  "I2382_13001;18_21709": "Publish",
  "I2382_12988;18_21709": "01/02/2024",
  "I2382_13053;35_49503": "View Statistics",
  "I2382_13072;23_39072": "Vehicle Management",
  "I2382_13065;21_22137": "Top",
  "I2382_13085;23_39072": "Approval",
  "2382_13056": "Coming Soon",
  "2382_13081": "Chauffeur Connect",
  "I2382_13096;250_58575": "Send Notification",
  "I2382_13052;35_49548;28_31236": "Notification",
  "2382_13063": "Client Portal",
  "I2382_13052;35_49551;28_31261": ">",
  "I2382_13097;250_58566": "Segment",
  "I2382_13052;35_49550;28_31236": "Send Notification",
  "I2382_13078;21_22137": "Passenger Management",
  "2382_13061": "5",
  "I2382_13057;30_32137": "Cancel",
  "I2382_13083;23_39072": "Work Management",
  "I2382_13052;35_49549;28_31261": ">",
  "I2382_13067;23_39072": "Company setting",
  "I2382_13077;21_22137": "Driver Management",
  "2382_13093": "Notification",
  "I2382_13052;35_49552;28_31243": "View Statistics",
  "I2382_13139;835_98906": "51",
  "I2382_13107;611_93748": "Warning",
  "I2382_13154;581_1954;7882_21971;433_34538": "3",
  "I2382_13106;30_31919": "Delete",
  "I2382_13109;30_32128": "Admin",
  "I2382_13154;581_1954;7882_26275;433_34538": "11",
  "I2382_13154;581_1954;7882_22097;433_34538": "4",
  "I2382_13107;611_95468;611_95349": "Confirm",
  "I2382_13141;18_21709": "xxxx@gmail.com",
  "I2382_13123;121_59079": "Search...",
  "I2382_13145;835_98906": "38",
  "I2382_13133;835_98906": "98",
  "I2382_13153;18_21709": "xxxx@gmail.com",
  "I2382_13154;581_1954;7882_26160;433_34538": "...",
  "I2382_13154;368_110840": "Show 1 to 10 of 100 results",
  "I2382_13102;30_32128": "End User",
  "I2382_13146;18_21709": "Joshua Davis",
  "I2382_13154;581_1954;7882_21853;433_34538": "2",
  "I2382_13107;611_95467;30_32137": "Cancel",
  "I2382_13112;30_32128": "Passenger",
  "I2382_13158;30_32140": "Save",
  "I2382_13129;171_66633": "Email",
  "I2382_13122;433_34538": "Activity Type",
  "I2382_13151;835_98906": "45",
  "I2382_13140;18_21709": "Maria White",
  "I2382_13107;611_93749": "Are you sure to delete Segment ?",
  "I2382_13127;171_66633": "ID",
  "I2382_13147;18_21709": "xxxx@gmail.com",
  "I2382_13154;581_1954;7882_19131;497_44116": "10 / page",
  "I2382_13135;18_21709": "xxxx@gmail.com",
  "I2382_13157;30_32137": "Cancel",
  "I2382_13154;581_1954;7882_21473;497_44116": "1",
  "I2382_13105;30_32128": "Edit",
  "I2382_13154;581_1954;7882_26229;433_34538": "10",
  "2382_13118": "Select User",
  "I2382_13134;18_21709": "Jennifer Robinson",
  "I2382_13103;30_32128": "Portal User",
  "I2382_13110;30_32128": "Driver",
  "I2382_13152;18_21709": "Brian Baker",
  "I2382_13128;171_66633": "Name",
  "I2382_13214;21_22137": "Top",
  "2382_13178": "*",
  "I2382_13232;23_39072": "Work Management",
  "I2382_13245;250_58575": "Send Notification",
  "2382_13179": "Message",
  "I2382_13162;35_49599;28_31236": "Edit Notification",
  "2382_13242": "Notification",
  "2382_13169": "Segment",
  "I2382_13206;30_32140": "Send",
  "I2382_13162;35_49595;28_31236": "Notification",
  "I2382_13175;185_68545": "Title",
  "2382_13184": "Destination URL",
  "I2382_13180;185_68601": "Message",
  "2382_13196": "Immediately",
  "2382_13188": "When to send",
  "I2382_13246;250_58566": "Segment",
  "I2382_13162;35_49596;28_31261": ">",
  "I2382_13181;863_68472;743_17640": "or",
  "2382_13230": "Chauffeur Connect",
  "I2382_13163;35_49503": "Preview Notification",
  "I2382_13226;21_22137": "Driver Management",
  "I2382_13162;35_49598;28_31261": ">",
  "I2382_13170;75_53865;250_37580": "Select Segment",
  "I2382_13234;23_39072": "Approval",
  "2382_13210": "5",
  "I2382_13181;863_68471": "Upload Imagine",
  "I2382_13216;23_39072": "Company setting",
  "I2382_13185;185_68545": "Destination URL",
  "I2382_13204;30_32137": "Back",
  "I2382_13221;23_39072": "Vehicle Management",
  "I2382_13205;30_32137": "Save as draft",
  "I2382_13162;35_49605;28_31243": "Preview Notification",
  "I2382_13227;21_22137": "Passenger Management",
  "2382_13168": "*",
  "I2382_13181;863_68472;743_17642": "Choose files to upload",
  "2382_13212": "Client Portal",
  "I2382_13181;863_68472;743_17639": "Drop your files here",
  "2382_13202": "Specific Organization",
  "I2382_13162;35_49597;28_31236": "Send Notification",
  "2382_13174": "Title",
  "2382_13173": "*",
  "I2382_13162;35_49607;28_31261": ">",
  "2390_15980": "5",
  "I2390_15924;35_49597;28_31236": "Segment",
  "2390_15964": "Role",
  "2390_15982": "Client Portal",
  "I2390_16002;23_39072": "Work Management",
  "I2390_15924;35_49605;28_31243": "View Segment",
  "I2390_15984;21_22137": "Top",
  "2390_15958": "*",
  "I2390_15969;185_68545": "Select User",
  "I2390_15924;35_49595;28_31236": "Notification",
  "2390_15954": "Target",
  "2390_15953": "*",
  "I2390_15960;121_59018": "Select Organization",
  "I2390_15976;30_32140": "Save",
  "I2390_15931;185_68545": "Title",
  "I2390_15996;21_22137": "Driver Management",
  "2390_15963": "*",
  "2390_15959": "Organization",
  "I2390_15924;35_49598;28_31261": ">",
  "2390_16000": "Chauffeur Connect",
  "I2390_15925;35_49503": "View Segment",
  "2390_15935": "Segment Type",
  "I2390_16015;250_58566": "Send Notification",
  "I2390_15975;30_32137": "Back",
  "2390_15967": "User",
  "I2390_15924;35_49596;28_31261": ">",
  "2390_15934": "*",
  "I2390_16004;23_39072": "Approval",
  "I2390_15973;30_32081": "Add Target",
  "I2390_15997;21_22137": "Passenger Management",
  "2390_16012": "Notification",
  "2390_15929": "*",
  "2390_15930": "Title",
  "I2390_16016;250_58575": "Segment",
  "I2390_15986;23_39072": "Company setting",
  "I2390_15924;35_49599;28_31236": "Edit Segment",
  "I2390_15970;30_32081": "Search",
  "2390_15949": "Specific Organization",
  "2390_15942": "All Organization",
  "I2390_15924;35_49607;28_31261": ">",
  "I2390_15965;121_59018": "Select Role",
  "I2390_15991;23_39072": "Vehicle Management",
  "I2402_13777;611_95467;30_32137": "Cancel",
  "I2402_13777;611_95468;611_95349": "Confirm",
  "I2402_13777;611_93748": "Warning",
  "256_14119": "Services",
  "257_8003": "8",
  "257_7998": "8",
  "257_8024": "End User",
  "I256_14087;1_6306;1_6265;1_6214": "Notification",
  "256_14115": "Overview",
  "I256_14087;1_6306;1_6247;1_6154": "Service Management",
  "I256_14087;1_6306;1_6242": "5",
  "I256_14087;1_6306;1_6246;1_6154": "TOP",
  "I256_14087;1_6306;1_6244": "Admin Portal",
  "I256_14087;1_6306;1_6253;1_6154": "Identity Management",
  "256_14120": "6",
  "257_8002": "Resources",
  "257_8016": "Users",
  "257_7997": "Roles",
  "257_8025": "8",
  "257_8017": "8",
  "I256_14087;1_6306;1_6254;1_6154": "Function Setting",
  "257_7992": "Organizations",
  "257_8011": "8",
  "I256_14087;1_6306;1_6256;1_6154": "Device Management",
  "I256_14087;1_6306;1_6257;1_6154": "Vehicle Management",
  "I256_14087;1_6306;1_6259;1_6219": "Approval",
  "257_8010": "Policies",
  "I256_14087;1_6306;1_6255;1_6154": "Developer Portal",
  "256_14124": "Apps",
  "257_7993": "8",
  "256_14125": "8",
  "256_14091": "Activity Logs",
  "59_949": "Approved",
  "2009_3528": "1",
  "2016_4214": "Reject",
  "59_1270": "14:10 29-01-2024",
  "59_969": "api-idm_user_create_39",
  "59_946": "Approved",
  "2009_3539": "Show",
  "59_989": "api-idm_user_create_40",
  "59_901": "Approved",
  "I10_2540;1_6306;1_6244": "Admin Portal",
  "59_1192": "admin@organiclink.cloud requested to create user 48",
  "59_925": "Approved",
  "59_1246": "admin@organiclink.cloud",
  "59_1273": "14:10 29-01-2024",
  "59_1274": "14:10 29-01-2024",
  "59_1194": "admin@organiclink.cloud requested to create user 44",
  "I10_2540;1_6306;1_6259;1_6219": "Approval",
  "I10_2540;1_6306;1_6246;1_6154": "TOP",
  "59_1189": "admin@organiclink.cloud requested to create user 41",
  "59_975": "api-idm_user_create_30",
  "59_1162": "Created At",
  "59_1272": "14:10 29-01-2024",
  "2009_3548": "/",
  "I10_2540;1_6306;1_6254;1_6154": "Function Setting",
  "59_858": "Status",
  "59_1140": "Created By",
  "59_1290": "End Date",
  "59_1271": "14:10 29-01-2024",
  "59_1195": "admin@organiclink.cloud requested to create user 39",
  "59_1234": "admin@organiclink.cloud",
  "59_1011": "Status",
  "I10_2540;1_6306;1_6255;1_6154": "Developer Portal",
  "I10_2540;1_6306;1_6247;1_6154": "Service Management",
  "59_951": "Action",
  "59_1286": "Start Date",
  "59_826": "List Approval",
  "I10_2540;1_6306;1_6262;1_6169": "Approval Setting",
  "59_1003": "Function Name",
  "59_1118": "Setting Flow",
  "59_1204": "admin@organiclink.cloud",
  "59_953": "Description",
  "59_1265": "admin@organiclink.cloud",
  "59_1260": "admin@organiclink.cloud",
  "59_987": "api-idm_user_create_46",
  "2009_3545": "results",
  "59_991": "api-idm_user_create_48",
  "59_898": "Approved",
  "59_1210": "admin@organiclink.cloud",
  "59_1132": "admin@organiclink.cloud",
  "59_1268": "14:10 29-01-2024",
  "2009_3540": "1",
  "59_963": "api-idm_user_create_41",
  "2016_4212": "Approve",
  "2009_3535": "page",
  "59_1269": "14:10 29-01-2024",
  "59_1216": "admin@organiclink.cloud",
  "2009_3549": "List Approval",
  "2009_3542": "40",
  "59_1154": "admin@organiclink.cloud",
  "I10_2540;1_6306;1_6261;1_6178": "List Approval",
  "2009_3547": "Approval",
  "59_1188": "Approved",
  "59_1197": "admin@organiclink.cloud requested to create user 31",
  "59_1257": "admin@organiclink.cloud",
  "2009_3544": "of",
  "2009_3534": "20  /",
  "59_1259": "admin@organiclink.cloud",
  "59_1264": "admin@organiclink.cloud",
  "2009_3543": "to",
  "59_965": "api-idm_user_create_42",
  "59_1187": "Approved",
  "59_1196": "admin@organiclink.cloud requested to create user 30",
  "59_977": "api-idm_user_create_31",
  "I10_2540;1_6306;1_6256;1_6154": "Device Management",
  "59_993": "api-idm_user_create_44",
  "I10_2540;1_6306;1_6257;1_6154": "Vehicle Management",
  "59_1185": "Rejected",
  "10_2544": "Activity Logs",
  "59_922": "Approved",
  "I10_2540;1_6306;1_6265;1_6214": "Notification",
  "59_1186": "Approved",
  "I10_2540;1_6306;1_6242": "5",
  "59_1296": "Search",
  "59_1267": "14:10 29-01-2024",
  "59_1266": "14:10 29-01-2024",
  "59_979": "admin@organiclink.cloud requested to create user 46",
  "59_1262": "admin@organiclink.cloud",
  "59_1193": "admin@organiclink.cloud requested to create user 43",
  "59_1263": "admin@organiclink.cloud",
  "59_967": "api-idm_user_create_43",
  "2009_3541": "20",
  "59_1261": "admin@organiclink.cloud",
  "59_1252": "admin@organiclink.cloud",
  "59_1190": "admin@organiclink.cloud requested to create user 40",
  "59_1176": "14:10 29-01-2024",
  "59_1191": "admin@organiclink.cloud requested to create user 42",
  "59_1228": "admin@organiclink.cloud",
  "59_1240": "admin@organiclink.cloud",
  "59_1222": "admin@organiclink.cloud",
  "59_1258": "admin@organiclink.cloud",
  "I10_2540;1_6306;1_6253;1_6154": "Identity Management",
  "61_1684": "update",
  "61_1384": "admin@organiclink.cloud",
  "61_1711": "api-ams",
  "61_1705": "api-ams",
  "61_1354": "Service Name",
  "I61_1546;1_6306;1_6254;1_6154": "Function Setting",
  "61_1696": "system_error",
  "61_1719": "update",
  "61_1666": "admin@organiclink.cloud",
  "2009_3672": "40",
  "61_1358": "Modified Date",
  "61_1729": "Create",
  "61_1523": "Search",
  "61_1386": "14:10 29-01-2024",
  "61_1463": "14:10 29-01-2024",
  "61_1504": "api-idm_user_update",
  "61_1500": "admin@organiclink.cloud",
  "I61_1546;1_6306;1_6262;1_6169": "Approval Setting",
  "61_1697": "translation_element",
  "61_1652": "admin@organiclink.cloud",
  "61_1557": "Approval Settings",
  "61_1397": "admin@organiclink.cloud",
  "61_1692": "organization",
  "I61_1546;1_6306;1_6265;1_6214": "Notification",
  "2009_3671": "20",
  "2009_3675": "results",
  "I61_1546;1_6306;1_6257;1_6154": "Vehicle Management",
  "61_1727": "update",
  "2009_3669": "Show",
  "61_1701": "api-idm",
  "61_1448": "api-idm_user_update",
  "61_1399": "14:10 29-01-2024",
  "61_1474": "admin@organiclink.cloud",
  "61_1707": "api-ams",
  "I61_1546;1_6306;1_6256;1_6154": "Device Management",
  "61_1446": "14:10 29-01-2024",
  "61_1416": "api-idm_user_update",
  "61_1410": "admin@organiclink.cloud",
  "61_1373": "14:10 29-01-2024",
  "61_1412": "14:10 29-01-2024",
  "61_1709": "api-ams",
  "61_1694": "client_dictionary",
  "61_1552": "Activity Logs",
  "2009_3664": "20  /",
  "61_1508": "api-idm_user_update",
  "61_1670": "Method",
  "61_1433": "14:10 29-01-2024",
  "61_1452": "User",
  "61_1668": "admin@organiclink.cloud",
  "2009_3700": "Approval Setting",
  "61_1414": "api-idm_user_update",
  "61_1695": "client_dictionary",
  "61_1487": "admin@organiclink.cloud",
  "2009_3698": "Approval",
  "61_1662": "admin@organiclink.cloud",
  "I61_1546;1_6306;1_6259;1_6219": "Approval",
  "2009_3673": "to",
  "61_1722": "create",
  "61_1648": "Created By",
  "61_1721": "update",
  "I61_1546;1_6306;1_6253;1_6154": "Identity Management",
  "61_1455": "api-idm",
  "61_1350": "Action",
  "61_1713": "api-ams",
  "61_1715": "api-ams",
  "61_1654": "admin@organiclink.cloud",
  "2009_3665": "page",
  "61_1476": "14:10 29-01-2024",
  "2009_3658": "1",
  "I61_1546;1_6306;1_6244": "Admin Portal",
  "61_1724": "translation",
  "61_1512": "ID",
  "61_1726": "update",
  "61_1693": "client_dictionary",
  "61_1418": "api-idm_user_update",
  "61_1371": "admin@organiclink.cloud",
  "61_1664": "admin@organiclink.cloud",
  "2009_3670": "1",
  "61_1650": "admin@organiclink.cloud",
  "61_1725": "update",
  "61_1489": "14:10 29-01-2024",
  "61_1703": "api-idm",
  "61_1691": "User",
  "61_1444": "admin@organiclink.cloud",
  "I61_1546;1_6306;1_6261;1_6178": "List Approval",
  "I61_1546;1_6306;1_6255;1_6154": "Developer Portal",
  "I61_1546;1_6306;1_6242": "5",
  "61_1502": "14:10 29-01-2024",
  "61_1510": "api-idm_user_update",
  "61_1718": "create",
  "61_1420": "api-idm_user_update",
  "61_1656": "admin@organiclink.cloud",
  "61_1356": "Modified By",
  "61_1720": "delete",
  "61_1461": "admin@organiclink.cloud",
  "61_1660": "admin@organiclink.cloud",
  "61_1450": "api-idm_user_update",
  "61_1699": "translation_element",
  "61_1506": "api-idm_user_update",
  "2009_3674": "of",
  "2009_3699": "/",
  "61_1717": "api-ams",
  "61_1352": "Object Name",
  "I61_1546;1_6306;1_6246;1_6154": "TOP",
  "61_1658": "admin@organiclink.cloud",
  "61_1431": "admin@organiclink.cloud",
  "61_1698": "translation_element",
  "61_1723": "update",
  "I61_1546;1_6306;1_6247;1_6154": "Service Management",
  "I61_1970;1_6306;1_6262;1_6169": "Approval Setting",
  "61_2106": "Enter step number",
  "61_2085": "Service Name",
  "61_2092": "Enter object...",
  "61_2104": "Steps",
  "I61_1970;1_6306;1_6242": "5",
  "I61_1970;1_6306;1_6253;1_6154": "Identity Management",
  "153_5070": "Enter service",
  "I61_1970;1_6306;1_6246;1_6154": "TOP",
  "61_2121": "Cancel",
  "2009_3702": "Approval",
  "I61_1970;1_6306;1_6244": "Admin Portal",
  "2009_3704": "Approval Setting",
  "I61_1970;1_6306;1_6254;1_6154": "Function Setting",
  "61_2110": "Enter user...",
  "61_2118": "Add field",
  "61_2090": "Object",
  "61_2108": "Approvers",
  "61_1976": "Activity Logs",
  "61_2097": "Enter setting method",
  "2024_4540": "Approval Setting",
  "61_2082": "Name",
  "I61_1970;1_6306;1_6257;1_6154": "Vehicle Management",
  "I61_1970;1_6306;1_6247;1_6154": "Service Management",
  "153_5072": "Enter method",
  "I61_1970;1_6306;1_6261;1_6178": "List Approval",
  "153_5074": "Enter approvers",
  "I61_1970;1_6306;1_6265;1_6214": "Notification",
  "153_5073": "Enter step number",
  "61_1981": "Create Approval Setting",
  "153_5069": "Enter name",
  "2009_3703": "/",
  "61_2123": "Next",
  "153_5071": "Enter object",
  "I61_1970;1_6306;1_6259;1_6219": "Approval",
  "I61_1970;1_6306;1_6255;1_6154": "Developer Portal",
  "61_2087": "Enter name",
  "61_2084": "Enter name",
  "61_2095": "Method",
  "2127_10793": "Please enter an integer value",
  "I61_1970;1_6306;1_6256;1_6154": "Device Management",
  "61_2182": "Next",
  "I61_2127;1_6306;1_6257;1_6154": "Vehicle Management",
  "I61_2127;1_6306;1_6256;1_6154": "Device Management",
  "2024_4548": "Approval Setting",
  "61_2147": "IDM",
  "61_2159": "Update",
  "61_2151": "Object",
  "61_2167": "Approvers",
  "61_2178": "Add field",
  "I61_2127;1_6306;1_6254;1_6154": "Function Setting",
  "61_2145": "Service Name",
  "61_2133": "Activity Logs",
  "61_2157": "Method",
  "I61_2127;1_6306;1_6261;1_6178": "List Approval",
  "I61_2127;1_6306;1_6255;1_6154": "Developer Portal",
  "I61_2127;1_6306;1_6244": "Admin Portal",
  "I61_2127;1_6306;1_6262;1_6169": "Approval Setting",
  "I61_2127;1_6306;1_6259;1_6219": "Approval",
  "2024_4544": "Update Approval Setting",
  "61_2153": "User",
  "2009_3710": "Approval",
  "2009_3712": "Approval Setting",
  "61_2169": "Admin@organicklink.cloud",
  "I61_2127;1_6306;1_6247;1_6154": "Service Management",
  "61_2141": "Name",
  "61_2163": "Steps",
  "61_2143": "Update Admin User",
  "61_2165": "1",
  "61_2180": "Cancel",
  "I61_2127;1_6306;1_6246;1_6154": "TOP",
  "I61_2127;1_6306;1_6265;1_6214": "Notification",
  "I61_2127;1_6306;1_6242": "5",
  "I61_2127;1_6306;1_6253;1_6154": "Identity Management",
  "2009_3711": "/",
  "I61_2276;1_6306;1_6257;1_6154": "Vehicle Management",
  "61_2290": "Name",
  "2024_4554": "View Approval Setting",
  "2009_3714": "Approval",
  "I61_2276;1_6306;1_6259;1_6219": "Approval",
  "2024_4550": "View",
  "I61_2276;1_6306;1_6253;1_6154": "Identity Management",
  "I61_2276;1_6306;1_6262;1_6169": "Approval Setting",
  "61_2292": "Update Admin User",
  "I61_2276;1_6306;1_6261;1_6178": "List Approval",
  "61_2296": "IDM",
  "I61_2276;1_6306;1_6265;1_6214": "Notification",
  "I61_2276;1_6306;1_6255;1_6154": "Developer Portal",
  "61_2308": "Update",
  "61_2332": "Back",
  "61_2282": "Activity Logs",
  "61_2312": "Steps",
  "I61_2276;1_6306;1_6247;1_6154": "Service Management",
  "61_2294": "Service Name",
  "I61_2276;1_6306;1_6254;1_6154": "Function Setting",
  "I61_2276;1_6306;1_6256;1_6154": "Device Management",
  "I61_2276;1_6306;1_6242": "5",
  "61_2302": "User",
  "61_2306": "Method",
  "61_2300": "Object",
  "61_2330": "Add field",
  "61_2316": "Approvers",
  "2009_3716": "Approval Setting",
  "2009_3715": "/",
  "I61_2276;1_6306;1_6246;1_6154": "TOP",
  "61_2314": "1",
  "I61_2276;1_6306;1_6244": "Admin Portal",
  "61_2319": "Admin@organicklink.cloud",
  "61_3045": "Approval",
  "61_3112": "test idm . From service api-idm raise error 200",
  "I61_2949;1_6306;1_6256;1_6154": "Device Management",
  "61_3111": "Get IDM",
  "2009_3694": "Reject",
  "2367_11306": "99+",
  "2009_3692": "Approve",
  "I61_2949;1_6306;1_6253;1_6154": "Identity Management",
  "61_3035": "My Notification",
  "I61_2949;1_6306;1_6244": "Admin Portal",
  "I61_2949;1_6306;1_6259;1_6219": "Approval",
  "61_3051": "99+",
  "61_3053": "Setting",
  "61_3049": "Inbox",
  "61_3047": "99+",
  "61_3101": "Get IDM",
  "I61_2949;1_6306;1_6255;1_6154": "Developer Portal",
  "I61_2949;1_6306;1_6254;1_6154": "Function Setting",
  "I61_2949;1_6306;1_6246;1_6154": "TOP",
  "I61_2949;1_6306;1_6257;1_6154": "Vehicle Management",
  "61_3102": "test idm . From service api-idm raise error 200",
  "61_3038": "General",
  "61_2953": "Activity Logs",
  "I61_2949;1_6306;1_6265;1_6214": "Notification",
  "2367_11304": "Tasks",
  "I61_2949;1_6306;1_6247;1_6154": "Service Management",
  "61_3039": "99+",
  "61_3113": "29-01-2024 15:52",
  "61_3103": "29-01-2024 15:52",
  "I61_2949;1_6306;1_6242": "5",
  "I61_3136;1_6306;1_6265;1_6214": "Notification",
  "I61_3136;1_6306;1_6254;1_6154": "Function Setting",
  "I61_3136;1_6306;1_6259;1_6219": "Approval",
  "2395_13757": "Message",
  "61_3140": "Activity Logs",
  "I61_3136;1_6306;1_6244": "Admin Portal",
  "61_3594": "Back",
  "61_3587": "Title",
  "I61_3136;1_6306;1_6257;1_6154": "Vehicle Management",
  "I61_3136;1_6306;1_6255;1_6154": "Developer Portal",
  "I61_3136;1_6306;1_6246;1_6154": "TOP",
  "2009_3718": "My Notification",
  "I61_3136;1_6306;1_6253;1_6154": "Identity Management",
  "I61_3136;1_6306;1_6247;1_6154": "Service Management",
  "2009_3720": "Detail Notification",
  "61_3584": "Detail Notification",
  "61_3589": "GET IDM",
  "61_3593": "test idm . From service api-idm raise error 200",
  "2009_3719": "/",
  "I61_3136;1_6306;1_6242": "5",
  "61_3591": "Thurday, 28-02-2024 12:14",
  "I61_3136;1_6306;1_6256;1_6154": "Device Management",
  "I61_3599;1_6306;1_6259;1_6219": "Approval",
  "I61_3599;1_6306;1_6256;1_6154": "Device Management",
  "61_3805": "Silent",
  "61_3603": "Activity Logs",
  "61_3806": "Turn on if you would like to receive notifications without popups and sound.",
  "61_3613": "99+",
  "61_3777": "Search by object",
  "61_3611": "General",
  "I61_3599;1_6306;1_6253;1_6154": "Identity Management",
  "61_3786": "Approval",
  "I61_3599;1_6306;1_6254;1_6154": "Function Setting",
  "61_3606": "My Notification",
  "I61_3599;1_6306;1_6255;1_6154": "Developer Portal",
  "61_3798": "Logistic Connect",
  "61_3622": "Setting",
  "61_3857": "Send Email",
  "I61_3599;1_6306;1_6242": "5",
  "I61_3599;1_6306;1_6265;1_6214": "Notification",
  "61_3619": "Inbox",
  "61_3617": "99+",
  "61_3615": "Approval",
  "I61_3599;1_6306;1_6257;1_6154": "Vehicle Management",
  "I61_3599;1_6306;1_6247;1_6154": "Service Management",
  "I61_3599;1_6306;1_6244": "Admin Portal",
  "61_3781": "Inbox",
  "I61_3599;1_6306;1_6246;1_6154": "TOP",
  "61_3794": "Vehicle Management",
  "61_3790": "Tasks",
  "61_3621": "99+",
  "61_3858": "Turn on if you would like to receive notifications by email.",
  "61_4187": "DELETE",
  "61_4027": "api_idm",
  "61_4000": "admin@organiclink.cloud",
  "61_4047": "user",
  "61_4040": "api_idm",
  "61_4212": "16:12 29-01-2024",
  "61_3972": "admin@organiclink.cloud",
  "153_5080": "Activity Logs",
  "61_4245": "Action",
  "61_4208": "16:12 29-01-2024",
  "61_4053": "api_idm",
  "61_4009": "16:12 29-01-2024",
  "61_4190": "CREATE",
  "61_4195": "SUCCESS",
  "61_4237": "End Date",
  "I153_5076;1_6306;1_6265;1_6214": "Notification",
  "61_4204": "16:2 29-01-2024",
  "61_3898": "Action",
  "61_4199": "SUCCESS",
  "I153_5076;1_6306;1_6244": "Admin Portal",
  "61_4234": "Start Date",
  "61_3908": "Service code",
  "61_3970": "admin@organiclink.cloud",
  "61_4002": "admin@organiclink.cloud",
  "I153_5076;1_6306;1_6255;1_6154": "Developer Portal",
  "61_3917": "permission",
  "61_4011": "SUCCESS",
  "61_3966": "admin@organiclink.cloud",
  "61_3936": "api_idm",
  "61_4194": "DELETE",
  "61_3990": "service",
  "61_4197": "SUCCESS",
  "61_3943": "organization",
  "61_4057": "admin@organiclink.cloud",
  "61_4034": "user",
  "I153_5076;1_6306;1_6257;1_6154": "Vehicle Management",
  "61_4013": "api_idm",
  "61_4211": "16:12 29-01-2024",
  "61_4065": "User",
  "2009_4078": "of",
  "2009_4069": "page",
  "61_3923": "api_idm",
  "61_4206": "16:12 29-01-2024",
  "61_3977": "service",
  "61_4207": "16:12 29-01-2024",
  "2009_4076": "40",
  "61_4241": "User",
  "61_4193": "CREATE",
  "61_4198": "SUCCESS",
  "2009_4073": "Show",
  "61_4063": "admin@organiclink.cloud",
  "61_4205": "16:12 29-01-2024",
  "I153_5076;1_6306;1_6254;1_6154": "Function Setting",
  "2009_4062": "1",
  "I153_5076;1_6306;1_6246;1_6154": "TOP",
  "61_4196": "SUCCESS",
  "61_4203": "SUCCESS",
  "61_3962": "api_control_plane",
  "61_3983": "api_control_plane",
  "61_4202": "SUCCESS",
  "61_4209": "16:12 29-01-2024",
  "61_4228": "Service",
  "61_4200": "SUCCESS",
  "2009_4075": "20",
  "I153_5076;1_6306;1_6242": "5",
  "61_3900": "Action",
  "61_4191": "CREATE",
  "61_4004": "CREATE",
  "61_4059": "admin@organiclink.cloud",
  "I153_5076;1_6306;1_6247;1_6154": "Service Management",
  "61_3996": "api_control_plane",
  "61_3956": "service",
  "61_3906": "Status",
  "61_4188": "CREATE",
  "2009_4074": "1",
  "I153_5076;1_6306;1_6259;1_6219": "Approval",
  "61_4085": "Activity Logs",
  "61_4192": "DELETE",
  "2009_4068": "20  /",
  "61_4021": "permission",
  "I153_5076;1_6306;1_6253;1_6154": "Identity Management",
  "61_3968": "admin@organiclink.cloud",
  "61_4189": "DELETE",
  "61_3949": "api_control_plane",
  "61_4210": "16:12 29-01-2024",
  "61_4201": "SUCCESS",
  "61_4186": "UPDATE",
  "61_4007": "user",
  "61_4061": "admin@organiclink.cloud",
  "61_3930": "permission",
  "61_3904": "Created At",
  "2009_4077": "to",
  "61_3902": "Object code",
  "2009_4079": "results",
  "I153_5076;1_6306;1_6256;1_6154": "Device Management",
  "61_4698": "Action",
  "I153_5276;1_6306;1_6247;1_6154": "Service Management",
  "61_4603": "Organization Code",
  "I153_5276;1_6306;1_6244": "Admin Portal",
  "61_4700": "create",
  "61_4712": "Service Code",
  "I153_5276;1_6306;1_6257;1_6154": "Vehicle Management",
  "I153_5276;1_6306;1_6259;1_6219": "Approval",
  "I153_5276;1_6306;1_6255;1_6154": "Developer Portal",
  "153_5281": "Activity Logs",
  "61_4600": "Detail Log",
  "61_4746": "New Data",
  "I153_5276;1_6306;1_6265;1_6214": "Notification",
  "2009_4038": "Activity Logs",
  "156_5382": "Expired At:",
  "61_4721": "Old Data",
  "61_4701": "Status",
  "61_4703": "SUCCESS",
  "61_4704": "Object Code",
  "2009_4039": "/",
  "I153_5276;1_6306;1_6254;1_6154": "Function Setting",
  "I153_5276;1_6306;1_6246;1_6154": "TOP",
  "61_4718": "admin@organiclink.cloud",
  "2009_4040": "Detail Log",
  "61_4714": "api_idm",
  "61_4706": "user",
  "I153_5276;1_6306;1_6242": "5",
  "61_4720": "16:12:47 2024-02-28",
  "156_5381": "Created At:",
  "61_4605": "om-admin",
  "61_4719": "16:12:47 2024-01-29",
  "61_4716": "User Email",
  "I153_5276;1_6306;1_6253;1_6154": "Identity Management",
  "I153_5276;1_6306;1_6256;1_6154": "Device Management",
  "I2695_15294;154_64377;75_53865;250_37564": "Select Vehicle Brand",
  "I2695_15300;154_64377;75_53865;250_37564": "Select Vehicle Color",
  "I2695_15297;154_64377;75_53865;250_37564": "Select Vehicle Model",
  "I2695_15305;154_64377;75_53865;250_37564": "Select Vehicle Type",
  "I2695_15308;154_64377;75_53865;250_37564": "Select Vehicle Model Year",
  "I2695_15381;30_32140": "Search",
  "I2695_15285;35_49503": "Add Vehicle",
  "I2695_15284;35_49548;28_31236": "Vehicle Management",
  "I2695_15284;35_49550;28_31236": "Manage Vehicle",
  "2695_15290": "General Setting",
  "I2695_15294;154_64376": "Vehicle Brand",
  "2695_15296": "Select Vehicle Brand",
  "I2695_15297;154_64376": "Vehicle Model",
  "2695_15299": "Select Vehicle Model",
  "I2695_15305;154_64376": "Vehicle Type",
  "2695_15307": "Select Vehicle Type",
  "I2695_15308;154_64376": "Vehicle Model Year",
  "I2695_15300;154_64376": "Vehicle Color",
  "2695_15302": "Select Vehicle Color",
  "I2695_15311;7636_13403": "VIN",
  "2695_15313": "Enter VIN",
  "I2695_15311;7636_13405;75_53807": "VIN",
  "I2695_15303;7636_13403": "Number Plate",
  "I2695_15303;7636_13405;75_53807": "Number Plate",
  "2695_15320": "Length",
  "2695_15328": "Width",
  "2695_15336": "Height",
  "2695_15344": "Max Weight",
  "2695_15353": "Advance Setting",
  "2695_15358": "Odometer",
  "2695_15366": "Vehicle Images",
  "2695_15372": "Device Linkage",
  "2695_15377": "Device ID",
  "I2695_15382;7636_13403": "Device Bluetooth ID",
  "I2695_15382;7636_13405;185_68545": "Device Bluetooth ID",
  "2695_15386": "Vehicle Assignment",
  "I2695_15388;154_64376": "Assigned Organization",
  "2695_15392": "Vehicle Inspection Information",
  "I2695_15395;154_64376": "Inspection Date",
  "I2695_15395;154_64377;75_53865;250_37564": "Enter Inspection Date",
  "I2695_15396;154_64376": "Inspection Due Date",
  "I2695_15396;154_64377;75_53865;250_37564": "Enter Inspection Due Date",
  "2695_15400": "Insurance Service Information",
  "I2695_15403;154_64376": "Insurance Service Contract Start Date",
  "I2695_15403;154_64377;75_53865;250_37564": "Enter Contract Start Date",
  "I2695_15404;154_64376": "Insurance Service Contract Expiry Date",
  "I2695_15404;154_64377;75_53865;250_37564": "Enter Contract Expiry Date",
  "2695_15408": "Towing Service Information",
  "I2695_15411;154_64376": "Towing Service Contract Start Date",
  "I2695_15411;154_64377;75_53865;250_37564": "Enter Contract Start Date",
  "I2695_15412;154_64376": "Towing Service Contract Expiry Date",
  "I2695_15412;154_64377;75_53865;250_37564": "Enter Contract Expiry Date",
  "I2695_15414;30_32137": "Cancel",
  "I2695_15415;30_32140": "Next",
  "2985_13870": "First Name",
  "2985_13872": "Middle Name",
  "2985_13874": "Last Name",
  "2870_14104": "Mobile Phone Number",
  "2985_13882": "Mobile Phone Number"
}
export default localeDefault
