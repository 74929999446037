import { apiUser } from "api"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { useStore } from "components/ui"
import { setEnums } from "lib/localstorage"
import { getCookieAuth } from "lib/cookie"
import LoginForm from "./LoginForm"
import { convertPermission } from "lib/function"
import { RESOURCE_KEY } from "lib/Const"
import { ENUMS_CONFIG } from "lib/Const"
import { changeLanguage } from "utils/i18n"
import { useTranslation } from "react-i18next"
import { apiAms } from "api"
import { Spin, Flex } from "antd"

const boxStyle = {
  width: "100%",
  height: "100vh"
}

// Define the Login component
const Login = () => {
  // Destructure values from the store and setAuthStore function
  const { setAuthStore } = useStore()
  const { t } = useTranslation()

  // Access the navigation function
  const navigate = useNavigate()

  // Check if the application is running in a local environment
  const local = window.location.href.includes("local")

  // Initialization function
  async function init() {
    try {
      // Retrieve the access token from cookies
      const { access_token } = getCookieAuth()

      let tabId = sessionStorage.tabID
      if (!tabId) {
        tabId = Math.random()
        sessionStorage.tabID = tabId
      }

      if (access_token && tabId) {
        // Fetch user information using the access token
        const _userInfo = apiUser.getUserInfo()

        // Fetch user permissions
        const _permission = apiUser.getPermissions()
        const [userInfo, permission] = await Promise.all([_userInfo, _permission])
        const vehicleAssign = !!Object.keys(permission.data).find(
          (i) => i === RESOURCE_KEY.ROOT_AC_VEHICLE_UPDATE_STATUS
        )
        localStorage.setItem("vehicleAssign", vehicleAssign)

        // Convert permission data to a format suitable for the application
        const __permission = convertPermission(permission?.data)

        // Extract organization name from user information
        const orgName = userInfo?.data?.organization?.name
        // Set the document title based on the organization name
        const defaultLanguage = userInfo?.data?.language || "en"

        // Prepare the base data object to be stored locally
        let baseData = {
          user: userInfo?.data,
          orgName: orgName,
          permission: __permission,
          access_token: access_token
        }
        // Set local enums and base data
        setEnums(ENUMS_CONFIG)
        await changeLanguage(defaultLanguage)
        // Set authentication data in the store
        setAuthStore(baseData)
        handleSetDataTranslateErrorMsg()
        // Redirect to the dashboard page
        if (convertPermission(permission?.data)[RESOURCE_KEY.ROOT_SERVICE] === undefined) {
          navigate("/profile")
        } else {
          navigate("/dashboard")
        }
      } else {
        // If no access token is found and the environment is not local, redirect to SSO login
        sessionStorage.tabID = Math.random()
        if (!local) {
          window.location.href = `${process.env.REACT_APP_SSO_URL}`
        }
      }
    } catch (error) {}
  }

  async function handleSetDataTranslateErrorMsg() {
    try {
      const { data } = await apiAms.getTranslateErrorMessage()
      const errorMsg =
        data &&
        data.length &&
        data.map((item) => ({
          error_code: item.error_code,
          content: item.content,
          language_code: item.language_code
        }))
      errorMsg && localStorage.setItem("errorMsg", JSON.stringify(errorMsg))
    } catch (error) {
      console.log(error)
    }
  }

  // Call the init function when the component mounts
  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Render the LoginForm component if the environment is local
  return (
    <div>
      {getCookieAuth().access_token ? (
        <Flex style={boxStyle} justify={"center"} align={"center"}>
          <Spin spinning={true} />
        </Flex>
      ) : (
        local && <LoginForm />
      )}
    </div>
  )
}

export default Login

//ss
